<div class="card-content">
  <div class="first-third">
    <div class="brand" [style.color]="viewModel.headerTextColor$ | async">
      {{ data | sectionRowViewModelBasicMethod: 'getBrand' }}
    </div>
    <div class="title-size-cannabinoids" [style.color]="viewModel.bodyTextColor$ | async">
      <div class="title-size">
        <div *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title" class="product-card-variant-title">
          {{ title }}
        </div>
        <div
          *ngIf="viewModel | getExpandedGridSize: (viewModel.spoofedColViewModel$ | async) | async | lowercase as size"
          class="product-card-size">
          {{ size }}
        </div>
      </div>
      <div class="cannabinoids">
        <div
          *ngIf="data | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'THC' as thc"
          class="product-cannabinoid product-card-thc">
          {{ thc }} <span>THC</span>
        </div>
        <div
          *ngIf="data | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'CBD' as cbd"
          class="product-cannabinoid product-card-cbd">
          {{ cbd }} <span>CBD</span>
        </div>
      </div>
    </div>
  </div>

  <div class="second-third" [style.color]="viewModel.bodyTextColor$ | async">
    <div>
      <app-column-primary-price
        [menu]="viewModel.cardStackMenu$ | async"
        [section]="viewModel.cardStack$ | async"
        [colVm]="viewModel.spoofedColViewModel$ | async"
        [rowVm]="viewModel.cardData$ | async"
        [locConfig]="viewModel.locationConfig$ | async"
        [locationId]="viewModel.locationId$ | async"
        [productStylingVm]="viewModel"
        [specificPriceWithoutDiscounts]="viewModel.mainPriceSpecificPriceWithoutDiscounts$ | async"
        [finalPriceStringModifier]="viewModel.addSpaceAfterDollarSign">
      </app-column-primary-price>
    </div>
  </div>
  <div class="last-third"></div>
</div>
