@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/index-cards-3x5/fika-index-card-stack-content/fika-size-and-price/fika-size-and-price.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
}
.size-and-price-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-evenly;
}
.size-and-price-container .product-column-general {
  overflow-x: visible !important;
  display: flex;
  flex-direction: column-reverse;
}
.size-and-price-container .multiple-variants-on-sale .product-column-general {
  flex-direction: row-reverse;
  gap: 0.25rem;
}
.size-and-price-container .size-and-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.size-and-price-container .size-and-price .size {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.size-and-price-container .size-and-price .price ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  line-height: 100%;
  overflow: visible;
  width: min-content;
}
.size-and-price-container .size-and-price .price ::ng-deep .product-variant-price .product-variant-current-price,
.size-and-price-container .size-and-price .price ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.size-and-price-container .size-and-price .price ::ng-deep .product-variant-price .product-variant-previous-price {
  opacity: 0.75;
}
.size-and-price-container .grid-name {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
}
/*# sourceMappingURL=fika-size-and-price.component.css.map */
