<app-label-inflator
  #labelCalculator
  hidden
  class="label-calculator label"
  [columnLabel]="false"
  [labelComponentInterface]="data"
  [reset]="false">
</app-label-inflator>

<div class="card-header">
  <div *ngIf="data | sectionRowViewModelBasicMethod: 'getReadableStrainType' as strainType">
    <div *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)" class="strain-container">{{ strainType }}</div>
  </div>
</div>
<div class="card-body">
  <div class="main-content">
    <img
      *ngIf="viewModel.showCompanyLogo$ | async"
      appImage
      class="company-logo"
      [asset]="viewModel.companyLogo$ | async"
      alt="company logo"
      src="" />

    <img
      *ngIf="data | getVariantAssetForCard | async as variantAsset"
      appImage
      class="brand-logo"
      [asset]="variantAsset"
      src=""
      alt="" />

    <div>
      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
        class="product-card-name"
        appScaleDownText
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
        [style.color]="viewModel.headerTextColor$ | async">
        {{ title | trimSafely }}
      </div>
    </div>

    <div class="product-size-and-type-container" [style.color]="viewModel.bodyTextColor$ | async">
      <ng-container *ngIf="viewModel.quantitySizeAndVariantType$ | async as quantitySizeAndVariantType">
        {{ quantitySizeAndVariantType }}
      </ng-container>
    </div>

    <div *ngIf="viewModel.badgeOrLabelVisible$ | async" class="badge-and-label-container">
      <img
        *ngFor="let variantBadge of data | printCardBadges"
        appImage
        class="badge"
        [asset]="variantBadge?.image"
        alt="badge"
        src="" />

      <app-label-inflator
        *ngIf="viewModel.labelText$ | async"
        class="print-card-label"
        [class.default-label]="!(viewModel.labelIsFlagStyle$ | async)"
        [class.flag-label]="viewModel.labelIsFlagStyle$ | async"
        [columnLabel]="false"
        [labelComponentInterface]="data"
        [reset]="false">
      </app-label-inflator>
    </div>
  </div>
</div>
