import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { AgentOrangeIndexCardStackBackgroundViewModel } from './agent-orange-index-card-stack-background-view-model';
import { CardBackgroundComponent } from '../../card-background.component';
import { CardData } from '../../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-agent-orange-index-card-stack-background',
  templateUrl: './agent-orange-index-card-stack-background.component.html',
  styleUrls: ['./agent-orange-index-card-stack-background.component.scss'],
  providers: [AgentOrangeIndexCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentOrangeIndexCardStackBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public viewModel: AgentOrangeIndexCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

  @Input() override cardBackground: CardData;

}
