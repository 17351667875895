import { PostCardStack } from './post-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { OGKushPostCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/post-cards-4x6/og-kush-post-card-stack-content/og-kush-post-card-stack-content.component';

export class OGKushPostCardStack extends PostCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return null;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return OGKushPostCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
