import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'getInternalGridCutLineCrossLeftOffset'
})
export class GetInternalGridCutLineCrossLeftOffsetPipe implements PipeTransform {

  /**
   * Indices of Crosses
   *   One Column              Two Column                      Three Column
   * |------------| |-----------------------------| |--------------------------------|
   * |     0      | |       0            1        | |    0          1          2     |
   * |     +      | |       +            +        | |    +          +          +     |
   * |            | |                             | |                                |
   * |     1      | |       2            3        | |    3          4          5     |
   * |     +      | |       +            +        | |    +          +          +     |
   * |            | |                             | |                                |
   * |     2      | |       4            5        | |    6          7          8     |
   * |     +      | |       +            +        | |    +          +          +     |
   * | -----------| | ----------------------------| | -------------------------------|
   */
  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper, nCardsOnPage: number, index: number): number {
    const nCrossColumns = (cardsThatLayoutOntoPaper?.getNumberOfVisibleColumnsOnPage(nCardsOnPage) ?? 1) - 1;
    const nThColumn = (index % nCrossColumns) + 1;
    const bleed = cardsThatLayoutOntoPaper?.withContentBleed(false, 2) ?? 0;
    const cardWidth = cardsThatLayoutOntoPaper?.getCardWidthInPixels() ?? 0;
    return (cardWidth + bleed) * nThColumn;
  }

}
