import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { map } from 'rxjs/operators';
import { VariantType } from '../../../../../../../models/enum/dto/variant-type.enum';
import { exists } from '../../../../../../../functions/exists';
import { ProductType } from '../../../../../../../models/enum/dto/product-type.enum';

@Injectable()
export class TrueNorthCardStackContentViewModel extends PrintCardContentViewModel {

  public companyLogo$ = this.menu$.pipe(map(menu => menu?.companyLogo));
  public showCompanyLogo$ = this.menu$.pipe(map(menu => menu?.showCompanyLogo()));

  public quantitySizeAndVariantType$ = this.cardData$.pipe(
    map(cardData => {
      const productType = cardData?.rowProductType();
      const variantType = cardData?.rowVariantType()?.toUpperCase();
      let quantityAndSizeString = cardData?.getQuantityAndSizeString();
      const noQtyAndSize = !quantityAndSizeString || quantityAndSizeString === '-' || quantityAndSizeString === '--';
      if (noQtyAndSize) quantityAndSizeString = '';
      const onlyVariantType = cardData?.isNonCannabinoidVariant();
      const onlyQtyAndSize = !variantType
        || variantType === VariantType.Capsule
        || productType === ProductType.Seed
        || variantType === VariantType.Seed;
      if (onlyVariantType) return variantType;
      if (onlyQtyAndSize) {
        const str = quantityAndSizeString;
        if (str?.includes('Seeds')) quantityAndSizeString = quantityAndSizeString?.replace('Seeds', 'SEEDS');
        if (str?.includes('Seed')) quantityAndSizeString = quantityAndSizeString?.replace('Seed', 'SEED');
        if (str?.includes('Capsules')) quantityAndSizeString = quantityAndSizeString?.replace('Capsules', 'CAPSULES');
        if (str?.includes('Capsule')) quantityAndSizeString = quantityAndSizeString?.replace('Capsule', 'CAPSULE');
        if (str?.includes('Caps')) quantityAndSizeString = quantityAndSizeString?.replace('Caps', 'CAPS');
        if (str?.includes('Cap')) quantityAndSizeString = quantityAndSizeString?.replace('Cap', 'CAP');
        return quantityAndSizeString;
      }
      return [quantityAndSizeString, variantType].filter(s => exists(s)).join(' ');
    })
  );

}
