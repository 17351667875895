import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { DougBusinessCardStackContentViewModel } from './doug-business-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';

@Component({
  selector: 'lib-doug-business-card-stack-content',
  templateUrl: './doug-business-card-stack-content.component.html',
  styleUrls: ['./doug-business-card-stack-content.component.scss'],
  providers: [DougBusinessCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DougBusinessCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: DougBusinessCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}

