import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { Observable } from 'rxjs';
import { NycdCardData } from '../../../../../../../models/print-cards/nycd-card-data';
import { map } from 'rxjs/operators';

@Injectable()
export class NycdIndexCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<NycdCardData>;

  public readonly priceStreamOverride$ = this.cardData$.pipe(
    map(cardData => cardData?.getOverridePriceStreamForLabelComponent())
  );

}
