import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { AgentOrangeIndexCardStackContentViewModel } from './agent-orange-index-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';

@Component({
  selector: 'app-agent-orange-index-card-stack-content',
  templateUrl: './agent-orange-index-card-stack-content.component.html',
  styleUrls: ['./agent-orange-index-card-stack-content.component.scss'],
  providers: [AgentOrangeIndexCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentOrangeIndexCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: AgentOrangeIndexCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
