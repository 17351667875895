import { BusinessCardStack } from './business-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { JaneDoeBusinessCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/business-cards-2x3/jane-doe-business-card-stack-background/jane-doe-business-card-stack-background.component';
import { JaneDoeBusinessCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/business-cards-2x3/jane-doe-business-card-stack-content/jane-doe-business-card-stack-content.component';

export class JaneDoeBusinessCardStack extends BusinessCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return JaneDoeBusinessCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return JaneDoeBusinessCardStackContentComponent;
  }

}
