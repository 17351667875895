import { PrintCardMenu } from './print-card-menu';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';
import { FONT, FontStyleSheet } from '../font-style-sheets';
import { PriceUtils } from '../../../utils/price-utils';
import type { LocationPriceStream } from '../../enum/shared/location-price-stream';
import type { SectionWithProducts } from '../section/section-with-products';
import type { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

export class StiiizyPrintCardMenu extends PrintCardMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.archivoRegular,
      FONT.archivoBlack,
      FONT.archivoBold,
      FONT.archivoExtraBold
    ];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    let decimal = super.getPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    decimal = PriceUtils.removeTrailingZerosFromPriceString(decimal);
    return decimal;
  }

  getSecondaryPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel
  ): string {
    let decimal = super.getSecondaryPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    decimal = PriceUtils.removeTrailingZerosFromPriceString(decimal);
    return decimal;
  }

  /**
   * Brand asset should be displayed if available - no product fallback.
   * Used in conjunction with variantAssetTypeSortOrder within StiiizyCardData and GetVariantAssetForCardPipe.
   */
  override variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand
    ];
  }

  /**
   * Stiiizy cards ask for 2 assets to be returned instead of 1, because there is an inverse version of the card, where
   * the background is black and the brand asset needs to be white, so in this scenario the alternative brand asset
   * is also needed.
   */
  variantAssetItemCount(): number {
    return 2;
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

}
