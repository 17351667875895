<app-label-inflator
  #labelCalculator
  hidden
  class="print-card-label"
  [columnLabel]="false"
  [labelComponentInterface]="data"
  [reset]="false">
</app-label-inflator>

<div class="header-content">
  <div
    *ngIf="data | sectionRowViewModelBasicMethod: 'getReadableStrainType' as strainType"
    class="strain-container"
    [class.dark-text]="!(viewModel.cardBorderColor$ | async | isDarkColor)"
    [class.light-text]="viewModel.cardBorderColor$ | async | isDarkColor">
    {{ strainType }}
  </div>
</div>

<div class="product-card-name-and-brand-container" [style.color]="viewModel.bodyTextColor$ | async">
  <div
    *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
    class="product-card-name"
    [style.color]="viewModel.headerTextColor$ | async">
    {{ title | trimSafely }}
  </div>

  <div *ngIf="data | sectionRowViewModelBasicMethod: 'getBrand' as brand" class="product-card-brand">
    {{ brand | trimSafely }}
  </div>
</div>

<div
  *ngIf="
    !(viewModel.isNonCannabinoidVariant$ | async) &&
    ((data | sectionRowViewModelBasicMethod: 'getThc') || (data | sectionRowViewModelBasicMethod: 'getCbd'))
  "
  class="product-card-cannabinoid-container"
  [style.color]="viewModel.cannabinoidTextColor$ | async">
  <div *ngIf="data | sectionRowViewModelBasicMethod: 'getThc' as thc" class="property-container">
    <div
      class="value"
      appScaleDownText
      [scaleDownLinkedKey]="viewModel.variantId$ | async"
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
      {{ thc }}
    </div>
    <div class="title">THC</div>
  </div>

  <div *ngIf="data | sectionRowViewModelBasicMethod: 'getCbd' as cbd" class="property-container">
    <div
      class="value"
      appScaleDownText
      [scaleDownLinkedKey]="viewModel.variantId$ | async"
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
      {{ cbd }}
    </div>
    <div class="title">CBD</div>
  </div>
</div>

<div *ngIf="viewModel.badgeOrLabelVisible$ | async" class="product-card-badge-and-label-container">
  <img
    *ngFor="let variantBadge of data | printCardBadges"
    appImage
    class="badge"
    [asset]="variantBadge?.image"
    alt="badge"
    src="" />

  <app-label-inflator
    class="print-card-label"
    [class.default-label]="!(viewModel.labelIsFlagStyle$ | async)"
    [class.flag-label]="viewModel.labelIsFlagStyle$ | async"
    [columnLabel]="false"
    [labelComponentInterface]="data"
    [reset]="false">
  </app-label-inflator>
</div>

<div class="product-card-price-size-and-quantity-container" [style.color]="viewModel.bodyTextColor$ | async">
  <app-column-primary-price
    [menu]="viewModel.cardStackMenu$ | async"
    [section]="viewModel.cardStack$ | async"
    [colVm]="viewModel.spoofedColViewModel$ | async"
    [rowVm]="viewModel.cardData$ | async"
    [locConfig]="viewModel.locationConfig$ | async"
    [locationId]="viewModel.locationId$ | async"
    [productStylingVm]="viewModel">
  </app-column-primary-price>

  <div
    *ngIf="viewModel.quantityAndSizeText$ | async | trimSafely as quantityAndSizeText"
    class="product-card-size"
    [style.color]="viewModel.bodyTextColor$ | async">
    {{ quantityAndSizeText }}
  </div>
</div>

<div
  class="footer-content"
  [class.dark-text]="!(viewModel.cardBorderColor$ | async | isDarkColor)"
  [class.light-text]="viewModel.cardBorderColor$ | async | isDarkColor">
  {{ viewModel.bottomText$ | async | trimSafely }}
</div>
