@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/index-cards-3x5/nycd-index-card-stack-content/nycd-index-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0.25rem;
  box-sizing: border-box;
}
:host .sidebar-section {
  position: absolute;
  box-sizing: border-box;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.25rem 0.25rem 0.125rem 0.25rem;
  transform: rotate(270deg);
  transform-origin: bottom left;
  left: 2rem;
  top: 16rem;
  text-transform: uppercase;
  border-color: black;
  border-style: solid;
  border-width: 0 0 0.15625rem 0;
  width: 17.5rem;
  height: 1.75rem;
}
:host .sidebar-section .sidebar-text {
  white-space: nowrap;
  font-family: inter, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.9375rem;
  text-align: center;
}
:host .main-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2rem;
  flex: 1;
}
:host .main-section .top-half-of-card {
  padding: 0.25rem 0;
  gap: 0.25rem;
}
:host .main-section .top-half-of-card .product-card-name {
  font-family: inter, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.1875rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:host .main-section .top-half-of-card .info-row {
  display: flex;
  flex-direction: row;
}
:host .main-section .top-half-of-card .info-row .property-container {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
:host .main-section .top-half-of-card .info-row .property-container .title {
  font-family: inter, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.25rem;
}
:host .main-section .top-half-of-card .info-row .property-container .value {
  font-family: inter, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.25rem;
}
:host .main-section .top-half-of-card .info-row .product-card-size {
  font-family: inter, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.25rem;
}
:host .main-section .top-half-of-card .info-row .print-card-label {
  align-self: center;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-section .top-half-of-card .info-row .print-card-label ::ng-deep .product-label {
  font-family: inter, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 140%;
  border-radius: 0.25rem;
  margin: 0.1rem 0 0.2rem 0;
  width: fit-content;
}
:host .main-section .top-half-of-card .info-row .product-badge {
  width: 1.25rem;
  height: 1.25rem;
}
:host .main-section .bottom-half-of-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}
:host .main-section .bottom-half-of-card .product-card-image {
  width: 8.5625rem;
}
:host ::ng-deep .force-bold-style * {
  font-family: inter, sans-serif;
  font-weight: 900;
  font-style: normal;
}
:host ::ng-deep .force-italic-style * {
  font-family: inter, sans-serif;
  font-weight: 600;
  font-style: italic;
}
/*# sourceMappingURL=nycd-index-card-stack-content.component.css.map */
