<app-render-card-background
  [preview]="preview"
  [parentHeight]="viewModel.height$ | async"
  [parentWidth]="viewModel.width$ | async"
  [data]="data">
</app-render-card-background>

<app-card-content-inflator
  [preview]="preview"
  [parentHeight]="viewModel.height$ | async"
  [parentWidth]="viewModel.width$ | async"
  [data]="data">
</app-card-content-inflator>
