import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, defer, iif, Observable } from 'rxjs';
import { PrintCardDomainModel } from '../../../../domain/print-card-domain-model';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { RenderContentViewModel } from '../../base/render-content-view-model';
import { SectionRowViewModelUtils } from '../../../../utils/section-row-view-model-utils';
import { exists } from '../../../../functions/exists';
import { NumberUtils } from '../../../../utils/number.utils';
import { SectionLayoutType } from '../../../../models/enum/dto/section-layout-type.enum';
import { FikaEdiblesCardMenu } from '../../../../models/menu/print-card/fika-edibles-card-menu';
import { StiiizyPrintCardMenu } from '../../../../models/menu/print-card/stiiizy-print-card-menu';
import { PrintCardMenu } from '../../../../models/menu/print-card/print-card-menu';
import type { CardData } from '../../../../models/print-cards/card-data';
import type { CardStack } from '../../../../models/menu/section/card-stacks/card-stack';
import type { LocationConfiguration } from '../../../../models/company/dto/location-configuration';
import type { CompanyConfiguration } from '../../../../models/company/dto/company-configuration';
import type { LocationPriceStream } from '../../../../models/enum/shared/location-price-stream';
import { ShelfTalkerCardData } from '../../../../models/shelf-talkers/shelf-talker-card-data';
import { ShelfTalkerUtils } from '../../../../utils/shelf-talker-utils';

@Injectable()
export class RenderCardsViewModel extends RenderContentViewModel {

  constructor(
    activatedRoute: ActivatedRoute,
    printCardDomainModel: PrintCardDomainModel
  ) {
    super(activatedRoute, printCardDomainModel);
  }

  private readonly cardStackCardGroupingsPerPage$: Observable<CardData[][]> = defer(() => {
    return combineLatest([
      this.cardStack$,
      this.printCardMenu$,
      this.overrideVariantIds$,
      this.overrideSiblingVariantIds$,
      this.companyConfig$,
      this.locationConfig$,
      this.variantCardCountMap$
    ]).pipe(
      map(([stack, menu, forcedVarIdPool, allVariantsSiblingIds, compConfig, locConfig, variantCardCountMap]) => {
        // Consolidate the forcedVariantIdPool with the allVariantsSiblingIds, depending on the section layoutType
        const consolidate = this.consolidateForcedVariantIdPoolWithSiblingVariantIds;
        forcedVarIdPool = consolidate(stack, menu, forcedVarIdPool, allVariantsSiblingIds, compConfig, locConfig);
        const paperSize = menu?.displaySize;
        const onPerforatedPaper = stack?.isOnPerforatedPaper() ?? false;
        const generateCardData = SectionRowViewModelUtils.generateRowViewModels;
        const products = stack?.products;
        const vms = generateCardData(stack, menu, compConfig, locConfig, products, [], forcedVarIdPool);
        const cards: CardData[] = [];
        const cardsPerPage = !stack?.singleCardPerPage
          ? (stack?.nCardsPerPage(paperSize, onPerforatedPaper) ?? 1)
          : 1;
        vms?.forEach(card => {
          const key = card?.getVariantIdsSeperatedBy('-');
          const nTimes = exists(variantCardCountMap?.[key]) ? Number(variantCardCountMap?.[key]) : 1;
          for (let i = 0; i < nTimes; i++) cards.push(card as CardData);
        });
        return menu?.cardGroupingsForEachPage(cards, cardsPerPage, onPerforatedPaper) ?? [];
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  });

  private readonly shelfTalkerCardGroupingsPerPage$: Observable<ShelfTalkerCardData[][]> = defer(() => {
    return combineLatest([
      this.shelfTalkerMenu$,
      this.shelfTalkerCards$,
      this.overrideSectionIds$,
      this.companyConfig$,
      this.locationConfig$,
    ]).pipe(
      map(([shelfTalkerMenu, shelfTalkerCards, overrideSectionIds, companyConfig, locationConfig]) => {
        const paperSize = shelfTalkerMenu?.displaySize;
        const onPerforatedPaper = shelfTalkerCards?.firstOrNull()?.isOnPerforatedPaper() ?? false;
        const builder = ShelfTalkerUtils.generateShelfTalkerCardData;
        const cardData = builder(shelfTalkerMenu, companyConfig, locationConfig, overrideSectionIds);
        const firstCard = shelfTalkerCards?.firstOrNull();
        const cardsPerPage = firstCard?.nCardsPerPage(paperSize, onPerforatedPaper) ?? 1;
        return shelfTalkerMenu?.cardGroupingsForEachPage(cardData, cardsPerPage, onPerforatedPaper) ?? [];
      })
    );
  });

  public readonly cardAsDataGroupedPerPage$ = this.laysOutCardsOnToPaperMenu$.pipe(
    filter(laysOutCardsOnToPaperMenu => exists(laysOutCardsOnToPaperMenu)),
    switchMap(laysOutCardsOnToPaperMenu => {
      return iif(
        () => laysOutCardsOnToPaperMenu instanceof PrintCardMenu,
        this.cardStackCardGroupingsPerPage$,
        this.shelfTalkerCardGroupingsPerPage$
      );
    })
  );

  private consolidateForcedVariantIdPoolWithSiblingVariantIds = (
    stack: CardStack,
    menu: PrintCardMenu,
    forcedVariantIdPool: string[]|null,
    allVariantsSiblingIds: string[]|null,
    compConfig: CompanyConfiguration,
    locConfig: LocationConfiguration,
  ): string[] => {
    const layoutType = menu?.sections?.firstOrNull()?.layoutType;
    switch (layoutType) {
      case SectionLayoutType.Grid: {
        // Grid menus can consolidate all variantIds since we will re-print any cards that are affected by grid changes
        return [...(forcedVariantIdPool ?? []), ...(allVariantsSiblingIds ?? [])];
      }
      case SectionLayoutType.ChildVariantList: {
        switch (true) {
          case menu instanceof StiiizyPrintCardMenu: {
            // Stiiizy cards group sibling variants onto the same card as "flavors", so we need to show all siblings
            return [...(forcedVariantIdPool ?? []), ...(allVariantsSiblingIds ?? [])];
          }
          case menu instanceof FikaEdiblesCardMenu: {
            // Fika edibles cards group siblings by price, so we need to show all siblings with the same price
            const tId = menu?.theme;
            const lId = locConfig?.locationId;
            const cId = compConfig?.companyId;
            const pStream = locConfig?.priceFormat;
            const hideSale = menu?.menuOptions?.hideSale;
            return forcedVariantIdPool
              ?.flatMap(variantId => {
                const getIdWithSiblings = this.getVariantIdWithAllSiblingVariantIdsWithSamePrice;
                return getIdWithSiblings(stack, variantId, allVariantsSiblingIds, tId, lId, cId, pStream, hideSale);
              })
              ?.unique();
          }
          default: {
            return forcedVariantIdPool;
          }
        }
      }
      default: {
        // single variant cards don't show siblings, so we can just return the forcedVariantIdPool
        return forcedVariantIdPool;
      }
    }
  };

  private getVariantIdWithAllSiblingVariantIdsWithSamePrice = (
    stack: CardStack,
    variantId: string,
    allVariantsSiblingIds: string[],
    themeId: string,
    locId: number,
    compId: number,
    priceStream: LocationPriceStream,
    hideSale: boolean
  ): string[] => {
    const product = stack?.products?.find(p => p?.variants?.map(v => v?.id)?.includes(variantId));
    const variant = product?.variants?.find(v => v?.id === variantId);
    const variantPrice = variant?.getVisiblePrice(themeId, locId, compId, priceStream, hideSale);
    const siblingVariants = product?.variants?.filter(v => allVariantsSiblingIds?.includes(v?.id));
    const siblingVariantsWithSamePrice = siblingVariants?.filter(v => {
      const price = v?.getVisiblePrice(themeId, locId, compId, priceStream, hideSale);
      return NumberUtils.floatNumbersEqual(price, variantPrice);
    });
    return [variantId, ...(siblingVariantsWithSamePrice?.map(v => v?.id) ?? [])];
  };

}
