import { PostCardStack } from './post-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { PlainJanePostCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/post-cards-4x6/plain-jane-post-card-stack-content/plain-jane-post-card-stack-content.component';
import { PlainJanePostCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/post-cards-4x6/plain-jane-post-card-stack-background/plain-jane-post-card-stack-background.component';

export class PlainJanePostCardStack extends PostCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> {
    return PlainJanePostCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> {
    return PlainJanePostCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
