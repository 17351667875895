import { Type } from '@angular/core';
import { MarketingThemeId, PrintCardThemeId, PrintLabelThemeId, PrintThemeId, ProductThemeId, ReportThemeId, ShelfTalkerThemeId, SpotlightThemeId } from '../enum/dto/theme.enum';
import { CleanMenu } from '../menu/product/clean-menu';
import { DougMenu } from '../menu/product/doug-menu';
import { MaryJaneMenu } from '../menu/product/mary-jane-menu';
import { FramedMenu } from '../menu/product/framed-menu';
import { NightMenu } from '../menu/product/night-menu';
import { MarketingLoopMenu } from '../menu/marketing/marketing-loop-menu';
import { Deserializable } from './deserializable';
import { PlainJaneMenu } from '../menu/print/plain-jane-menu';
import { FoggyMenu } from '../menu/product/foggy-menu';
import { Menu } from '../menu/menu';
import { MarketingFeaturedProductMenu } from '../menu/marketing/FeaturedProduct/marketing-featured-product-menu';
import { GridLinesMenu } from '../menu/print/grid-lines-menu';
import { JimmyPrintMenu } from '../menu/print/jimmy-print-menu';
import { ContemporaryMenu } from '../menu/product/contemporary-menu';
import { SproutMenu } from '../menu/print/sprout-menu';
import { TopProductsSpotlightMenu } from '../menu/spotlight/top-products-spotlight-menu';
import { TightMenu } from '../menu/product/tight-menu';
import { MarketingComboMenu } from '../menu/marketing/marketing-combo-menu';
import { WeedMenu } from '../menu/product/weed-menu';
import { CalyxTrichomesMenu } from '../menu/print/calyx-trichomes-menu';
import { TrueNorthMenu } from '../menu/product/true-north-menu';
import { BandedMenu } from '../menu/print/banded-menu';
import { SmallJaneMenu } from '../menu/product/small-jane-menu';
import { BudSupplyMenu } from '../menu/print/bud-supply-menu';
import { WeedStoreMenu } from '../menu/product/weed-store-menu';
import { MarketingFeaturedCategoryMenu } from '../menu/marketing/featured-category/marketing-featured-category-menu';
import { PlantlifeMenu } from '../menu/print/plantlife-menu';
import { AmsterdamMenu } from '../menu/product/amsterdam-menu';
import { BlendedMenu } from '../menu/print/blended-menu';
import { JimmyMenu } from '../menu/product/jimmy-menu';
import { TightPrintMenu } from '../menu/print/tight-print-menu';
import { RoyalMenu } from '../menu/product/royal-menu';
import { PlantlifeNonSmokableMenu } from '../menu/print/plantlife-non-smokable-menu';
import { DoubleDutchMenu } from '../menu/print/double-dutch-menu';
import { ComeToBaskTrifoldMenu } from '../menu/print/come-to-bask-trifold-menu';
import { DoubleGelatoMenu } from '../menu/print/double-gelato-menu';
import { LaCanapaMenu } from '../menu/product/la-canapa-menu';
import { IngramAndSonsMenu } from '../menu/product/ingram-and-sons-menu';
import { IngramAndSonsPrintMenu } from '../menu/print/ingram-and-sons-print-menu';
import { MarketingFeaturedCategoryStaticGridMenu } from '../menu/marketing/featured-category/marketing-featured-category-static-grid-menu';
import { FeaturedCategoryUtils } from '../../utils/featured-category-utils';
import { BlackJackMenu } from '../menu/product/black-jack-menu';
import { LaCanapaPrintMenu } from '../menu/print/la-canapa-print-menu';
import { DougPrintMenu } from '../menu/print/doug-print-menu';
import { RuntzMenu } from '../menu/product/runtz-menu';
import { LobsterButterMenu } from '../menu/product/lobster-butter-menu';
import { SoulBudMenu } from '../menu/product/soul-bud';
import { SoulBudPrintMenu } from '../menu/print/soul-bud-print-menu';
import { OGKushPrintMenu } from '../menu/print/og-kush-print-menu';
import { SpaceMonkeyPrintMenu } from '../menu/print/space-monkey-print-menu';
import { MarketingSmartPlaylistMenu } from '../menu/marketing/marketing-smart-playlist-menu';
import { LuxLeafMenu } from '../menu/product/lux-leaf-menu';
import { PurLifeMenu } from '../menu/product/pur-life-menu';
import { GenericPrintCardMenu } from '../menu/print-card/generic-print-card-menu';
import { PopsCannabisPrintCardMenu } from '../menu/print-card/pops-cannabis-print-card-menu';
import { DougPrintCardMenu } from '../menu/print-card/doug-print-card-menu';
import { PlainJanePrintCardMenu } from '../menu/print-card/plain-jane-print-card-menu';
import { OGKushPrintCardMenu } from '../menu/print-card/og-kush-print-card-menu';
import { OrderReviewReportMenu } from '../menu/report/order-review-report-menu';
import { MissJonesPrintCardMenu } from '../menu/print-card/miss-jones-print-card-menu';
import { TrueNorthPrintMenu } from '../menu/print/true-north-print-menu';
import { AgentOrangePrintCardMenu } from '../menu/print-card/agent-orange-print-card-menu';
import { FikaPrintCardMenu } from '../menu/print-card/fika-print-card-menu';
import { JaneDoePrintCardMenu } from '../menu/print-card/jane-doe-print-card-menu';
import { TrueNorthPortraitPrintCardMenu } from '../menu/print-card/true-north-portrait-print-card-menu';
import { TrueNorthLandscapePrintCardMenu } from '../menu/print-card/true-north-landscape-print-card-menu';
import { SessionsPrintCardMenu } from '../menu/print-card/sessions-print-card-menu';
import { TrueNorthDarkMenu } from '../menu/product/true-north-dark-menu';
import { SuperLemonHazeMenu } from '../menu/product/super-lemon-haze-menu';
import { FikaBeveragePrintCardMenu } from '../menu/print-card/fika-beverage-print-card-menu';
import { NorthernLights } from '../menu/product/northern-lights-menu';
import { HoneyHazePrintCardMenu } from '../menu/print-card/honey-haze-print-card-menu';
import { FikaPrintMenu } from '../menu/print/fika-print-menu';
import { FireAndFlowerPrintMenu } from '../menu/print/fire-and-flower-print-menu';
import { FireAndFlowerPrintCardMenu } from '../menu/print-card/fire-and-flower-print-card-menu';
import { PlantlifePrintCardMenu } from '../menu/print-card/plantlife-print-card-menu';
import { FikaAccessoriesPrintCardMenu } from '../menu/print-card/fika-accessories-print-card-menu';
import { FireAndFlowerDefaultPrintCardMenu } from '../menu/print-card/fire-and-flower-default-print-card-menu';
import { FireAndFlowerSalePrintCardMenu } from '../menu/print-card/fire-and-flower-sale-print-card-menu';
import { FireAndFlowerMemberPrintCardMenu } from '../menu/print-card/fire-and-flower-member-print-card-menu';
import { BaseInventoryPrintLabelMenu } from '../menu/print-label/base-inventory-print-label-menu';
import { BellaLunaPrintMenu } from '../menu/print/bella-luna-print-menu';
import { NycdPrintCardMenu } from '../menu/print-card/nycd-print-card-menu';
import { StiiizyPrintCardMenu } from '../menu/print-card/stiiizy-print-card-menu';
import { MarketingMenuType } from '../enum/dto/marketing-menu-type.enum';
import { MarketingUrlPlaylistMenu } from '../menu/marketing/marketing-url-playlist-menu';
import { GanjikaBeveragePrintCardMenu } from '../menu/print-card/ganjika-beverage-print-card-menu';
import { FikaEdiblesCardMenu } from '../menu/print-card/fika-edibles-card-menu';
import { FireAndFlowerShelfTalkerMenu } from '../menu/shelf-talkers/fire-and-flower-shelf-talker-menu';

/**
 * This allows for polymorphic deserialization of menus.
 * As new menus are created, they need to be added in here
 */
export class DeserializeMenuHelper {

  static getPolymorphicMenuObject<T extends Deserializable>(menu: any): T {
    const createPolymorphicObject = (ObjectType: Type<any>): T => {
      return Object.assign(Object.create(ObjectType.prototype), menu);
    };
    return DeserializeMenuHelper.deserializeMarketingMenu(menu, createPolymorphicObject)
        ?? DeserializeMenuHelper.deserializeReportMenu(menu, createPolymorphicObject)
        ?? DeserializeMenuHelper.deserializeShelfTalkerMenu(menu, createPolymorphicObject)
        ?? DeserializeMenuHelper.deserializePrintCardMenu(menu, createPolymorphicObject)
        ?? DeserializeMenuHelper.deserializePrintLabelMenu(menu, createPolymorphicObject)
        ?? DeserializeMenuHelper.deserializePrintMenu(menu, createPolymorphicObject)
        ?? DeserializeMenuHelper.deserializeProductMenu(menu, createPolymorphicObject)
        ?? DeserializeMenuHelper.deserializeSpotlightMenu(menu, createPolymorphicObject)
        ?? createPolymorphicObject(Menu);
  }

  private static deserializeMarketingMenu<T extends Deserializable>(
    menu: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const themeId = menu?.theme as MarketingThemeId;
    const subType = menu?.hydratedTheme?.menuSubType;
    if (subType === MarketingMenuType.UrlPlaylist) {
      return createPolymorphicObject(MarketingUrlPlaylistMenu);
    }
    switch (themeId) {
      case MarketingThemeId.Combo: {
        return createPolymorphicObject(MarketingComboMenu);
      }
      case MarketingThemeId.FeaturedCategory: {
        const cardType = !!menu?.templateId ? menu?.template?.metadata?.cardType : menu?.metadata?.cardType;
        if (FeaturedCategoryUtils.cardTypeIsForStaticGridMenu(cardType)) {
          return createPolymorphicObject(MarketingFeaturedCategoryStaticGridMenu);
        }
        return createPolymorphicObject(MarketingFeaturedCategoryMenu);
      }
      case MarketingThemeId.MarketingLoop: {
        return createPolymorphicObject(MarketingLoopMenu);
      }
      case MarketingThemeId.MarketingProductFeature: {
        return createPolymorphicObject(MarketingFeaturedProductMenu);
      }
      case MarketingThemeId.SmartPlaylist: {
        return createPolymorphicObject(MarketingSmartPlaylistMenu);
      }
    }
    return null;
  }

  private static deserializeShelfTalkerMenu<T extends Deserializable>(
    menu: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const themeId = menu?.theme as ShelfTalkerThemeId;
    switch (themeId) {
      case ShelfTalkerThemeId.FireAndFlower:
        return createPolymorphicObject(FireAndFlowerShelfTalkerMenu);
    }
    return null;
  }

  private static deserializePrintCardMenu<T extends Deserializable>(
    menu: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const themeId = menu?.theme as PrintCardThemeId;
    switch (themeId) {
      case PrintCardThemeId.AgentOrange:
        return createPolymorphicObject(AgentOrangePrintCardMenu);
      case PrintCardThemeId.Doug:
      case PrintCardThemeId.DougAr:
        return createPolymorphicObject(DougPrintCardMenu);
      case PrintCardThemeId.FikaEdibles:
        return createPolymorphicObject(FikaEdiblesCardMenu);
      case PrintCardThemeId.FikaBeverage:
        return createPolymorphicObject(FikaBeveragePrintCardMenu);
      case PrintCardThemeId.Fika:
        return createPolymorphicObject(FikaPrintCardMenu);
      case PrintCardThemeId.GanjikaHouseBeverage:
        return createPolymorphicObject(GanjikaBeveragePrintCardMenu);
      case PrintCardThemeId.FikaAccessories:
        return createPolymorphicObject(FikaAccessoriesPrintCardMenu);
      case PrintCardThemeId.FireAndFlower:
        return createPolymorphicObject(FireAndFlowerPrintCardMenu);
      case PrintCardThemeId.FireAndFlowerDefault:
        return createPolymorphicObject(FireAndFlowerDefaultPrintCardMenu);
      case PrintCardThemeId.FireAndFlowerSale:
        return createPolymorphicObject(FireAndFlowerSalePrintCardMenu);
      case PrintCardThemeId.FireAndFlowerMember:
        return createPolymorphicObject(FireAndFlowerMemberPrintCardMenu);
      case PrintCardThemeId.Generic:
        return createPolymorphicObject(GenericPrintCardMenu);
      case PrintCardThemeId.HoneyHaze:
        return createPolymorphicObject(HoneyHazePrintCardMenu);
      case PrintCardThemeId.JaneDoe:
        return createPolymorphicObject(JaneDoePrintCardMenu);
      case PrintCardThemeId.MissJones:
        return createPolymorphicObject(MissJonesPrintCardMenu);
      case PrintCardThemeId.NYCD:
        return createPolymorphicObject(NycdPrintCardMenu);
      case PrintCardThemeId.OGKush:
        return createPolymorphicObject(OGKushPrintCardMenu);
      case PrintCardThemeId.PlainJane:
        return createPolymorphicObject(PlainJanePrintCardMenu);
      case PrintCardThemeId.Plantlife:
        return createPolymorphicObject(PlantlifePrintCardMenu);
      case PrintCardThemeId.PopsCannabis:
        return createPolymorphicObject(PopsCannabisPrintCardMenu);
      case PrintCardThemeId.Sessions:
        return createPolymorphicObject(SessionsPrintCardMenu);
      case PrintCardThemeId.Stiiizy:
        return createPolymorphicObject(StiiizyPrintCardMenu);
      case PrintCardThemeId.TrueNorthLandscape:
        return createPolymorphicObject(TrueNorthLandscapePrintCardMenu);
      case PrintCardThemeId.TrueNorthPortrait:
        return createPolymorphicObject(TrueNorthPortraitPrintCardMenu);
    }
    return null;
  }

  private static deserializePrintLabelMenu<T extends Deserializable>(
    menu: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const themeId = menu?.theme as PrintLabelThemeId;
    switch (themeId) {
      case PrintLabelThemeId.BaseInventoryLabel:
        return createPolymorphicObject(BaseInventoryPrintLabelMenu);
    }
    return null;
  }

  private static deserializeReportMenu<T extends Deserializable>(
    menu: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const themeId = menu?.theme as ReportThemeId;
    switch (themeId) {
      case ReportThemeId.OrderReview: {
        return createPolymorphicObject(OrderReviewReportMenu);
      }
    }
    return null;
  }

  private static deserializePrintMenu<T extends Deserializable>(
    menu: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const themeId = menu?.theme as PrintThemeId;
    switch (themeId) {
      case PrintThemeId.Banded: {
        return createPolymorphicObject(BandedMenu);
      }
      case PrintThemeId.BellaLuna: {
        return createPolymorphicObject(BellaLunaPrintMenu);
      }
      case PrintThemeId.Blended: {
        return createPolymorphicObject(BlendedMenu);
      }
      case PrintThemeId.BudSupply: {
        return createPolymorphicObject(BudSupplyMenu);
      }
      case PrintThemeId.CalyxTrichomes: {
        return createPolymorphicObject(CalyxTrichomesMenu);
      }
      case PrintThemeId.ComeToBaskTriFold: {
        return createPolymorphicObject(ComeToBaskTrifoldMenu);
      }
      case PrintThemeId.DoubleDutch: {
        return createPolymorphicObject(DoubleDutchMenu);
      }
      case PrintThemeId.DoubleGelato: {
        return createPolymorphicObject(DoubleGelatoMenu);
      }
      case PrintThemeId.DougPrint: {
        return createPolymorphicObject(DougPrintMenu);
      }
      case PrintThemeId.Fika: {
        return createPolymorphicObject(FikaPrintMenu);
      }
      case PrintThemeId.FireAndFlower: {
        return createPolymorphicObject(FireAndFlowerPrintMenu);
      }
      case PrintThemeId.GridLines: {
        return createPolymorphicObject(GridLinesMenu);
      }
      case PrintThemeId.IngramAndSonsSingleFold: {
        return createPolymorphicObject(IngramAndSonsPrintMenu);
      }
      case PrintThemeId.JimmyPrint: {
        return createPolymorphicObject(JimmyPrintMenu);
      }
      case PrintThemeId.LaCanapaPrint: {
        return createPolymorphicObject(LaCanapaPrintMenu);
      }
      case PrintThemeId.OGKushPrint: {
        return createPolymorphicObject(OGKushPrintMenu);
      }
      case PrintThemeId.PlainJane: {
        return createPolymorphicObject(PlainJaneMenu);
      }
      case PrintThemeId.Plantlife: {
        return createPolymorphicObject(PlantlifeMenu);
      }
      case PrintThemeId.PlantlifeNonSmokable: {
        return createPolymorphicObject(PlantlifeNonSmokableMenu);
      }
      case PrintThemeId.SoulBudPrint: {
        return createPolymorphicObject(SoulBudPrintMenu);
      }
      case PrintThemeId.SpaceMonkeyPrint: {
        return createPolymorphicObject(SpaceMonkeyPrintMenu);
      }
      case PrintThemeId.Sprout: {
        return createPolymorphicObject(SproutMenu);
      }
      case PrintThemeId.TightPrint: {
        return createPolymorphicObject(TightPrintMenu);
      }
      case PrintThemeId.TrueNorthPrint: {
        return createPolymorphicObject(TrueNorthPrintMenu);
      }
    }
    return null;
  }

  private static deserializeProductMenu<T extends Deserializable>(
    menu: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const themeId = menu?.theme as ProductThemeId;
    switch (themeId) {
      case ProductThemeId.Amsterdam: {
        return createPolymorphicObject(AmsterdamMenu);
      }
      case ProductThemeId.BlackJack: {
        return createPolymorphicObject(BlackJackMenu);
      }
      case ProductThemeId.Clean: {
        return createPolymorphicObject(CleanMenu);
      }
      case ProductThemeId.Contemporary: {
        return createPolymorphicObject(ContemporaryMenu);
      }
      case ProductThemeId.Doug: {
        return createPolymorphicObject(DougMenu);
      }
      case ProductThemeId.Framed: {
        return createPolymorphicObject(FramedMenu);
      }
      case ProductThemeId.Foggy: {
        return createPolymorphicObject(FoggyMenu);
      }
      case ProductThemeId.IngramAndSons: {
        return createPolymorphicObject(IngramAndSonsMenu);
      }
      case ProductThemeId.Jimmy: {
        return createPolymorphicObject(JimmyMenu);
      }
      case ProductThemeId.KingOfQueens: {
        return createPolymorphicObject(RoyalMenu);
      }
      case ProductThemeId.LaCanapa: {
        return createPolymorphicObject(LaCanapaMenu);
      }
      case ProductThemeId.LobsterButter: {
        return createPolymorphicObject(LobsterButterMenu);
      }
      case ProductThemeId.LuxLeaf: {
        return createPolymorphicObject(LuxLeafMenu);
      }
      case ProductThemeId.MaryJane: {
        return createPolymorphicObject(MaryJaneMenu);
      }
      case ProductThemeId.Night: {
        return createPolymorphicObject(NightMenu);
      }
      case ProductThemeId.PurLife: {
        return createPolymorphicObject(PurLifeMenu);
      }
      case ProductThemeId.Runtz: {
        return createPolymorphicObject(RuntzMenu);
      }
      case ProductThemeId.SmallJane: {
        return createPolymorphicObject(SmallJaneMenu);
      }
      case ProductThemeId.SoulBud: {
        return createPolymorphicObject(SoulBudMenu);
      }
      case ProductThemeId.SuperLemonHaze: {
        return createPolymorphicObject(SuperLemonHazeMenu);
      }
      case ProductThemeId.Tight: {
        return createPolymorphicObject(TightMenu);
      }
      case ProductThemeId.TrueNorth: {
        return createPolymorphicObject(TrueNorthMenu);
      }
      case ProductThemeId.TrueNorthDark: {
        return createPolymorphicObject(TrueNorthDarkMenu);
      }
      case ProductThemeId.Weed: {
        return createPolymorphicObject(WeedMenu);
      }
      case ProductThemeId.WeedStore: {
        return createPolymorphicObject(WeedStoreMenu);
      }
      case ProductThemeId.NorthernLights: {
        return createPolymorphicObject(NorthernLights);
      }
    }
    return null;
  }

  private static deserializeSpotlightMenu<T extends Deserializable>(
    menu: any,
    createPolymorphicObject: (ObjectType: Type<any>) => T
  ): T | null {
    const themeId = menu?.theme as SpotlightThemeId;
    switch (themeId) {
      case SpotlightThemeId.Top: {
        return createPolymorphicObject(TopProductsSpotlightMenu);
      }
    }
    return null;
  }

}
