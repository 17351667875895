import { ApiClient } from './api-client';
import { LoggingService } from '../services/logging-service';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { Menu } from '../models/menu/menu';
import { Display } from '../models/display/dto/display';

@Injectable({ providedIn: 'root' })
export class TemplateAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  public serviceName = 'Template';

  public GetMenuTemplate(locationId: number, menuTemplateId: string): Observable<Menu> {
    const url = Endpoints.GetMenuTemplate(locationId, menuTemplateId);
    return this.apiClient.getObj(Menu, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenuTemplate', err));
        return throwError(err);
      })
    );
  }

  public GetPartialMenuTemplate(locationId: number, menuTemplateId: string, variantIds: string[]): Observable<Menu> {
    const url = Endpoints.GetPartialMenuTemplate(locationId, menuTemplateId, variantIds);
    return this.apiClient.getObj(Menu, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetPartialMenuTemplate', err));
        return throwError(err);
      })
    );
  }

  public GetPartialShelfTalkerTemplate(
    locationId: number,
    menuTemplateId: string,
    sectionIds: string[]
  ): Observable<Menu> {
    const url = Endpoints.GetPartialShelfTalkerTemplate(locationId, menuTemplateId, sectionIds);
    return this.apiClient.getObj(Menu, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetPartialShelfTalkerTemplate', err));
        return throwError(err);
      })
    );
  }

  public GetTemplateCollection(locationId: number, collectionId: string): Observable<Display> {
    const url = Endpoints.GetTemplateCollection(locationId, collectionId);
    return this.apiClient.getObj(Display, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetTemplateCollection', err));
        return throwError(err);
      })
    );
  }

}
