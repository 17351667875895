import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MissJonesCardData } from '../../../../../../../models/print-cards/miss-jones-card-data';

@Injectable()
export class MissJonesPostCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<MissJonesCardData>;

  public readonly headerText$ = this.cardData$.pipe(map(cardData => cardData?.getHeaderText()));

}
