import { Component, ChangeDetectionStrategy, ElementRef, Renderer2 } from '@angular/core';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';
import { OGKushPostCardStackContentViewModel } from './og-kush-post-card-stack-content-view-model';

@Component({
  selector: 'app-og-kush-post-card-stack-content',
  templateUrl: './og-kush-post-card-stack-content.component.html',
  styleUrls: ['./og-kush-post-card-stack-content.component.scss'],
  providers: [OGKushPostCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OGKushPostCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: OGKushPostCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
