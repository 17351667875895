import { IndexCardStack } from './index-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { NycdIndexCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/index-cards-3x5/nycd-index-card-stack-background/nycd-index-card-stack-background.component';
import { NycdIndexCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/index-cards-3x5/nycd-index-card-stack-content/nycd-index-card-stack-content.component';

export class NycdIndexCardStack extends IndexCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return NycdIndexCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return NycdIndexCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
