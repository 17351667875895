<ng-container *ngIf="viewModel.layoutInstructions$ | async as layoutInstructions">
  <div
    *ngFor="let cardsAsData of viewModel.cardAsDataGroupedPerPage$ | async"
    class="print-card-paper"
    [style.justify-content]="layoutInstructions | verticalCardStackJustification">
    <div
      *ngIf="layoutInstructions | customTopPaperMarginInPx as spacing"
      [style.height.px]="spacing"
      [style.width.%]="100"></div>
    <app-print-card-top-cut-lines
      *ngIf="layoutInstructions | isOnRegularPaper"
      [layoutInstructions]="layoutInstructions"
      [nCardsOnPage]="cardsAsData?.length">
    </app-print-card-top-cut-lines>
    <div class="print-card-middle-content">
      <app-print-card-left-cut-lines
        *ngIf="layoutInstructions | isOnRegularPaper"
        [cardsAsData]="cardsAsData"
        [layoutInstructions]="layoutInstructions"
        [nCardsOnPage]="cardsAsData?.length">
      </app-print-card-left-cut-lines>
      <app-card-grid [cardsAsData]="cardsAsData"></app-card-grid>
      <app-print-card-right-cut-lines
        *ngIf="layoutInstructions | isOnRegularPaper"
        [layoutInstructions]="layoutInstructions"
        [nCardsOnPage]="cardsAsData?.length">
      </app-print-card-right-cut-lines>
    </div>
    <app-print-card-bottom-cut-lines
      *ngIf="layoutInstructions | isOnRegularPaper"
      [layoutInstructions]="layoutInstructions"
      [nCardsOnPage]="cardsAsData?.length">
    </app-print-card-bottom-cut-lines>
  </div>
  <div class="print-card-page-break"></div>
</ng-container>
