import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../../../models/base/render-context-component';
import { CardBackgroundViewModel } from './card-background-view-model';
import { CardBackground } from '../../../../../models/protocols/card-background';
import { CardBackgroundInterface } from '../../../interface/card-background-interface';

@Component({ selector: 'app-card-background', template: '' })
export abstract class CardBackgroundComponent extends RenderContextComponent
  implements CardBackgroundInterface, OnChanges {

  constructor(
    public viewModel: CardBackgroundViewModel,
    protected elementRef: ElementRef,
    protected renderer2: Renderer2
  ) {
    super();
  }

  @Input() cardBackground: CardBackground;
  @Input() preview: boolean;

  setupViews() {
    this.handleBleedClass();
    this.viewModel.connectToCardBackground(this.cardBackground);
    this.viewModel.connectToPreview(this.preview);
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cardBackground) {
      this.handleBleedClass();
      this.viewModel.connectToCardBackground(this.cardBackground);
    }
    if (changes.preview) {
      this.handleBleedClass();
      this.viewModel.connectToPreview(this.preview);
    }
  }

  private handleBleedClass(): void {
    (this.cardBackground?.getLayoutInstructions()?.isOnRegularPaper() && !this.preview)
      ? this.renderer2.addClass(this.elementRef.nativeElement, 'bleeding')
      : this.renderer2.removeClass(this.elementRef.nativeElement, 'bleeding');
  }

}
