import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'cutLineExtendedLengthInPx'
})
export class CutLineExtendedLengthInPxPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper, addBleed: number = 0): number | null {
    return ((cardsThatLayoutOntoPaper?.extendCutLineIntoContentAreaByNPx() ?? 0) + addBleed) || null;
  }

}
