import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'getNInternalGridCrosses'
})
export class GetNInternalGridCrossesPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper, nCardsOnPage: number): number {
    const nCrossColumns = (cardsThatLayoutOntoPaper?.getNumberOfVisibleColumnsOnPage(nCardsOnPage) ?? 1) - 1;
    const nCrossRows = (cardsThatLayoutOntoPaper?.getNumberOfVisibleRowsOnPage(nCardsOnPage) ?? 1) - 1;
    return nCrossColumns * nCrossRows;
  }

}
