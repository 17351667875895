@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/business-cards-2x3/jane-doe-business-card-stack-content/jane-doe-business-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
:host .header-content {
  height: 2rem;
  width: 100%;
  text-align: center;
}
:host .header-content .strain-container {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 0.75rem;
  color: #ffffff;
}
:host .header-content .strain-container.dark-text {
  color: #222222;
}
:host .header-content .strain-container.light-text {
  color: #ffffff;
}
:host .product-card-name-and-brand-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0 0.5rem;
}
:host .product-card-name-and-brand-container .product-card-name {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 1rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
}
:host .product-card-name-and-brand-container .product-card-brand {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 0.625rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
}
:host .product-card-cannabinoid-container {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
:host .product-card-cannabinoid-container .property-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.625rem;
  align-items: center;
  box-sizing: border-box;
}
:host .product-card-cannabinoid-container .property-container .value {
  overflow: hidden;
  white-space: nowrap;
  max-width: 6rem;
}
:host .product-card-badge-and-label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}
:host .product-card-badge-and-label-container .badge {
  height: 0.9375rem;
}
:host .product-card-badge-and-label-container .print-card-label {
  align-self: flex-start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .product-card-badge-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: indivisible, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.625rem;
  width: fit-content;
  display: block;
  max-width: 6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
:host .product-card-badge-and-label-container .print-card-label ::ng-deep .product-flag-label {
  border-radius: unset;
}
:host .product-card-badge-and-label-container .print-card-label ::ng-deep .flag-body {
  display: block;
  max-width: 5rem;
  justify-content: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
:host .product-card-price-size-and-quantity-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.125rem;
}
:host .product-card-price-size-and-quantity-container ::ng-deep .product-variant-price {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.125rem;
}
:host .product-card-price-size-and-quantity-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 1.125rem;
}
:host .product-card-price-size-and-quantity-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 1.5rem;
}
:host .product-card-price-size-and-quantity-container .product-card-size {
  font-family: indivisible, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.75rem;
}
:host .footer-content {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 0.75rem;
  width: 100%;
  text-align: center;
}
:host .footer-content.dark-text {
  color: #222222;
}
:host .footer-content.light-text {
  color: #ffffff;
}
:host ::ng-deep .force-italic-style * {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: italic;
}
/*# sourceMappingURL=jane-doe-business-card-stack-content.component.css.map */
