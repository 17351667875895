import { CardsThatLayoutOntoPaper } from '../cards-that-layout-onto-paper';
import { Type } from '@angular/core';
import type { CardBackgroundComponent } from '../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import type { ShelfTalkerCardData } from '../../../shelf-talkers/shelf-talker-card-data';
import type { ShelfTalkerCardContentComponent } from '../../../../modules/print-cards/components/cards/card-content/shared/shelf-talker-card-content.component';
import type { ShelfTalkerCardSize } from '../../../enum/dto/shelf-talker-card-size';

export abstract class ShelfTalkerCard extends CardsThatLayoutOntoPaper {

  cardSize: ShelfTalkerCardSize;

  abstract override getCardBackgroundComponent(data: ShelfTalkerCardData): Type<CardBackgroundComponent> | null;
  abstract override getCardContentComponent(data: ShelfTalkerCardData): Type<ShelfTalkerCardContentComponent> | null;

}
