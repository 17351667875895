@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/address-cards-1x3/jane-doe-address-card-stack-content/jane-doe-address-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}
:host .main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
  width: 100%;
}
:host .main-content .product-card-name {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 1rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: center;
  max-width: calc(100% - 16px);
}
:host .main-content .product-card-info {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  align-items: center;
  width: calc(100% - 16px);
}
:host .main-content .product-card-info .product-card-badge-container {
  display: flex;
}
:host .main-content .product-card-info .product-card-badge-container .product-card-badge {
  width: 1rem;
}
:host .main-content .product-card-info .pricing-container {
  justify-self: center;
}
:host .main-content .product-card-info .pricing-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: row;
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  height: 100%;
  gap: 0.5rem;
}
:host .main-content .product-card-info .pricing-container ::ng-deep .product-variant-price .product-variant-current-price,
:host .main-content .product-card-info .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
}
:host .main-content .product-card-info .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 1rem;
}
:host .main-content .product-card-info .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 0.75rem;
  align-self: center;
}
:host .main-content .product-card-info .product-card-size {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 0.75rem;
  justify-self: right;
  align-self: center;
}
:host ::ng-deep .force-italic-style * {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: italic;
}
/*# sourceMappingURL=jane-doe-address-card-stack-content.component.css.map */
