import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { PrintCardContentComponent } from '../../cards/card-content/shared/print-card-content.component';
import { PrintLabelContentViewModel } from './print-label-content-view-model';
import { LabelData } from '../../../../../models/print-labels/label-data';

@Component({ template: '' })
export abstract class PrintLabelContentComponent extends PrintCardContentComponent {

  constructor(
    public viewModel: PrintLabelContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @Input() data: LabelData;

}
