import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { MissJonesPostCardStackContentViewModel } from './miss-jones-post-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';

@Component({
  selector: 'app-miss-jones-post-card-stack-content',
  templateUrl: './miss-jones-post-card-stack-content.component.html',
  styleUrls: ['./miss-jones-post-card-stack-content.component.scss'],
  providers: [MissJonesPostCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissJonesPostCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: MissJonesPostCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
