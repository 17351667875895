import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { FireAndFlowerShelfTalkerContentViewModel } from './fire-and-flower-shelf-talker-content-view-model';
import { FireAndFlowerShelfTalkerCardData } from '../../../../../../../../models/shelf-talkers/fire-and-flower-shelf-talker-card-data';

@Component({
  selector: 'app-fire-and-flower-shelf-talker-content',
  templateUrl: './fire-and-flower-shelf-talker-content.component.html',
  styleUrl: './fire-and-flower-shelf-talker-content.component.scss',
  providers: [FireAndFlowerShelfTalkerContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireAndFlowerShelfTalkerContentComponent extends RenderContextComponent implements OnChanges {

  constructor(
    public viewModel: FireAndFlowerShelfTalkerContentViewModel
  ) {
    super();
  }

  @Input() shelfTalkerCardData: FireAndFlowerShelfTalkerCardData;

  setupViews() {}

  setupBindings() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shelfTalkerCardData) {
      this.viewModel.connectToShelfTalkerCardData(this.shelfTalkerCardData);
    }
  }

}
