import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { Observable, of } from 'rxjs';
import { SpecificPriceWithoutDiscounts } from '../../../../../../../models/enum/shared/specific-price-without-discounts.enum';

@Injectable()
export class FireAndFlowerDefaultCardStackContentViewModel extends PrintCardContentViewModel {

  public mainPriceSpecificPriceWithoutDiscounts$: Observable<SpecificPriceWithoutDiscounts[]> = of(
    [
      SpecificPriceWithoutDiscounts.LocationSpecificBasePrice,
      SpecificPriceWithoutDiscounts.CompanySpecificBasePrice,
      SpecificPriceWithoutDiscounts.BasePrice
    ]
  );

  public addSpaceAfterDollarSign = (price: string): string => price?.replace('$', '$ ');

}
