import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'cardContentHeight'
})
export class CardContentHeightPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper): number {
    return cardsThatLayoutOntoPaper?.getCardHeightInPixels() || 0;
  }

}
