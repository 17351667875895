import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { SessionsCardData } from '../../../../../../../models/print-cards/sessions-card-data';
import { AssetUtils } from '../../../../../../../utils/asset-utils';
import { StrainType } from '../../../../../../../models/enum/dto/strain-classification.enum';
import { CardStack } from '../../../../../../../models/menu/section/card-stacks/card-stack';

@Injectable()
export class SessionsPostCardStackContentViewModel extends PrintCardContentViewModel {

  private static COLOR_STRIP_WIDTH_IN_PERCENT = 18.75;
  private static MAIN_CONTENT_WIDTH_IN_PERCENT = 81.25;

  public readonly cardData$: Observable<SessionsCardData>;

  public readonly cardStack$ = this.cardData$.pipe(map(cardData => cardData?.section));

  public cardAssetUrl$ = this.cardData$.pipe(
    map(cardData => AssetUtils.sessionsProductTypeUrls(cardData))
  );

  public qtyAndSize$ = this.cardData$.pipe(
    map(cardData => cardData?.getQuantityAndSizeString())
  );

  public readonly priceStreamOverride$ = this.cardData$.pipe(
    map(cardData => cardData?.getOverridePriceStreamForLabelComponent())
  );

  public effectsText$ = this.cardData$.pipe(
    map(cardData => {
      const strainType = cardData?.rowStrainType();
      switch (strainType) {
        case StrainType.Sativa:
        case StrainType.SativaDominant:
          return 'BOOST';
        case StrainType.Indica:
        case StrainType.IndicaDominant:
          return 'SLUMBER';
        case StrainType.Hybrid:
        case StrainType.CBD:
          return 'RELAX';
        default:
          return '';
      }
    })
  );

  public colorStripWidth$ = combineLatest([
    this.cardStack$,
    this.preview$
  ]).pipe(
    map(([cardStack, preview]) => {
      return this.getDimensionAdjustment(
        cardStack,
        SessionsPostCardStackContentViewModel.COLOR_STRIP_WIDTH_IN_PERCENT,
        preview
      );
    })
  );

  public mainContentWidth$ = combineLatest([
    this.cardStack$,
    this.preview$
  ]).pipe(
    map(([cardStack, preview]) => {
      return this.getDimensionAdjustment(
        cardStack,
        SessionsPostCardStackContentViewModel.MAIN_CONTENT_WIDTH_IN_PERCENT,
        preview
      );
    })
  );

  private getDimensionAdjustment(cardStack: CardStack, colorStripWidth: number, preview: boolean): string {
    const bleed = (cardStack?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH;
    const additionalWidth = cardStack?.isOnRegularPaper() && !preview ? bleed : 0;
    return `calc(${colorStripWidth}% + ${additionalWidth / 2}px)`;
  }

}
