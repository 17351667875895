<div class="outside-border" [style.background-color]="viewModel.borderColor$ | async">
  <div
    class="main-content"
    [style.width]="viewModel.borderAdjustment$ | async"
    [style.height]="viewModel.borderAdjustment$ | async"
    [style.background-color]="viewModel.cardColor$ | async">
    <app-print-card-background-image-with-color-overlay-and-opacity [cardData]="cardBackground">
    </app-print-card-background-image-with-color-overlay-and-opacity>
  </div>
</div>
