/* src/app/modules/print-cards/components/cards/render-card-background/render-card-background.component.scss */
.print-card-background-container {
  position: absolute;
  inset: 0;
  z-index: 0;
}
app-print-card-background-inflator {
  position: absolute;
  inset: 0;
  z-index: 1;
}
.print-card-background-blur {
  position: absolute;
  inset: 0;
  z-index: 2;
}
.print-card-background-color-overlay {
  position: absolute;
  inset: 0;
  z-index: 3;
}
/*# sourceMappingURL=render-card-background.component.css.map */
