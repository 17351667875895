import { AddressCardStack } from './address-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { JaneDoeAddressCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/address-cards-1x3/jane-doe-address-card-stack-content/jane-doe-address-card-stack-content.component';
import { JaneDoeAddressCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/address-cards-1x3/jane-doe-address-card-stack-background/jane-doe-address-card-stack-background.component';

export class JaneDoeAddressCardStack extends AddressCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return JaneDoeAddressCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return JaneDoeAddressCardStackContentComponent;
  }

}
