<div class="card-content">
  <div class="background-content" [style.background-color]="viewModel.cardColor$ | async">
    <app-print-card-background-image-with-color-overlay-and-opacity [cardData]="data">
    </app-print-card-background-image-with-color-overlay-and-opacity>
  </div>
  <div class="top-container">
    <div class="top-row">
      <div
        class="product-title"
        [style.color]="(viewModel.headerTextColor$ | async) || (viewModel.bodyTextColor$ | async)"
        appScaleDownText
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
        {{ data | sectionRowViewModelBasicMethod: 'getReadableStrainType' }}
      </div>
      <div class="product-card-container">
        <ng-container *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async">
          <div
            class="product-card-size"
            [style.color]="viewModel.bodyTextColor$ | async"
            [class.two-col]="(viewModel.spoofedGridColViewModels$ | async)?.length === 2"
            appScaleDownText
            [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
            {{ viewModel | getExpandedGridSize: spoofedGridColVM | async | trimSafely }}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="bottom-row">
      <div class="content-container">
        <div *ngIf="viewModel.hasBadges$ | async; else noBadgeTemplate" class="product-badge-container">
          <img
            *ngFor="let variantBadge of data | printCardBadges"
            appImage
            class="product-badge"
            [asset]="variantBadge?.image"
            alt="badge"
            src="" />
        </div>
        <ng-template #noBadgeTemplate>
          <div class="product-badge-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 21"
              class="polygon-badge"
              [style.fill]="(viewModel.cardAccentColor$ | async) || (viewModel.bodyTextColor$ | async)">
              <path d="M22 10.5L16.5 20.0263L5.5 20.0263L-4.80825e-07 10.5L5.5 0.97372L16.5 0.97372L22 10.5Z" />
            </svg>
          </div>
        </ng-template>
        <div class="outer-content-container" [style.color]="viewModel.bodyTextColor$ | async">
          <div class="top-row">
            <div class="product-name" appScaleDownText [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
              {{ data | sectionRowViewModelBasicMethod: 'getRowTitle' | trimSafely }}
            </div>
            <div
              class="product-price-container"
              [class.grid-mode]="!(viewModel.isLineItemMode$ | async)"
              [class.two-col]="(viewModel.spoofedGridColViewModels$ | async)?.length === 2">
              <ng-container *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
                <div
                  class="product-price regular-price"
                  [style.color]="viewModel.bodyTextColor$ | async"
                  appScaleDownText
                  [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                  <app-column-primary-price
                    [menu]="viewModel.cardStackMenu$ | async"
                    [section]="viewModel.cardStack$ | async"
                    [colVm]="spoofedGridColVM"
                    [rowVm]="viewModel.cardData$ | async"
                    [locConfig]="viewModel.locationConfig$ | async"
                    [locationId]="viewModel.locationId$ | async"
                    [productStylingVm]="viewModel">
                  </app-column-primary-price>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="bottom-row">
            <div class="inner-content-container">
              <div
                class="product-brand"
                appScaleDownText
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                {{ data | sectionRowViewModelBasicMethod: 'getBrand' }}
              </div>
              <app-label-inflator
                class="print-card-label"
                [columnLabel]="false"
                [labelComponentInterface]="data"
                [reset]="false">
              </app-label-inflator>
            </div>
            <div
              *ngIf="viewModel.atLeastOneGridVariantOnSale$ | async"
              class="product-price-container"
              [class.grid-mode]="!(viewModel.isLineItemMode$ | async)"
              [class.two-col]="(viewModel.spoofedGridColViewModels$ | async)?.length === 2">
              <ng-container *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
                <div
                  class="product-price sale-price"
                  [style.color]="viewModel.bodyTextColor$ | async"
                  appScaleDownText
                  [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                  <app-column-primary-price
                    [menu]="viewModel.cardStackMenu$ | async"
                    [section]="viewModel.cardStack$ | async"
                    [colVm]="spoofedGridColVM"
                    [rowVm]="viewModel.cardData$ | async"
                    [locConfig]="viewModel.locationConfig$ | async"
                    [locationId]="viewModel.locationId$ | async"
                    [productStylingVm]="viewModel"
                    [showNgContentContainerIfNoDiscount]="true">
                  </app-column-primary-price>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr
    class="card-separator"
    [style.background-color]="(viewModel.cardAccentColor$ | async) || (viewModel.bodyTextColor$ | async)" />
  <div class="bottom-container">
    <div class="left-column">
      <div class="cannabinoids" [style.color]="viewModel.bodyTextColor$ | async">
        <div
          *ngIf="
            !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
            (data | sectionRowViewModelBasicMethod: 'getThc') as thc
          "
          class="property-container">
          <div class="title">THC</div>
          <div
            class="value"
            appScaleDownText
            [scaleDownLinkedKey]="viewModel.variantId$ | async"
            [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
            {{ thc }}
          </div>
        </div>
        <div
          *ngIf="
            !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
            (data | sectionRowViewModelBasicMethod: 'getCbd') as cbd
          "
          class="property-container">
          <div class="title">CBD</div>
          <div
            class="value"
            appScaleDownText
            [scaleDownLinkedKey]="viewModel.variantId$ | async"
            [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
            {{ cbd }}
          </div>
        </div>
      </div>
    </div>
    <div class="product-column-desc">
      <div
        class="product-card-desc"
        [style.color]="viewModel.bodyTextColor$ | async"
        appScaleDownText
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
        {{ data | sectionRowViewModelBasicMethod: 'getProductDesc' }}
      </div>
    </div>
  </div>
</div>
