@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/address-cards-1x3/ganjika-beverage-address-card-stack-content/ganjika-beverage-address-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5rem 0.75rem;
  overflow: hidden;
  color: #000000;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  align-items: stretch;
}
:host .main-content .top-container {
  display: flex;
}
:host .main-content .top-container .product-cannabinoids-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 0.15rem;
}
:host .main-content .top-container .product-cannabinoids-container .cannabinoid {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
:host .main-content .top-container .product-cannabinoids-container .cannabinoid-separator {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content .top-container .product-cannabinoids-container .cannabinoid-title {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
}
:host .main-content .top-container .product-cannabinoids-container .cannabinoid-value {
  text-transform: uppercase;
  font-size: 0.75rem;
}
:host .main-content .bottom-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
:host .main-content .bottom-container .product-brand-and-product {
  display: flex;
  flex-direction: column;
}
:host .main-content .bottom-container .product-brand-and-product .product-card-brand {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
}
:host .main-content .bottom-container .product-brand-and-product .product-card-name {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.8125rem;
}
:host .main-content .bottom-container .card-separator {
  width: 100%;
  height: 0.125rem;
  background-color: #000000;
  border: 0;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
:host .main-content .bottom-container .product-card-info {
  display: flex;
  flex-direction: row;
  height: 2.3175rem;
}
:host .main-content .bottom-container .product-card-info .left-side {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
:host .main-content .bottom-container .product-card-info .left-side .product-card-badge-and-label-container {
  display: flex;
  flex-direction: row;
  gap: 0.3125rem;
}
:host .main-content .bottom-container .product-card-info .left-side .product-card-badge-and-label-container .product-card-badge {
  height: 1rem;
  width: 1rem;
}
:host .main-content .bottom-container .product-card-info .left-side .product-card-badge-and-label-container .print-card-label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .bottom-container .product-card-info .left-side .product-card-badge-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.5625rem;
  border-radius: 0.25rem;
  margin: 0.1rem 0 0 0;
  width: fit-content;
}
:host .main-content .bottom-container .product-card-info .left-side .strain-and-quantity-container {
  display: flex;
  flex-direction: row;
  gap: 0.1825rem;
  align-items: center;
}
:host .main-content .bottom-container .product-card-info .left-side .strain-and-quantity-container .product-card-strain-type {
  text-transform: uppercase;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
}
:host .main-content .bottom-container .product-card-info .left-side .strain-and-quantity-container .product-card-strain-quantity-separator {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content .bottom-container .product-card-info .left-side .strain-and-quantity-container .product-card-quantity {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.75rem;
}
:host .main-content .bottom-container .product-card-info .product-card-price-container {
  margin-top: auto;
  margin-left: auto;
}
:host .main-content .bottom-container .product-card-info .product-card-price-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
:host .main-content .bottom-container .product-card-info .product-card-price-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 1rem;
}
:host .main-content .bottom-container .product-card-info .product-card-price-container ::ng-deep .product-variant-price .product-variant-previous-price .product-variant-price-decoration {
  font-size: 1rem;
}
:host .main-content .bottom-container .product-card-info .product-card-price-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 1rem;
}
:host .main-content .bottom-container .product-card-info .product-card-price-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: 1rem;
}
:host .main-content ::ng-deep .force-italic-style * {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: italic;
}
:host .main-content ::ng-deep .force-bold-style * {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
}
/*# sourceMappingURL=ganjika-beverage-address-card-stack-content.component.css.map */
