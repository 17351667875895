@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/business-cards-2x3/stiiizy-card-stack-content/stiiizy-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.card-content {
  font-family: Archivo, sans-serif;
  font-weight: 400;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}
.card-content app-stiiizy-side-bar {
  width: 0.75rem;
  height: 100%;
}
/*# sourceMappingURL=stiiizy-card-stack-content.component.css.map */
