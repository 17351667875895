import { FONT, FontStyleSheet } from '../font-style-sheets';
import { ShelfTalkerMenu } from './shelf-talker-menu';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';

export class FireAndFlowerShelfTalkerMenu extends ShelfTalkerMenu {

  getFontStyleSheets(): FontStyleSheet[] {
    return [
      FONT.futuraPtBook,
      FONT.futuraPtMedium
    ];
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  override variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand
    ];
  }

}
