@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/shared/components/fire-and-flower-shelf-talker-content/fire-and-flower-shelf-talker-item/fire-and-flower-shelf-talker-item.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.fire-and-flower-shelf-talker-item {
  width: 100%;
  box-sizing: border-box;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}
.fire-and-flower-shelf-talker-item .brand-logo {
  height: 3.4375rem;
  width: 3.4375rem;
  object-fit: contain;
  align-content: center;
}
.fire-and-flower-shelf-talker-item .text-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.fire-and-flower-shelf-talker-item .text-content .brand-name {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow-x: clip;
  max-width: 6rem;
  line-height: 100%;
  letter-spacing: 0.28px;
}
.fire-and-flower-shelf-talker-item .text-content .additional-info {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 100%;
  letter-spacing: 0.26px;
}
.fire-and-flower-shelf-talker-item .text-content .sizing-info {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 100%;
  letter-spacing: 0.24px;
}
.fire-and-flower-shelf-talker-item .text-content .price-info {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 100%;
}
.fire-and-flower-shelf-talker-item.larger {
  height: 5rem;
  gap: 1rem;
}
.fire-and-flower-shelf-talker-item.larger .brand-logo {
  height: 4.375rem;
  width: 4.375rem;
}
.fire-and-flower-shelf-talker-item.larger .text-content .brand-name {
  font-size: 1rem;
  letter-spacing: 0.32px;
  max-width: 7.5rem;
}
.fire-and-flower-shelf-talker-item.larger .text-content .additional-info {
  font-size: 1rem;
  letter-spacing: 0.32px;
}
.fire-and-flower-shelf-talker-item.larger .text-content .sizing-info {
  font-size: 0.9375rem;
  letter-spacing: 0.3px;
}
.fire-and-flower-shelf-talker-item.larger .text-content .price-info {
  font-size: 1rem;
}
/*# sourceMappingURL=fire-and-flower-shelf-talker-item.component.css.map */
