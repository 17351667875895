import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../../../models/base/render-context-component';
import { RenderCardBackgroundViewModel } from './render-card-background-view-model';
import { CardData } from '../../../../../models/print-cards/card-data';
import { ShelfTalkerCardData } from '../../../../../models/shelf-talkers/shelf-talker-card-data';

@Component({
  selector: 'app-render-card-background',
  templateUrl: './render-card-background.component.html',
  styleUrls: ['./render-card-background.component.scss'],
  providers: [RenderCardBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderCardBackgroundComponent extends RenderContextComponent implements OnChanges {

  constructor(
    public viewModel: RenderCardBackgroundViewModel
  ) {
    super();
  }

  @Input() preview: boolean;
  @Input() parentHeight: number;
  @Input() parentWidth: number;
  @Input() data: CardData | ShelfTalkerCardData;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentHeight) this.viewModel.connectToParentHeight(this.parentHeight);
    if (changes.parentWidth) this.viewModel.connectToParentWidth(this.parentWidth);
    if (changes.preview) this.viewModel.connectToPreview(this.preview);
    if (changes.data) this.viewModel.connectToCardData(this.data);
  }

  setupBindings() {
  }

  setupViews() {
  }

}
