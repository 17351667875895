<div class="bottom-cut-lines" [class.extended]="layoutInstructions | isExtendedCutLine">
  <div
    class="left-sliver"
    [style.width.px]="layoutInstructions | withContentBleed: false"
    [style.height]="
      layoutInstructions | calculateExtendedCutLineLengthString: (layoutInstructions | withContentBleed: false)
    "
    [style.bottom.px]="
      layoutInstructions | cutLineExtendedLengthInPx: (layoutInstructions | withContentBleed: false)
    "></div>
  <ng-template ngFor [ngForOf]="layoutInstructions | iterableCardStackColumns: nCardsOnPage" let-last="last">
    <div class="spacer" [style.width.px]="layoutInstructions | cardContentWidth"></div>
    <div
      *ngIf="!last"
      class="dividing-sliver-container"
      [style.width.px]="layoutInstructions | withContentBleed: false : 2">
      <div
        class="dividing-sliver"
        [style.height]="
          layoutInstructions | calculateExtendedCutLineLengthString: (layoutInstructions | withContentBleed: false)
        "
        [style.bottom.px]="
          layoutInstructions | cutLineExtendedLengthInPx: (layoutInstructions | withContentBleed: false)
        "
        [style.border-right]="(layoutInstructions | withContentBleed: false) === 0 ? 'unset' : null"></div>
      <div
        class="dividing-sliver-horizontal-left-line"
        [style.width.px]="layoutInstructions | cutLineExtendedLengthInPx"
        [style.left.px]="-(layoutInstructions | cutLineExtendedLengthInPx)"
        [style.top.px]="-(layoutInstructions | withContentBleed: false)"></div>
      <div
        class="dividing-sliver-horizontal-middle-line"
        [style.width.px]="layoutInstructions | withContentBleed: false : 2"
        [style.left.px]="0"
        [style.top.px]="-(layoutInstructions | withContentBleed: false)"></div>
      <div
        class="dividing-sliver-horizontal-right-line"
        [style.width.px]="layoutInstructions | cutLineExtendedLengthInPx"
        [style.left.px]="layoutInstructions | withContentBleed: false : 2"
        [style.top.px]="-(layoutInstructions | withContentBleed: false)"></div>
    </div>
  </ng-template>
  <div
    class="right-sliver"
    [style.width.px]="layoutInstructions | withContentBleed: false"
    [style.height]="
      layoutInstructions | calculateExtendedCutLineLengthString: (layoutInstructions | withContentBleed: false)
    "
    [style.bottom.px]="
      layoutInstructions | cutLineExtendedLengthInPx: (layoutInstructions | withContentBleed: false)
    "></div>
</div>
