import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';
import { NycdIndexCardStackContentViewModel } from './nycd-index-card-stack-content-view-model';

@Component({
  selector: 'app-nycd-index-card-stack-content',
  templateUrl: './nycd-index-card-stack-content.component.html',
  styleUrls: ['./nycd-index-card-stack-content.component.scss'],
  providers: [NycdIndexCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NycdIndexCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: NycdIndexCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
