/* src/app/modules/print-cards/components/cards/backgrounds/index-cards-3x5/fika-accessories-index-card-background/fika-accessories-index-card-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
}
:host .main-content {
  flex: 1;
}
:host .main-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=fika-accessories-index-card-background.component.css.map */
