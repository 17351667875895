import { ShelfTalkerCardData } from './shelf-talker-card-data';
import type { Variant } from '../product/dto/variant';
import { VariantsGroupedByBrand } from '../../modules/print-cards/models/variants-grouped-by-brand';
import { SortUtils } from '../../utils/sort-utils';
import type { LocationPriceStream } from '../enum/shared/location-price-stream';
import { SectionSortProductInfo } from '../enum/dto/section-sort-option.enum';

export class GroupedByBrandShelfTalkerCardData extends ShelfTalkerCardData {

  protected getVariantsGroupedByBrand(): Map<string, Variant[]> {
    const brandGroupedVariants = new Map<string, Variant[]>(); // Map<Brand, Variant[]>
    this.variants?.forEach(variant => {
      const brand = variant?.brand?.toLowerCase();
      if (brand) {
        const variants = brandGroupedVariants.get(brand) || [];
        variants.push(variant);
        brandGroupedVariants.set(brand, variants);
      }
    });
    return brandGroupedVariants;
  }

  getVariantsGroupedByBrandThenSortedByBrandAsc(): VariantsGroupedByBrand[] {
    const brandGroupedVariants = this.getVariantsGroupedByBrand();
    return [...(brandGroupedVariants.entries() || [])]
      .sort((a, b) => SortUtils.getMovement(a[0]?.localeCompare(b[0], 'en', { numeric: true })))
      .map(([_, variants]) => new VariantsGroupedByBrand(variants));
  }

  getVariantsGroupedByBrandThenSortedByBrandDesc(): VariantsGroupedByBrand[] {
    const brandGroupedVariants = this.getVariantsGroupedByBrand();
    return [...(brandGroupedVariants.entries() || [])]
      .sort((a, b) => SortUtils.getMovement(b[0]?.localeCompare(a[0], 'en', { numeric: true })))
      .map(([_, variants]) => new VariantsGroupedByBrand(variants));
  }

  getVariantsGroupedByBrandThenSortedByPriceAsc(
    themeId: string,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream,
    hideSale: boolean
  ): VariantsGroupedByBrand[] {
    const brandGroupedVariants = this.getVariantsGroupedByBrand();
    return [...(brandGroupedVariants.entries() || [])]
      .map(([_, variants]) => new VariantsGroupedByBrand(variants))
      .sort((a, b) => {
        return SortUtils.numberAscendingZerosAndNullsLast(
          a?.getMinPrice(themeId, locationId, companyId, priceStream, hideSale),
          b?.getMinPrice(themeId, locationId, companyId, priceStream, hideSale)
        );
      });
  }

  getVariantsGroupedByBrandThenSortedByPriceDesc(
    themeId: string,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream,
    hideSale: boolean
  ): VariantsGroupedByBrand[] {
    const brandGroupedVariants = this.getVariantsGroupedByBrand();
    return [...(brandGroupedVariants.entries() || [])]
      .map(([_, variants]) => new VariantsGroupedByBrand(variants))
      .sort((a, b) => {
        return SortUtils.numberDescendingZerosAndNullsLast(
          a?.getMaxPrice(themeId, locationId, companyId, priceStream, hideSale),
          b?.getMaxPrice(themeId, locationId, companyId, priceStream, hideSale)
        );
      });
  }

  getVariantsGroupedByBrandThenSortedBySection(): VariantsGroupedByBrand[] {
    const themeId = this.menu?.theme;
    const locId = this.menu?.locationId;
    const compId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.menu?.menuOptions?.hideSale;
    switch (this.section?.sorting) {
      case SectionSortProductInfo.BrandAsc:
        return this.getVariantsGroupedByBrandThenSortedByBrandAsc();
      case SectionSortProductInfo.BrandDesc:
        return this.getVariantsGroupedByBrandThenSortedByBrandDesc();
      case SectionSortProductInfo.PriceAsc:
        return this.getVariantsGroupedByBrandThenSortedByPriceAsc(themeId, locId, compId, priceStream, hideSale);
      case SectionSortProductInfo.PriceDesc:
        return this.getVariantsGroupedByBrandThenSortedByPriceDesc(themeId, locId, compId, priceStream, hideSale);
      default:
        return this.getVariantsGroupedByBrandThenSortedByBrandAsc();
    }
  }

}
