<div class="color-strip" [style.width]="viewModel.colorStripWidth$ | async">
  <div class="upper-container">
    <img
      *ngIf="viewModel.cardAssetUrl$ | async as cardAssetUrl"
      class="product-image"
      [src]="cardAssetUrl"
      alt="product image" />

    <div
      *ngIf="
        !(data | sectionRowViewModelBasicMethod: 'isNonCannabinoidVariant') &&
        (data | sectionRowViewModelBasicMethod: 'getReadableStrainType' | trimSafely) as strainType
      "
      class="card-strain-type"
      appScaleDownText
      [scaleDownLinkedKey]="viewModel.variantId$ | async"
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
      {{ strainType }}
    </div>

    <div
      *ngIf="!(data | sectionRowViewModelBasicMethod: 'isNonCannabinoidVariant')"
      class="qty-and-size-container"
      appScaleDownText
      [scaleDownLinkedKey]="viewModel.variantId$ | async"
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
      {{ viewModel.qtyAndSize$ | async }}
    </div>
  </div>

  <div class="lower-container">
    <div class="effects-container">{{ viewModel.effectsText$ | async }}</div>
    <div class="company-name">session</div>
  </div>
</div>
<div class="main-content" [style.width]="viewModel.mainContentWidth$ | async">
  <div class="product-info">
    <div
      *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
      class="product-card-name"
      [style.color]="viewModel.headerTextColor$ | async">
      {{ title | trimSafely }}
    </div>
    <div
      *ngIf="data | sectionRowViewModelBasicMethod: 'getBrand' as brand"
      class="product-card-brand"
      [style.color]="viewModel.bodyTextColor$ | async">
      {{ brand | trimSafely }}
    </div>
    <div
      *ngIf="!data?.isAccessory() && !(viewModel.isNonCannabinoidVariant$ | async)"
      class="product-card-cannabinoids-container">
      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getThc' as thc"
        class="property-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        THC {{ thc }}
      </div>

      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getCbd' as cbd"
        class="property-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        CBD {{ cbd }}
      </div>
    </div>

    <div class="badge-and-label-container">
      <app-label-inflator
        class="print-card-label"
        [class.default-label]="!(viewModel.labelIsFlagStyle$ | async)"
        [class.flag-label]="viewModel.labelIsFlagStyle$ | async"
        [columnLabel]="false"
        [labelComponentInterface]="data"
        [reset]="false">
      </app-label-inflator>

      <img
        *ngFor="let variantBadge of data | printCardBadges"
        appImage
        class="badge"
        [asset]="variantBadge?.image"
        alt="badge"
        src="" />
    </div>

    <app-print-card-description-container
      [cardData]="data"
      [bodyTextColor]="viewModel.bodyTextColor$ | async"></app-print-card-description-container>
  </div>

  <app-sessions-price></app-sessions-price>
</div>
