import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'withContentBleed'
})
export class WithContentBleedPipe implements PipeTransform {

  transform(
    cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper | null,
    preview: boolean,
    multiplier: number = 1
  ): number {
    return cardsThatLayoutOntoPaper?.withContentBleed(preview, multiplier) || 0;
  }

}
