import { Pipe, PipeTransform } from '@angular/core';
import { CutLineMode } from '../../../models/enum/shared/cut-line-mode.enum';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'isExtendedCutLine'
})
export class IsExtendedCutLinePipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper | null): boolean {
    return cardsThatLayoutOntoPaper?.cutLineMode === CutLineMode.Extended;
  }

}
