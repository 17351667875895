import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'iterableCardStackRows'
})
export class IterableCardStackRowsPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper, nCardsOnPage: number): any[] {
    return Array(cardsThatLayoutOntoPaper?.getNumberOfVisibleRowsOnPage(nCardsOnPage) || 1);
  }

}
