@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/index-cards-3x5/doug-index-card-stack-content/doug-index-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: block;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0.75rem;
  box-sizing: border-box;
}
:host .card-header {
  position: relative;
  display: block;
  color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  background-color: #000000;
  height: 2.25rem;
  max-height: 2.25rem;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
}
:host .card-header .header-content.left-content {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}
:host .card-header .header-content.right-content {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}
:host .card-header .header-content .strain-container {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  vertical-align: middle;
}
:host .card-header .header-content .product-size-and-type-container {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  vertical-align: middle;
}
:host .card-body {
  position: relative;
  height: calc(16.5rem - 2.25rem);
  max-height: calc(16.5rem - 2.25rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 0.5rem 0.5rem 0.5rem;
}
:host .card-body .background-content {
  position: absolute;
  inset: 0;
  z-index: -1;
}
:host .card-body .background-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
:host .card-body .product-card-name {
  height: 3.5625rem;
  box-sizing: border-box;
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 2.375rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .card-body .bottom-body-content {
  position: relative;
  height: 10.1875rem;
  max-height: 10.1875rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}
:host .card-body .bottom-body-content .card-body-left-column {
  position: absolute;
  top: 0;
  left: 0;
  height: 10.1875rem;
  max-height: 10.1875rem;
  display: grid;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 1;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .product-card-brand {
  box-sizing: border-box;
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  width: fit-content;
  text-overflow: ellipsis;
  margin: 0;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .default-label ::ng-deep .product-label {
  overflow: hidden;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .flag-label ::ng-deep .flag-body {
  overflow: hidden;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-line-mode {
  align-self: start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-line-mode ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  display: block;
  font-size: 0.75rem;
  width: fit-content;
  max-width: 17rem;
  text-overflow: ellipsis;
  justify-content: inherit;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-line-mode ::ng-deep .flag-body {
  display: block;
  max-width: 17rem;
  justify-content: inherit;
  text-overflow: ellipsis;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-label-3-columns {
  align-self: start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-label-3-columns ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  display: block;
  font-size: 0.75rem;
  width: fit-content;
  max-width: 9rem;
  text-overflow: ellipsis;
  justify-content: inherit;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-label-3-columns ::ng-deep .flag-body {
  display: block;
  max-width: 9rem;
  justify-content: inherit;
  text-overflow: ellipsis;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-label-3-columns-ranged ::ng-deep .product-label {
  max-width: 6.25rem;
}
:host .card-body .bottom-body-content .card-body-left-column .brand-and-label .print-card-label-3-columns-ranged ::ng-deep .flag-body {
  max-width: 6.25rem;
}
:host .card-body .bottom-body-content .card-body-left-column .badge-container {
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.25rem;
}
:host .card-body .bottom-body-content .card-body-left-column .badge-container .badge {
  height: 2rem;
}
:host .card-body .bottom-body-content .card-body-left-column .pricing-container {
  height: fit-content;
  align-self: end;
}
:host .card-body .bottom-body-content .card-body-left-column .pricing-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1;
  overflow: visible;
  width: unset;
}
:host .card-body .bottom-body-content .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-current-price,
:host .card-body .bottom-body-content .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  line-height: 0.7;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.1rem;
}
:host .card-body .bottom-body-content .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 1.5rem;
  color: #a3a3a3;
  text-decoration: unset;
  align-self: end;
}
:host .card-body .bottom-body-content .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price .product-variant-price-decoration {
  font-size: 0.75rem;
}
:host .card-body .bottom-body-content .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 2.625rem;
  padding-right: 0.625rem;
}
:host .card-body .bottom-body-content .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: 1.3125rem;
}
:host .card-body .bottom-body-content .card-body-right-column {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-end;
}
:host .card-body .bottom-body-content .card-body-right-column .badge-and-label-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
:host .card-body .bottom-body-content .card-body-right-column .badge-and-label-container .badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  margin-bottom: 0.625rem;
  justify-content: center;
  align-items: center;
}
:host .card-body .bottom-body-content .card-body-right-column .badge-and-label-container .badge-container .badge {
  height: 2rem;
}
:host .card-body .bottom-body-content .card-body-right-column .badge-and-label-container .print-card-label {
  align-self: center;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .card-body .bottom-body-content .card-body-right-column .badge-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  display: block;
  font-size: 0.75rem;
  line-height: 140%;
  border-radius: 0.25rem;
  margin: 0.1rem 0 0.2rem 0;
  width: fit-content;
  max-width: 9rem;
  overflow: hidden;
}
:host .card-body .property-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5625rem 0.5rem;
  gap: 0.625rem;
  text-align: center;
}
:host .card-body .property-container .title {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  vertical-align: middle;
  text-align: center;
}
:host .card-body .property-container .value {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
}
:host .card-body .property-container.grid-mode .title {
  width: 2.5rem;
  font-size: 0.875rem;
}
:host .card-body .property-container.grid-mode .value {
  font-size: 0.75rem;
}
:host .card-body .property-container:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
:host .card-body .product-grid-header {
  display: flex;
  justify-content: flex-end;
  background-color: #000000;
  border-radius: 0.5rem 0.5rem 0 0;
}
:host .card-body .product-grid-column-header {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  text-align: center;
  color: #ffffff;
  padding: 0.375rem;
  gap: 0.625rem;
}
:host ::ng-deep .grid-sale-value .product-variant-previous-price {
  display: none;
}
:host ::ng-deep .grid-regular-value .product-variant-current-price.and-has-previous-price {
  display: none;
}
/*# sourceMappingURL=doug-index-card-stack-content.component.css.map */
