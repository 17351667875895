import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { AgentOrangePostCardStackContentViewModel } from './agent-orange-post-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';

@Component({
  selector: 'app-agent-orange-post-card-stack-content',
  templateUrl: './agent-orange-post-card-stack-content.component.html',
  styleUrls: ['./agent-orange-post-card-stack-content.component.scss'],
  providers: [AgentOrangePostCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentOrangePostCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public viewModel: AgentOrangePostCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
