import { ShelfTalkerCard } from '../shelf-talker-card';
import { PaperSize } from '../../../../shared/paper-size';
import { Orientation } from '../../../../enum/dto/orientation.enum';

export abstract class FiveBySevenShelfTalker extends ShelfTalkerCard {

  getCardHeightInInches(): number {
    return 5;
  }

  getCardWidthInInches(): number {
    return 7;
  }

  nCardsPerPage(paperSize: PaperSize, isOnPerforatedPaper: boolean): number {
    return 2;
  }

  nColumns(): number {
    return 1;
  }

  nRows(): number {
    return 2;
  }

  cardOrientationOnPortraitPage(): Orientation {
    return Orientation.Landscape;
  }

}
