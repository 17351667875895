import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RenderCardsComponent } from './components/render-cards/render-cards.component';
import { RenderCardPreviewComponent } from './components/render-card-preview/render-card-preview.component';
import { RenderPrintCardLiveViewComponent } from './components/render-print-card-live-view/render-print-card-live-view.component';

const routes: Routes = [
  {
    path: '',
    component: RenderCardsComponent,
  },
  {
    path: ':locationId/:printCardMenuId',
    component: RenderCardsComponent,
    data: {
      title: 'Print Cards'
    }
  },
  {
    path: 'live-view',
    component: RenderPrintCardLiveViewComponent,
    data: {
      title: 'Live View of Print Card'
    }
  },
  {
    path: 'preview/:locationId/:printCardMenuId',
    component: RenderCardPreviewComponent,
    data: {
      title: 'Preview Print Cards'
    }
  },
  {
    path: 'template',
    children: [
      {
        path: ':locationId/:printCardMenuTemplateId',
        component: RenderCardsComponent,
        data: {
          title: 'Template for Print Cards'
        }
      },
      {
        path: 'preview/:locationId/:printCardMenuTemplateId',
        component: RenderCardPreviewComponent,
        data: {
          title: 'Preview Print Card Template'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrintCardsRoutingModule {
}
