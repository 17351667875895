@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/post-cards-4x6/doug-post-card-stack-content/doug-post-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 1rem;
  box-sizing: border-box;
}
:host .card-header {
  position: relative;
  height: 10.9%;
  width: 100%;
  display: block;
  color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
}
:host .card-header .header-content.left-content {
  position: absolute;
  left: 1.125rem;
  top: 0;
  bottom: 0;
}
:host .card-header .header-content.right-content {
  position: absolute;
  right: 1.125rem;
  top: 0;
  bottom: 0;
}
:host .card-header .header-content .strain-container {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  vertical-align: middle;
}
:host .card-header .header-content .product-size-and-type-container {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  vertical-align: middle;
}
:host .product-id-info {
  padding: 0.75rem 0 0.75rem 1rem;
}
:host .product-id-info .product-card-name {
  line-height: 1.3;
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 2.375rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:host .product-id-info .product-card-brand {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .product-id-info ::ng-deep .description-container .description {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  overflow-wrap: break-word;
  flex: 1;
}
:host .product-id-info ::ng-deep .description-container .plain-text {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
:host .product-id-info ::ng-deep .description-container .rich-text {
  max-height: 5.5rem;
  overflow-y: hidden;
}
:host .card-body {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 1rem;
}
:host .card-body .card-body-left-column {
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 1rem;
}
:host .card-body .card-body-left-column .badge-and-label-container {
  display: flex;
  flex-direction: column-reverse;
}
:host .card-body .card-body-left-column .badge-and-label-container .badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  margin-top: 0.875rem;
}
:host .card-body .card-body-left-column .badge-and-label-container .badge-container .badge {
  height: 2rem;
}
:host .card-body .card-body-left-column .badge-and-label-container .print-card-label {
  align-self: start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .card-body .card-body-left-column .badge-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  display: block;
  font-size: 0.875rem;
  margin-left: 0;
  width: fit-content;
  max-width: 10rem;
}
:host .card-body .card-body-left-column .badge-and-label-container .print-card-label ::ng-deep .flag-body {
  display: block;
  max-width: 10rem;
  justify-content: inherit;
  text-overflow: ellipsis;
}
:host .card-body .card-body-left-column .badge-and-label-container .print-card-label-3-columns {
  align-self: start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .card-body .card-body-left-column .badge-and-label-container .print-card-label-3-columns ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  display: block;
  font-size: 0.875rem;
  width: fit-content;
  max-width: 10rem;
  text-overflow: ellipsis;
  justify-content: inherit;
}
:host .card-body .card-body-left-column .badge-and-label-container .print-card-label-3-columns ::ng-deep .flag-body {
  display: block;
  max-width: 10rem;
  justify-content: inherit;
  text-overflow: ellipsis;
}
:host .card-body .card-body-left-column .badge-and-label-container .default-label ::ng-deep .product-label {
  overflow: hidden;
}
:host .card-body .card-body-left-column .badge-and-label-container .flag-label ::ng-deep .flag-body {
  overflow: hidden;
}
:host .card-body .card-body-left-column .badge-and-label-container .print-card-label-3-columns-ranged ::ng-deep .product-label {
  max-width: 7.75rem;
}
:host .card-body .card-body-left-column .badge-and-label-container .print-card-label-3-columns-ranged ::ng-deep .flag-body {
  max-width: 7.75rem;
}
:host .card-body .card-body-left-column .pricing-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
  overflow: visible;
  width: unset;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-current-price,
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.1rem;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 1.75rem;
  color: #a3a3a3;
  text-decoration: unset;
  margin-top: 0.25rem;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price .product-variant-price-decoration {
  font-size: 0.875rem;
  line-height: 20%;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 2.75rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: 1.375rem;
  line-height: 10%;
}
:host .card-body .card-body-right-column {
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.75rem 1rem 0.75rem 0;
}
:host .card-body .card-body-right-column .product-info-table-container {
  align-self: end;
}
:host .card-body .card-body-right-column .badge-and-label-container {
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
:host .card-body .card-body-right-column .badge-and-label-container .badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  margin-bottom: 0.875rem;
  justify-content: center;
  align-items: center;
}
:host .card-body .card-body-right-column .badge-and-label-container .badge-container .badge {
  height: 2rem;
}
:host .card-body .card-body-right-column .badge-and-label-container .print-card-label {
  align-self: center;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .card-body .card-body-right-column .badge-and-label-container .print-card-label.line-item-mode {
  justify-self: center;
}
:host .card-body .card-body-right-column .badge-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  display: block;
  font-size: 0.875rem;
  line-height: 140%;
  border-radius: 0.25rem;
  margin: 0.1rem 0 0.2rem 0;
  width: fit-content;
  max-width: 12rem;
  overflow: hidden;
}
:host .card-body .card-body-right-column .badge-and-label-container .print-card-label ::ng-deep .flag-body {
  display: block;
  max-width: 12rem;
  overflow: hidden;
  justify-content: inherit;
}
:host .card-body .card-body-right-column .badge-and-label-container .default-label ::ng-deep .product-label {
  overflow: hidden;
}
:host .card-body .card-body-right-column .badge-and-label-container .flag-label ::ng-deep .flag-body {
  overflow: hidden;
}
:host .card-body .card-body-right-column .property-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.625rem 0.5rem;
  gap: 0.625rem;
  text-align: center;
}
:host .card-body .card-body-right-column .property-container.line-item-mode {
  display: grid;
  grid-template-columns: auto auto;
}
:host .card-body .card-body-right-column .property-container .title {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  vertical-align: middle;
  text-align: center;
  width: 3rem;
}
:host .card-body .card-body-right-column .property-container .value {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
}
:host .card-body .card-body-right-column .property-container:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
:host .card-body .card-body-right-column .product-grid-header {
  display: flex;
  justify-content: flex-end;
  background-color: #000000;
  border-radius: 0.5rem 0.5rem 0 0;
}
:host .card-body .card-body-right-column .product-grid-column-header {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.8125rem;
  text-align: center;
  width: 3rem;
  color: #ffffff;
  padding: 0.625rem 0.375rem;
  gap: 0.625rem;
}
:host ::ng-deep .grid-sale-value .product-variant-previous-price {
  display: none;
}
:host ::ng-deep .grid-regular-value .product-variant-current-price.and-has-previous-price {
  display: none;
}
/*# sourceMappingURL=doug-post-card-stack-content.component.css.map */
