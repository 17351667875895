import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';
import { takeUntil } from 'rxjs/operators';
import { FikaEdiblesIndexCardStackContentViewModel } from './fika-edibles-index-card-stack-content-view-model';
import { FikaIndexCardStackContentViewModel } from '../fika-index-card-stack-content/fika-index-card-stack-content-view-model';
import { FikaEdiblesCardData } from '../../../../../../../models/print-cards/fika-edibles-card-data';

@Component({
  selector: 'app-fika-edibles-index-card-stack-content',
  templateUrl: './fika-edibles-index-card-stack-content.component.html',
  styleUrls: ['./fika-edibles-index-card-stack-content.component.scss'],
  providers: [
    FikaEdiblesIndexCardStackContentViewModel,
    { provide: FikaIndexCardStackContentViewModel, useExisting: FikaEdiblesIndexCardStackContentViewModel }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FikaEdiblesIndexCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: FikaEdiblesIndexCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @Input() override data: FikaEdiblesCardData;

  override setupBindings() {
    super.setupBindings();
    this.listenToVirtualLabelText();
  }

  private listenToVirtualLabelText(): void {
    this.labelCalculator?.virtualLabelText$
      ?.pipe(takeUntil(this.onDestroy))
      ?.subscribe(labelText => this.viewModel.connectToVirtualLabelText(labelText));
  }

}
