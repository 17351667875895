import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { PrintCardDomainModel } from '../../../../domain/print-card-domain-model';

@Injectable()
export class CardGridViewModel extends BaseViewModel {

  constructor(
    protected printCardDomainModel: PrintCardDomainModel
  ) {
    super();
  }

  public readonly layoutInstructions$ = this.printCardDomainModel.layoutInstructions$;

}
