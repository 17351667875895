import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { FikaIndexCardStack } from './fika-index-card-stack';
import { FikaEdiblesIndexCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/index-cards-3x5/fika-edibles-index-card-stack-background/fika-edibles-index-card-stack-background.component';
import { FikaEdiblesIndexCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/index-cards-3x5/fika-edibles-index-card-stack-content/fika-edibles-index-card-stack-content.component';

export class FikaEdiblesIndexCardStack extends FikaIndexCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> {
    return FikaEdiblesIndexCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> {
    return FikaEdiblesIndexCardStackContentComponent;
  }

}
