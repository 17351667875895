import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { map } from 'rxjs/operators';
import { PlainJaneCardData } from '../../../../../../../models/print-cards/plain-jane-card-data';
import { Observable } from 'rxjs';

@Injectable()
export class PlainJaneBusinessCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<PlainJaneCardData>;

  public showSize$ = this.cardData$.pipe(
    map(cardData => cardData?.showSize())
  );

  public showQuantity$ = this.cardData$.pipe(
    map(cardData => cardData?.showQuantity())
  );

}
