import { SectionColumnConfigDataValue } from '../section-column-config';
import { CardsThatLayoutOntoPaper } from '../cards-that-layout-onto-paper';
import { Type } from '@angular/core';
import type { CardData } from '../../../print-cards/card-data';
import type { PrintCardContentComponent } from '../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import type { PrintCardSize } from '../../../enum/dto/print-card-size.enum';

export abstract class CardStack extends CardsThatLayoutOntoPaper {

  cardSize: PrintCardSize;
  public templateSection?: CardStack;

  abstract override getCardContentComponent(data: CardData): Type<PrintCardContentComponent> | null;

  onDeserialize() {
    super.onDeserialize();
  }

  public priceType(): SectionColumnConfigDataValue {
    return SectionColumnConfigDataValue.OriginalAndSalePrice;
  }

}
