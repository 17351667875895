import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { ShelfTalkerCardContentComponent } from '../../shared/shelf-talker-card-content.component';
import { FireAndFlower5By7ShelfTalkerContentViewModel } from './fire-and-flower-5-by-7-shelf-talker-content-view-model';

@Component({
  selector: 'app-fire-and-flower5-by7-shelf-talker-content',
  templateUrl: './fire-and-flower-5-by-7-shelf-talker-content.component.html',
  styleUrl: './fire-and-flower-5-by-7-shelf-talker-content.component.scss',
  providers: [FireAndFlower5By7ShelfTalkerContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireAndFlower5By7ShelfTalkerContentComponent extends ShelfTalkerCardContentComponent {

  constructor(
    public viewModel: FireAndFlower5By7ShelfTalkerContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
