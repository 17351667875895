import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { StiiizyCardData } from '../../../../../../../models/print-cards/stiiizy-card-data';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { iiif } from '../../../../../../../utils/observable.extensions';
import { ProductType } from '../../../../../../../models/enum/dto/product-type.enum';
import { exists } from '../../../../../../../functions/exists';
import { VariantType } from '../../../../../../../models/enum/dto/variant-type.enum';

@Injectable()
export class StiiizyCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<StiiizyCardData>;

  private readonly _cannabinoidsHeight = new BehaviorSubject<number>(0);
  public readonly cannabinoidsHeight$ = this._cannabinoidsHeight.pipe(distinctUntilChanged());
  connectToCannabinoidsHeight(height: number): void { this._cannabinoidsHeight.next(height); }

  public readonly hasStiiizySize$ = this.cardData$.pipe(
    map(cardData => exists(cardData?.getGridQuantityAndSizeString())),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cannabinoidsWrapped$ = this.cannabinoidsHeight$.pipe(
    map(height => height > 20),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly shrinkBrandAsset$ = combineLatest([
    combineLatest([this.hasStiiizySize$, this.hasLabel$]).pipe(map(([hasSize, hasLabel]) => hasSize || hasLabel)),
    this.cannabinoidsWrapped$
  ]).pipe(
    map(([hasBottomLine, cannabinoidsWrapped]) => hasBottomLine && cannabinoidsWrapped),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly shrinkCardTitle$ = this.shrinkBrandAsset$;

  public readonly isStiiizyBrandedCard$ = this.cardData$.pipe(
    map(cardData => cardData?.isStiiizyBrandedCard()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly stiiizyCardBackgroundColor$ = iiif(
    this.isStiiizyBrandedCard$,
    of('black'),
    this.cardColor$
  ).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly sidebarAndContentGapInRem$ = this.isStiiizyBrandedCard$.pipe(
    map(isStiiizyBrandedCard => isStiiizyBrandedCard ? 0.125 : 0),
  );

  public readonly isFlavorCardOrMultiStainCard$ = this.cardData$.pipe(
    map(cardData => cardData?.isFlavorCardOrMultiStrainCard()),
  );

  public readonly hasFlavors$ = this.cardData$.pipe(
    map(cardData => cardData?.numberOfFlavorsOrStrains() > 1)
  );

  public readonly showSidebar$ = combineLatest([this.isFlavorCardOrMultiStainCard$, this.hasFlavors$]).pipe(
    map(([isFlavorCard, hasFlavors]) => isFlavorCard && hasFlavors),
  );

  public readonly showCannabinoids$ = this.cardData$.pipe(
    map(cardData => {
      const productType = cardData?.rowProductType();
      const validProduct = productType === ProductType.Beverage
        || productType === ProductType.Edible
        || productType === ProductType.Oil
        || productType === ProductType.Wellness
        || cardData?.rowVariantType() === VariantType.Capsule;
      return validProduct && exists(cardData?.getCannabinoid('THC') || cardData?.getCannabinoid('CBD'));
    })
  );

}
