import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PlantlifeCardData } from '../../../../../../../models/print-cards/plantlife-card-data';
import { map } from 'rxjs/operators';
import { ColorUtils } from '../../../../../../../utils/color-utils';
import { exists } from '../../../../../../../functions/exists';
import { StrainType } from '../../../../../../../models/enum/dto/strain-classification.enum';

@Injectable()
export class PlantlifeBusinessCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<PlantlifeCardData>;

  public readonly topOfCardBackgroundColor$ = this.cardAccentColor$.pipe(
    map(accentColor => accentColor || '#000000')
  );

  public readonly topOfCardTextColor$ = this.topOfCardBackgroundColor$.pipe(
    map(accentColor => ColorUtils.isDarkColor(accentColor) ? '#ffffff' : '#000000')
  );

  private readonly backgroundColor$ = this.cardData$.pipe(
    map(cardData => {
      return exists(cardData?.menu?.hydratedTheme?.themeFeatures?.sectionBodyBackgroundColor)
        ? cardData?.section?.metadata?.productsContainerBackgroundColor
        : null;
    })
  );

  private readonly backgroundImage$ = this.cardData$.pipe(
    map(cardData => {
      return exists(cardData?.menu?.backgroundImage)
        ? cardData?.menu?.backgroundImage
        : null;
    })
  );

  public readonly hasBackgroundColorOrImage$ = combineLatest([
    this.backgroundColor$,
    this.backgroundImage$
  ]).pipe(
    map(([backgroundColor, backgroundImage]) => exists(backgroundColor || backgroundImage))
  );

  public readonly tableHeaderBackgroundColor$ = this.cardData$.pipe(
    map(cardData => {
      const type = cardData?.getStrainType();
      switch (type) {
        case StrainType.Indica:
        case StrainType.IndicaDominant: return '#6957C2';
        case StrainType.Sativa:
        case StrainType.SativaDominant: return '#CA622E';
        case StrainType.Hybrid:         return '#147747';
        case StrainType.Blend:          return '#057A9F';
        case StrainType.CBD:            return '#AC1F49';
        default:                        return '#000000';
      }
    })
  );

}
