import { ShelfTalkerMenu } from '../models/menu/shelf-talkers/shelf-talker-menu';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { LocationConfiguration } from '../models/company/dto/location-configuration';
import { ShelfTalkerCardData } from '../models/shelf-talkers/shelf-talker-card-data';
import { FireAndFlowerShelfTalkerMenu } from '../models/menu/shelf-talkers/fire-and-flower-shelf-talker-menu';
import { FireAndFlowerShelfTalkerCardData } from '../models/shelf-talkers/fire-and-flower-shelf-talker-card-data';
import { Variant } from '../models/product/dto/variant';

export class ShelfTalkerUtils {

  static generateShelfTalkerCardData(
    menu: ShelfTalkerMenu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    forcedSectionIdPool?: string[]
  ): ShelfTalkerCardData[] {
    const whichCards = !forcedSectionIdPool?.length
      ? menu?.getShelfTalkerCards()
      : menu?.getShelfTalkerCards()?.filter(card => forcedSectionIdPool?.includes(card?.id));
    const returnedShelfTalkerCardData: ShelfTalkerCardData[] = [];
    whichCards?.forEach(shelfTalkerCard => {
      const shelfTalkerCardData = ShelfTalkerUtils.getTypedShelfTalkerCardData(menu);
      shelfTalkerCardData.menu = menu;
      shelfTalkerCardData.section = shelfTalkerCard;
      shelfTalkerCardData.products = shelfTalkerCard?.products;
      shelfTalkerCardData.variants = shelfTalkerCard?.products?.map(p => p.variants)?.flatten<Variant[]>();
      shelfTalkerCardData.hideLabel = menu?.menuOptions?.hideLabel || false;
      shelfTalkerCardData.companyConfig = companyConfig;
      shelfTalkerCardData.locationConfig = locationConfig;
      shelfTalkerCardData.variantBadgeMap = shelfTalkerCard?.variantBadgeMap;
      if (shelfTalkerCard?.variants?.length > 0) {
        returnedShelfTalkerCardData.push(shelfTalkerCardData);
      }
    });
    return returnedShelfTalkerCardData;
  }

  static getTypedShelfTalkerCardData(menu: ShelfTalkerMenu): ShelfTalkerCardData {
    switch (true) {
      case menu instanceof FireAndFlowerShelfTalkerMenu:
        return new FireAndFlowerShelfTalkerCardData();
      default:
        return new ShelfTalkerCardData();
    }
  }

}
