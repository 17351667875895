import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { CardBackgroundComponent } from '../../card-background.component';
import { FikaEdiblesIndexCardStackBackgroundViewModel } from './fika-edibles-index-card-stack-background-view-model';
import { CardData } from '../../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-fika-edibles-index-card-stack-background',
  templateUrl: './fika-edibles-index-card-stack-background.component.html',
  styleUrls: ['./fika-edibles-index-card-stack-background.component.scss'],
  providers: [FikaEdiblesIndexCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FikaEdiblesIndexCardStackBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public viewModel: FikaEdiblesIndexCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @Input() override cardBackground: CardData;

}
