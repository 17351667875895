/* src/app/modules/print-cards/components/cards/card-content/shared/components/print-card-description-container/print-card-description-container.component.scss */
.description-container p,
.description-container ul,
.description-container ol {
  margin: 0;
}
.description-container ul,
.description-container ol {
  padding-left: 1.4rem;
}
.description-container .ql-align-center {
  text-align: center;
}
.description-container .ql-align-right {
  text-align: right;
}
.description-container .ql-align-justify {
  text-align: justify;
}
/*# sourceMappingURL=print-card-description-container.component.css.map */
