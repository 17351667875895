<app-label-inflator
  #labelCalculator
  hidden
  class="label-calculator label"
  [columnLabel]="false"
  [labelComponentInterface]="data"
  [reset]="false">
</app-label-inflator>

<div class="card-header" [style.margin-top.rem]="(viewModel.isNonCannabinoidVariant$ | async) ? 0.125 : 0">
  <div
    *ngIf="data | sectionRowViewModelBasicMethod: 'getReadableStrainType' as strainType"
    class="header-content left-content">
    <div *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)" class="strain-container">{{ strainType }}</div>
  </div>
  <div class="header-content right-content">
    <div class="product-size-and-type-container">
      <span
        *ngIf="
          viewModel.isLineItemMode$
            | cardHeaderUnitSize: viewModel.cardData$ : viewModel.spoofedGridColViewModels$ : true
            | async as unitSize
        ">
        {{ unitSize | trimSafely }}
      </span>
      {{ data | sectionRowViewModelBasicMethod: 'rowVariantType' | trimSafely }}
    </div>
  </div>
</div>
<div *ngIf="!(viewModel.isLineItemMode$ | async)" [style.height]="'5.375rem'">
  <div *ngIf="!(viewModel.isLineItemMode$ | async)" class="product-id-info">
    <div
      *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
      class="product-card-name"
      appScaleDownText
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
      [style.color]="viewModel.headerTextColor$ | async">
      {{ title | trimSafely }}
    </div>
  </div>
</div>
<div class="card-body" [style.height]="viewModel.cardBodyHeight$ | async">
  <div
    class="card-body-left-column"
    [style.width.rem]="viewModel.leftSideLineItemWidthInRem$ | async"
    [style.position]="(viewModel.isLineItemMode$ | async) ? 'absolute' : 'relative'"
    [style.margin-top.rem]="!(viewModel.isLineItemMode$ | async) ? 1.5 : 0.75">
    <div class="product-id-info">
      <div
        *ngIf="(viewModel.isLineItemMode$ | async) && data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
        class="product-card-name"
        appScaleDownText
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
        [style.color]="viewModel.headerTextColor$ | async">
        {{ title | trimSafely }}
      </div>
      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getBrand' as brand"
        class="product-card-brand"
        [style.color]="viewModel.bodyTextColor$ | async">
        {{ brand | trimSafely | uppercase }}
      </div>
      <div *ngIf="!(viewModel.isLineItemMode$ | async)" class="badge-and-label-container">
        <div
          *ngIf="(data?.menu | printCardMenuBasicMethod: 'getNumberOfBadgesForVariant' : data) > 0"
          class="badge-container">
          <img
            *ngFor="let variantBadge of data | printCardBadges"
            appImage
            class="badge"
            [asset]="variantBadge?.image"
            alt="badge"
            src="" />
        </div>
        <app-label-inflator
          class="print-card-label"
          [class.print-card-label-3-columns]="viewModel.gridHas3Columns$ | async"
          [class.print-card-label-3-columns-ranged]="
            (viewModel.gridHas3Columns$ | async) && data.displayCannabinoidInRanges()
          "
          [class.default-label]="!(viewModel.labelIsFlagStyle$ | async)"
          [class.flag-label]="viewModel.labelIsFlagStyle$ | async"
          [columnLabel]="false"
          [labelComponentInterface]="data"
          [reset]="false">
        </app-label-inflator>
      </div>
      <app-print-card-description-container
        [cardData]="data"
        [bodyTextColor]="viewModel.bodyTextColor$ | async"></app-print-card-description-container>
    </div>
    <div
      *ngIf="viewModel.isLineItemMode$ | async"
      class="pricing-container"
      [style.color]="viewModel.bodyTextColor$ | async">
      <app-column-primary-price
        [menu]="viewModel.cardStackMenu$ | async"
        [section]="viewModel.cardStack$ | async"
        [colVm]="viewModel.spoofedColViewModel$ | async"
        [rowVm]="viewModel.cardData$ | async"
        [locConfig]="viewModel.locationConfig$ | async"
        [locationId]="viewModel.locationId$ | async"
        [productStylingVm]="viewModel">
      </app-column-primary-price>
    </div>
  </div>

  <div
    class="card-body-right-column"
    [style.width.rem]="viewModel.rightSideLineItemWidthInRem$ | async"
    [style.position]="(viewModel.isLineItemMode$ | async) ? 'absolute' : 'relative'"
    [style.height.rem]="(viewModel.isLineItemMode$ | async) ? 19.375 : 15"
    [style.max-height.rem]="(viewModel.isLineItemMode$ | async) ? 19.375 : 15"
    [style.justify-content]="!(viewModel.isLineItemMode$ | async) ? 'flex-end' : 'center'">
    <div
      *ngIf="(viewModel.isLineItemMode$ | async) && (viewModel.badgeOrLabelVisible$ | async)"
      class="badge-and-label-container">
      <div *ngIf="viewModel.hasBadges$ | async" class="badge-container">
        <img
          *ngFor="let variantBadge of data | printCardBadges"
          appImage
          class="badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>
      <app-label-inflator
        *ngIf="viewModel.hasLabel$ | async"
        class="print-card-label"
        [class.line-item-mode]="viewModel.isLineItemMode$ | async"
        [class.default-label]="!(viewModel.labelIsFlagStyle$ | async)"
        [class.flag-label]="viewModel.labelIsFlagStyle$ | async"
        [columnLabel]="false"
        [labelComponentInterface]="data"
        [reset]="false">
      </app-label-inflator>
    </div>

    <div class="product-info-table-container">
      <ng-container *ngIf="viewModel.isLineItemMode$ | async; else gridModeTable">
        <div
          *ngIf="
            !(viewModel.isNonCannabinoidVariant$ | async) && data | sectionRowViewModelBasicMethod: 'getThc' as thc
          "
          class="property-container line-item-mode"
          [style.color]="viewModel.bodyTextColor$ | async">
          <div class="title">THC</div>
          <div
            class="value"
            [style.width.rem]="(data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 6 : 3"
            appScaleDownText
            [scaleDownLinkedKey]="viewModel.variantId$ | async"
            [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
            {{ thc }}
          </div>
        </div>
        <div
          *ngIf="
            !(viewModel.isNonCannabinoidVariant$ | async) && data | sectionRowViewModelBasicMethod: 'getCbd' as cbd
          "
          class="property-container line-item-mode"
          [style.color]="viewModel.bodyTextColor$ | async">
          <div class="title">CBD</div>
          <div
            class="value"
            [style.width.rem]="(data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 6 : 3"
            appScaleDownText
            [scaleDownLinkedKey]="viewModel.variantId$ | async"
            [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
            {{ cbd }}
          </div>
        </div>
        <div
          *ngIf="
            !(viewModel.isNonCannabinoidVariant$ | async) && data
              | sectionRowViewModelBasicMethod: 'getListItemQty' as qty
          "
          class="property-container line-item-mode"
          [style.color]="viewModel.bodyTextColor$ | async">
          <div class="title">QTY</div>
          <div
            appScaleDownText
            class="value"
            [style.width.rem]="(data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 6 : 3">
            {{ qty }}
          </div>
        </div>
      </ng-container>

      <ng-template #gridModeTable>
        <div class="product-grid-header" [style.background-color]="viewModel.cardAccentColor$ | async">
          <ng-container *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
            <div
              class="product-grid-column-header"
              [style.width.rem]="
                (data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges')
                  ? gridRangeColWidthInRem
                  : gridColWidthInRem
              ">
              {{ viewModel.getGridSize$(spoofedGridColVM) | async }}
            </div>
          </ng-container>
        </div>
        <div
          *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)"
          class="property-container"
          [style.color]="viewModel.bodyTextColor$ | async"
          [style.padding]="(viewModel.atLeastOneGridVariantOnSale$ | async) ? '0.425rem 0' : '0.625rem 0.5rem'">
          <div class="title">THC</div>
          <div *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
            <div
              class="value"
              [style.width.rem]="
                (data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges')
                  ? gridRangeColWidthInRem
                  : gridColWidthInRem
              "
              appScaleDownText
              [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
              [scaleDownLinkedKey]="(viewModel.getGridVariantId$(spoofedGridColVM) | async) + 'cannabinoids'">
              {{ spoofedGridColVM | getGridVariantThc: viewModel.cardStackMenu$ : viewModel.cardData$ | async }}
            </div>
          </div>
        </div>
        <div
          *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)"
          class="property-container"
          [style.color]="viewModel.bodyTextColor$ | async"
          [style.padding]="(viewModel.atLeastOneGridVariantOnSale$ | async) ? '0.425rem 0' : '0.625rem 0.5rem'">
          <div class="title">CBD</div>
          <div *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
            <div
              class="value"
              [style.width.rem]="
                (data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges')
                  ? gridRangeColWidthInRem
                  : gridColWidthInRem
              "
              appScaleDownText
              [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
              [scaleDownLinkedKey]="(viewModel.getGridVariantId$(spoofedGridColVM) | async) + 'cannabinoids'">
              {{ spoofedGridColVM | getGridVariantCbd: viewModel.cardStackMenu$ : viewModel.cardData$ | async }}
            </div>
          </div>
        </div>
        <div
          class="property-container"
          [style.color]="viewModel.bodyTextColor$ | async"
          [style.padding]="(viewModel.atLeastOneGridVariantOnSale$ | async) ? '0.425rem 0' : '0.625rem 0.5rem'">
          <div class="title">$</div>
          <div *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
            <div
              class="value grid-regular-value"
              [style.width.rem]="
                (data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges')
                  ? gridRangeColWidthInRem
                  : gridColWidthInRem
              ">
              <app-column-primary-price
                [menu]="viewModel.cardStackMenu$ | async"
                [section]="viewModel.cardStack$ | async"
                [colVm]="spoofedGridColVM"
                [rowVm]="viewModel.cardData$ | async"
                [locConfig]="viewModel.locationConfig$ | async"
                [locationId]="viewModel.locationId$ | async"
                [productStylingVm]="viewModel">
              </app-column-primary-price>
            </div>
          </div>
        </div>
        <div
          *ngIf="viewModel.atLeastOneGridVariantOnSale$ | async"
          class="property-container"
          [style.color]="viewModel.bodyTextColor$ | async"
          [style.padding]="(viewModel.atLeastOneGridVariantOnSale$ | async) ? '0.425rem 0' : '0.625rem 0.5rem'">
          <div class="title">SALE</div>
          <div *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
            <div
              class="value grid-sale-value"
              [style.width.rem]="
                (data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges')
                  ? gridRangeColWidthInRem
                  : gridColWidthInRem
              ">
              <app-column-primary-price
                [menu]="viewModel.cardStackMenu$ | async"
                [section]="viewModel.cardStack$ | async"
                [colVm]="spoofedGridColVM"
                [rowVm]="viewModel.cardData$ | async"
                [locConfig]="viewModel.locationConfig$ | async"
                [locationId]="viewModel.locationId$ | async"
                [productStylingVm]="viewModel"
                [showNgContentContainerIfNoDiscount]="true">
                --
              </app-column-primary-price>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
