import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'getInternalCrossDimension'
})
export class GetInternalCrossDimensionPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper, crossDimension: string): string {
    const bleed = !cardsThatLayoutOntoPaper ? 0 : cardsThatLayoutOntoPaper.withContentBleed(false, 2);
    return `calc(${crossDimension} + ${bleed}px)`;
  }

}
