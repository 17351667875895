import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { Theme } from '../models/menu/dto/theme';
import { DisplayAttribute } from '../models/display/dto/display-attribute';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { Menu } from '../models/menu/menu';
import { Section } from '../models/menu/section/section';
import { Label } from '../models/menu/labels/label';
import { MarketingAsset } from '../models/image/dto/marketing-asset';

// eslint-disable-next-line rxjs/no-compat

@Injectable({ providedIn: 'root' })
export class MenuAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Menu';

  // Menu

  public GetMenuSection(menuId, sectionId: string): Observable<Section> {
    const url = Endpoints.GetMenuSection(menuId, sectionId);
    return this.apiClient.getObj<Section>(Section, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenuSection', err));
        return throwError(err);
      })
    );
  }

  public GetMenu(locationId: number, menuId: string): Observable<Menu> {
    const url = Endpoints.GetMenu(locationId, menuId);
    return this.apiClient.getObj<Menu>(Menu, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenu', err));
        return throwError(err);
      })
    );
  }

  public GetPartialMenu(locationId: number, menuId: string, variantIds: string[]): Observable<Menu> {
    const url = Endpoints.GetPartialMenu(locationId, menuId, variantIds);
    return this.apiClient.getObj<Menu>(Menu, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetPartialMenu', err));
        return throwError(err);
      })
    );
  }

  public GetPartialShelfTalkerMenu(locationId: number, menuId: string, sectionIds: string[]): Observable<Menu> {
    const url = Endpoints.GetPartialShelfTalkerMenu(locationId, menuId, sectionIds);
    return this.apiClient.getObj<Menu>(Menu, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetPartialShelfTalkerMenu', err));
        return throwError(err);
      })
    );
  }

  public GetMarketingMenuAssets(menu: Menu): Observable<MarketingAsset[]> {
    const url = Endpoints.GetMenuAssets(menu);
    return this.apiClient.getArr<MarketingAsset>(MarketingAsset, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMarketingMenuAssets', err));
        return throwError(err);
      })
    );
  }

  public GetMenuThemes(): Observable<Theme[]> {
    const url = Endpoints.GetMenuThemes();
    return this.apiClient.getArr<Theme>(Theme, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenuThemes', err));
        return throwError(err);
      })
    );
  }

  // Display Attributes

  public GetDisplayAttributes(): Observable<DisplayAttribute[]> {
    const url = Endpoints.GetDisplayAttributes();
    return this.apiClient.getArr<DisplayAttribute>(DisplayAttribute, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetDisplayAttributes', err));
        return throwError(err);
      })
    );
  }

  // Labels

  public GetLabels(locationId: number, companyId: number): Observable<Label[]> {
    const url = Endpoints.GetLabels(locationId);
    // noinspection SpellCheckingInspection
    const additionalHeaders = { companyid: `${companyId}` };
    return this.apiClient.getArr<Label>(Label, url, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLabels', err));
        return throwError(err);
      })
    );
  }

}
