/* src/app/modules/print-cards/components/cards/backgrounds/post-cards-4x6/plain-jane-post-card-stack-background/plain-jane-post-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  box-sizing: border-box;
  padding: 1rem;
}
:host .main-content {
  flex: 1;
}
:host .main-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=plain-jane-post-card-stack-background.component.css.map */
