import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'cardContentWidth'
})
export class CardContentWidthPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper): number {
    return cardsThatLayoutOntoPaper?.getCardWidthInPixels() || 0;
  }

}
