<app-label-inflator
  #labelCalculator
  hidden
  class="print-card-label"
  [columnLabel]="false"
  [labelComponentInterface]="data"
  [reset]="false">
</app-label-inflator>

<div class="main-content">
  <img
    *ngIf="data | getVariantAssetForCard | async as variantAsset"
    appImage
    class="product-card-image"
    [asset]="variantAsset"
    src=""
    alt="" />
  <div class="brand-name-badge-and-label-container">
    <div class="brand-name-badge-and-label">
      <div class="brand-name-and-badge">
        <div class="brand-and-name" [style.color]="viewModel.headerTextColor$ | async">
          <div *ngIf="data | sectionRowViewModelBasicMethod: 'getBrand' as brand" class="product-card-brand">
            {{ brand | trimSafely }}
          </div>
          <div *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title" class="product-card-name">
            {{ title | trimSafely }}
          </div>
        </div>
        <div
          *ngIf="(data?.menu | printCardMenuBasicMethod: 'getNumberOfBadgesForVariant' : data) > 0"
          class="product-card-badge-container">
          <img
            *ngFor="let variantBadge of data | printCardBadges"
            appImage
            class="product-card-badge"
            [asset]="variantBadge?.image"
            alt="badge"
            src="" />
        </div>
      </div>
      <app-label-inflator
        class="print-card-label"
        [columnLabel]="false"
        [labelComponentInterface]="data"
        [reset]="false">
      </app-label-inflator>
    </div>
  </div>
  <div class="desc-type-and-price">
    <app-print-card-description-container
      [cardData]="data"
      [bodyTextColor]="viewModel.bodyTextColor$ | async"></app-print-card-description-container>
    <div class="type-and-price">
      <div
        *ngIf="!(viewModel.isAccessory$ | async) && !(viewModel.isNonCannabinoidOtherVariant$ | async)"
        class="product-card-type"
        [style.color]="viewModel.bodyTextColor$ | async">
        {{ data | sectionRowViewModelBasicMethod: 'getSize' | trimSafely }}
        {{ data | sectionRowViewModelBasicMethod: 'rowVariantType' | trimSafely }}
      </div>
      <div class="product-card-price" [style.color]="viewModel.bodyTextColor$ | async">
        <app-column-primary-price
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="viewModel.spoofedColViewModel$ | async"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel">
        </app-column-primary-price>
      </div>
    </div>
  </div>
</div>
<div class="color-strip" [style.color]="viewModel.bodyTextColor$ | async">
  <div *ngIf="data | sectionRowViewModelBasicMethod: 'rowVariantType' as variantType" class="property-container">
    <div class="title">Type</div>
    <div class="value">{{ variantType }}</div>
  </div>
  <div
    *ngIf="
      !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
      (data | sectionRowViewModelBasicMethod: 'getReadableStrainType') as strainType
    "
    class="property-container">
    <div class="title">Strain</div>
    <div class="value">{{ strainType }}</div>
  </div>
  <div
    *ngIf="
      !(viewModel.isNonCannabinoidOtherVariant$ | async) && (data | sectionRowViewModelBasicMethod: 'getThc') as thc
    "
    class="property-container">
    <div class="title">THC</div>
    <div class="value">{{ thc }}</div>
  </div>
  <div
    *ngIf="
      !(viewModel.isNonCannabinoidOtherVariant$ | async) && (data | sectionRowViewModelBasicMethod: 'getCbd') as cbd
    "
    class="property-container">
    <div class="title">CBD</div>
    <div class="value">{{ cbd }}</div>
  </div>
</div>
