import { PaperSize } from '../../shared/paper-size';
import { Section } from '../section/section';
import { ShelfTalkerCard } from '../section/shelf-talker-card/shelf-talker-card';
import { SectionType } from '../../enum/dto/section-type.enum';
import { LaysOutCardsOnToPaperMenu } from '../print-card/lays-out-cards-on-to-paper-menu';
import type { CardsThatLayoutOntoPaper } from '../section/cards-that-layout-onto-paper';

/**
 * Each section represents a card on shelf talker menus.
 */
export abstract class ShelfTalkerMenu extends LaysOutCardsOnToPaperMenu {

  public override template?: ShelfTalkerMenu;
  public override sections: ShelfTalkerCard[];
  public override templateSections: ShelfTalkerCard[];

  onDeserialize() {
    super.onDeserialize();
  }

  protected forceSectionType(section: CardsThatLayoutOntoPaper): void {
    section.sectionType = SectionType.ShelfTalkerCard;
  }

  protected override deserializeDisplaySize(): void {
    this.displaySize = window?.injector?.Deserialize?.instanceOf(PaperSize, this.displaySize);
  }

  protected override deserializeSections(): void {
    this.sections?.forEach(section => this.setCardInfoDataOnSection(section));
    const sections = window?.injector?.Deserialize?.arrayOf(Section, this.sections) ?? [];
    this.sections = sections?.filter((s: Section): s is ShelfTalkerCard => s instanceof ShelfTalkerCard);
  }

  protected override deserializeTemplateSections(): void {
    this.templateSections?.forEach(templateSection => this.setCardInfoDataOnSection(templateSection));
    const templateSections = window?.injector?.Deserialize?.arrayOf(Section, this.templateSections) ?? [];
    this.templateSections = templateSections?.filter((s): s is ShelfTalkerCard => s instanceof ShelfTalkerCard);
  }

  getLayoutInstructions(): ShelfTalkerCard {
    return this.sections?.firstOrNull();
  }

  getShelfTalkerCards(): ShelfTalkerCard[] | null {
    return this.sections;
  }

  /**
   * @param cards to be printed. They are already sorted by the criteria attached to the card stack object.
   * @param cardsPerPage is the number of cards should be printed on a single page. It can be 1 if singleCardPerPage
   * is true.
   * @param onPerforatedPaper
   * @returns a list of CardData[]. Each list of CardData represents the cards that will be printed on a single page.
   */
  cardGroupingsForEachPage<T>(cards: T[], cardsPerPage: number, onPerforatedPaper: boolean): T[][] {
    return cards?.chunkedList(cardsPerPage) ?? [];
  }

  /** Columns start at 1. */
  protected getCurrentColumn(nthCardOnPage: number, nColumns: number): number {
    return ((nthCardOnPage - 1) % nColumns) + 1;
  }

  /** Rows start at 1. */
  protected getCurrentRow(nthCardOnPage: number, nColumns: number): number {
    return Math.floor((nthCardOnPage - 1) / nColumns) + 1;
  }

}
