<div class="left-cut-lines" [class.extended]="layoutInstructions | isExtendedCutLine">
  <div
    class="top-sliver"
    [style.height.px]="layoutInstructions | withContentBleed: false"
    [style.width]="
      layoutInstructions | calculateExtendedCutLineLengthString: (layoutInstructions | withContentBleed: false)
    "></div>
  <ng-template ngFor [ngForOf]="layoutInstructions | iterableCardStackRows: nCardsOnPage" let-last="last" let-i="index">
    <div class="spacer" [style.height.px]="layoutInstructions | cardContentHeight">
      {{ layoutInstructions | leftCutLineText: cardsAsData : i }}
    </div>
    <div
      *ngIf="!last"
      class="dividing-sliver-container"
      [style.height.px]="layoutInstructions | withContentBleed: false : 2">
      <div
        class="dividing-sliver"
        [style.width]="
          layoutInstructions | calculateExtendedCutLineLengthString: (layoutInstructions | withContentBleed: false)
        "
        [style.border-bottom]="(layoutInstructions | withContentBleed: false) === 0 ? 'unset' : null"></div>
      <div
        class="dividing-sliver-vertical-top-line"
        [style.height.px]="layoutInstructions | cutLineExtendedLengthInPx"
        [style.top.px]="-(layoutInstructions | cutLineExtendedLengthInPx)"
        [style.right.px]="-(layoutInstructions | withContentBleed: false)"></div>
      <div
        class="dividing-sliver-vertical-middle-line"
        [style.height.px]="layoutInstructions | withContentBleed: false : 2"
        [style.top.px]="0"
        [style.right.px]="-(layoutInstructions | withContentBleed: false)"></div>
      <div
        class="dividing-sliver-vertical-bottom-line"
        [style.height.px]="layoutInstructions | cutLineExtendedLengthInPx"
        [style.top.px]="layoutInstructions | withContentBleed: false : 2"
        [style.right.px]="-(layoutInstructions | withContentBleed: false)"></div>
    </div>
  </ng-template>
  <div
    class="bottom-sliver"
    [style.height.px]="layoutInstructions | withContentBleed: false"
    [style.width]="
      layoutInstructions | calculateExtendedCutLineLengthString: (layoutInstructions | withContentBleed: false)
    "></div>
</div>
