import { ApiClient } from './api-client';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Display } from '../models/display/dto/display';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';

@Injectable({ providedIn: 'root' })
export class DisplayAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Display';

  // Display
  public GetDisplay(
    id: string,
    includeProducts: boolean,
    ignoreLastSession: boolean,
    lastModified?: number,
    publishToast?: (message: string, title: string) => void
  ): Observable<Display> {
    const url = Endpoints.GetDisplay(id, includeProducts, ignoreLastSession, lastModified);
    const maxRetries = 5;
    return this.apiClient.recursiveGetObjectWithRetry(Display, url, null, maxRetries, publishToast).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetDisplay', err));
        return throwError(err);
      })
    );
  }

}
