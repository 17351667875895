/* src/app/modules/print-cards/components/cards/backgrounds/business-cards-2x3/doug-ar-business-card-stack-background/doug-ar-business-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0.625rem;
  box-sizing: border-box;
}
:host .main-content {
  flex: 1;
}
:host .main-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
:host .card-header {
  background-color: #000000;
  height: 14.3%;
  width: 100%;
  border-radius: 0.375rem 0.375rem 0 0;
}
/*# sourceMappingURL=doug-ar-business-card-stack-background.component.css.map */
