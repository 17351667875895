{
  "name": "budsense-client-display",
  "version": "2.0.0-9",
  "author": {
    "name": "Kevin Baker",
    "url": "https://mybudsense.com",
    "github": "https://github.com/mobilefirstdev"
  },
  "contributors": [
    {
      "name": "Kevin Baker",
      "url": "https://github.com/kevinpbaker"
    }
  ],
  "engines": {
    "npm": "use yarn instead of npm",
    "yarn": ">= 1.20.0"
  },
  "resolutions": {
    "autoprefixer": "10.4.20",
    "webpack": "^5.73.0"
  },
  "lint-staged": {
    "*.{js,ts}": [
      "eslint --fix"
    ],
    "*.{scss,css,html}": [
      "prettier --write --ignore-unknown"
    ]
  },
  "scripts": {
    "ng": "ng",
    "build": "ng build --configuration production --progress",
    "start": "ng serve --configuration development --port 4201",
    "startStaging": "ng serve --configuration staging --port 4201",
    "startFastStaging": "ng serve --configuration fastStaging --port 4201",
    "startFastRC": "ng serve --configuration fastRC --port 4201",
    "startRC": "ng serve --configuration rc --port 4201",
    "deployDev": "yarn install && ng build --configuration development --base-href ./ && aws s3 sync dist/display-app/. s3://dev.display.mybudsense.com --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --profile m1dev && aws cloudfront create-invalidation --distribution-id E2QSO4E7V44DT7 --paths '/*' --profile m1dev",
    "deployStaging": "yarn install && ng build --configuration staging --base-href ./ && aws s3 sync dist/display-app/. s3://staging.display.mybudsense.com --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --profile m1dev && aws cloudfront create-invalidation --distribution-id E37J9SMUQ3CJYE --paths '/*' --profile m1dev",
    "deployProdRC": "yarn install && ng build --configuration rc --base-href ./ && aws s3 sync dist/display-app/. s3://rc.display.mybudsense.com --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --profile m1dev && aws cloudfront create-invalidation --distribution-id E12VB6VP9FKO8X --paths '/*' --profile m1dev",
    "deployProd": "yarn install && ng build --configuration production --base-href ./ && aws s3 sync dist/display-app/. s3://display.mybudsense.com --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers --profile m1dev && aws cloudfront create-invalidation --distribution-id E1ZGXUHYHBLMGL --paths '/*' --profile m1dev",
    "deployDevCI": "ng build --configuration development --base-href ./ && aws s3 sync dist/display-app/. s3://dev.display.mybudsense.com --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers && aws cloudfront create-invalidation --distribution-id E2QSO4E7V44DT7 --paths '/*'",
    "deployStagingCI": "ng build --configuration staging --base-href ./ && aws s3 sync dist/display-app/. s3://staging.display.mybudsense.com --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers && aws cloudfront create-invalidation --distribution-id E37J9SMUQ3CJYE --paths '/*'",
    "deployProdRCCI": "ng build --configuration rc --base-href ./ && aws s3 sync dist/display-app/. s3://rc.display.mybudsense.com --grants read=uri=http://acs.amazonaws.com/groups/global/AllUsers && aws cloudfront create-invalidation --distribution-id E12VB6VP9FKO8X --paths '/*'",
    "precommit": "lint-staged",
    "prepare": "husky install",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "fix": "yarn cache clean && rm -rf .angular && rm -rf node_modules && yarn install"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.3.12",
    "@angular/cdk": "17.3.10",
    "@angular/common": "17.3.12",
    "@angular/compiler": "17.3.12",
    "@angular/core": "17.3.12",
    "@angular/forms": "17.3.12",
    "@angular/platform-browser": "17.3.12",
    "@angular/platform-browser-dynamic": "17.3.12",
    "@angular/router": "17.3.12",
    "@juggle/resize-observer": "^3.4.0",
    "@ngbracket/ngx-layout": "17.0.1",
    "@rx-angular/cdk": "^17.1.0",
    "@rx-angular/template": "^17.3.1",
    "autoprefixer": "10.4.20",
    "fastdom": "^1.0.12",
    "firebase": "^9.9.0",
    "jackson-js": "^1.1.0",
    "jsbarcode": "^3.11.6",
    "lzutf8": "^0.6.3",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.45",
    "ng-morph": "^3.0.0",
    "ngx-barcode6": "1.0.24",
    "ngx-bootstrap": "^5.6.2",
    "ngx-pipes": "^3.2.2",
    "ngx-spinner": "16.0.2",
    "ngx-toastr": "^16.2.0",
    "p5": "^1.4.1",
    "postcss": "^8.4.33",
    "rxjs": "~7.8.1",
    "tslib": "2.5.3",
    "zone.js": "~0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "~17.3.11",
    "@angular-devkit/core": "17.3.11",
    "@angular-devkit/schematics": "17.3.11",
    "@angular-eslint/builder": "17.5.3",
    "@angular-eslint/schematics": "^17.5.3",
    "@angular-eslint/template-parser": "^17.5.3",
    "@angular/cli": "17.3.11",
    "@angular/compiler-cli": "17.3.12",
    "@angular/language-service": "17.3.12",
    "@commitlint/cli": "^17.6.7",
    "@commitlint/config-conventional": "^17.6.7",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "2.0.13",
    "@types/node": "^20.1.0",
    "@typescript-eslint/eslint-plugin": "^6.21.0",
    "@typescript-eslint/parser": "6.21.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "latest",
    "eslint-plugin-import": "latest",
    "eslint-plugin-jsdoc": "latest",
    "eslint-plugin-kevs-plugin": "file:eslint",
    "eslint-plugin-prefer-arrow": "latest",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-unused-imports": "^3.2.0",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.2",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "lint-staged": "^15.2.4",
    "prettier": "^3.2.5",
    "prettier-eslint": "^16.3.0",
    "protractor": "~7.0.0",
    "ts-node": "~10.9.2",
    "typescript": "5.4.5"
  }
}
