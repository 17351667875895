import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';
import { StiiizyCardStackContentViewModel } from './stiiizy-card-stack-content-view-model';
import { StiiizyCardData } from '../../../../../../../models/print-cards/stiiizy-card-data';

@Component({
  selector: 'app-stiiizy-card-stack-content',
  templateUrl: './stiiizy-card-stack-content.component.html',
  styleUrls: ['./stiiizy-card-stack-content.component.scss'],
  providers: [StiiizyCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StiiizyCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: StiiizyCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @Input() override data: StiiizyCardData;

}
