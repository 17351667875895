import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';
import { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'customTopPaperMarginInPx'
})
export class CustomTopPaperMarginInPxPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper): number | null {
    const topPaperMarginInInches = cardsThatLayoutOntoPaper?.customTopPaperMarginInInches() ?? null;
    if (Number.isFinite(topPaperMarginInInches)) {
      return topPaperMarginInInches * CardStack.PIXELS_PER_INCH;
    }
    return null;
  }

}
