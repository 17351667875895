import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { Observable } from 'rxjs';
import { AgentOrangeCardData } from '../../../../../../../models/print-cards/agent-orange-card-data';
import { map } from 'rxjs/operators';

@Injectable()
export class AgentOrangeIndexCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<AgentOrangeCardData>;

  public readonly quantityAndSizeText$ = this.cardData$.pipe(
    map(cardData => cardData?.getQuantityAndSizeString())
  );

}
