/* src/app/modules/print-cards/components/render-cards/render-cards.component.scss */
:host {
  display: block;
  height: 100%;
  width: 100%;
}
.print-card-paper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.print-card-paper .print-card-middle-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.print-card-paper .print-card-middle-content .print-card-grid {
  place-content: center;
}
.print-card-paper .print-card-page-break {
  page-break-after: always;
}
/*# sourceMappingURL=render-cards.component.css.map */
