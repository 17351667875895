<div
  class="card-header"
  [style.justify-content]="(viewModel.isNonCannabinoidVariant$ | async) ? 'flex-end' : 'space-between'">
  <div
    *ngIf="data | sectionRowViewModelBasicMethod: 'getReadableStrainType' as strainType"
    class="header-content left-content">
    <div *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)" class="strain-container">{{ strainType }}</div>
  </div>
  <div class="header-content right-content">
    <div
      *ngIf="!(viewModel.isNonCannabinoidVariant$ | async); else notCannabisProduct"
      class="product-size-and-type-container">
      {{ data | sectionRowViewModelBasicMethod: 'rowVariantType' | trimSafely }}
    </div>
    <ng-template #notCannabisProduct>
      <div class="product-size-and-type-container">
        {{ data | sectionRowViewModelBasicMethod: 'rowVariantType' | trimSafely }}
      </div>
    </ng-template>
  </div>
</div>

<div class="card-body">
  <div class="card-body-left-column">
    <div class="product-id-info">
      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
        class="product-card-name"
        [style.color]="viewModel.headerTextColor$ | async">
        {{ title | trimSafely }}
      </div>
      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getBrand' as brand"
        class="product-card-brand"
        [style.color]="viewModel.bodyTextColor$ | async">
        {{ brand | trimSafely | uppercase }}
      </div>
    </div>
    <div class="pricing-container" [style.color]="viewModel.bodyTextColor$ | async">
      <app-column-primary-price
        [menu]="viewModel.cardStackMenu$ | async"
        [section]="viewModel.cardStack$ | async"
        [colVm]="viewModel.spoofedColViewModel$ | async"
        [rowVm]="viewModel.cardData$ | async"
        [locConfig]="viewModel.locationConfig$ | async"
        [locationId]="viewModel.locationId$ | async"
        [productStylingVm]="viewModel">
      </app-column-primary-price>
    </div>
  </div>
  <div class="card-body-right-column">
    <div *ngIf="viewModel.badgeOrLabelVisible$ | async" class="badge-and-label-container">
      <div class="badge-container">
        <img
          *ngFor="let variantBadge of data | printCardBadges"
          appImage
          class="badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>
      <app-label-inflator
        class="print-card-label"
        [columnLabel]="false"
        [labelComponentInterface]="data"
        [reset]="false">
      </app-label-inflator>
    </div>
    <div class="table-of-properties">
      <div
        *ngIf="!(viewModel.isNonCannabinoidVariant$ | async) && data | sectionRowViewModelBasicMethod: 'getThc' as thc"
        class="property-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        <div class="title">THC</div>
        <div
          class="value"
          [style.width.rem]="(data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 6 : 3"
          appScaleDownText
          [scaleDownLinkedKey]="viewModel.variantId$ | async"
          [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
          {{ thc }}
        </div>
      </div>
      <div
        *ngIf="!(viewModel.isNonCannabinoidVariant$ | async) && data | sectionRowViewModelBasicMethod: 'getCbd' as cbd"
        class="property-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        <div class="title">CBD</div>
        <div
          class="value"
          [style.width.rem]="(data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 6 : 3"
          appScaleDownText
          [scaleDownLinkedKey]="viewModel.variantId$ | async"
          [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
          {{ cbd }}
        </div>
      </div>
      <div
        *ngIf="
          !(viewModel.isNonCannabinoidVariant$ | async) && data
            | sectionRowViewModelBasicMethod: 'getListItemQty' as qty
        "
        class="property-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        <div class="title">QTY</div>
        <div
          appScaleDownText
          class="value"
          [style.width.rem]="(data | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 6 : 3">
          {{ qty }}
        </div>
      </div>
    </div>
  </div>
</div>
