import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'cardPreviewWidthInPx'
})
export class CardPreviewWidthInPxPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper | null): number {
    return cardsThatLayoutOntoPaper?.cardPreviewWidthInPx() ?? 0;
  }

}
