<div class="fire-and-flower-shelf-talker">
  <div class="fire-and-flower-shelf-talker-header">
    <div class="header-title">{{ viewModel.headerText$ | push }}</div>
    <img
      *rxIf="viewModel.companyLogo$ | push as logo"
      appImage
      class="header-logo"
      [asset]="logo"
      alt="company logo"
      src="" />
  </div>
  <div class="fire-and-flower-shelf-talker-body">
    <div
      *rxFor="let rowVariants of viewModel.variantsSplitInto7Rows$; let even = even"
      class="shelf-talker-row"
      [style.padding-left]="
        (viewModel.cardSize$ | push) === Types.ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7 ? '0.75rem' : '0.375rem'
      "
      [style.padding-right]="
        (viewModel.cardSize$ | push) === Types.ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7 ? '0.75rem' : '0.375rem'
      "
      [class.even]="even">
      <app-fire-and-flower-shelf-talker-item
        *ngIf="rowVariants?.[0] as firstGrouping"
        [shelfTalkerCardData]="viewModel.shelfTalkerCardData$ | push"
        [cardSize]="viewModel.cardSize$ | push"
        [variantsGroupedByBrand]="firstGrouping"
        [viewingInLiveView]="viewModel.viewingInLiveView$ | async" />
      <app-fire-and-flower-shelf-talker-item
        *ngIf="rowVariants?.[1] as secondGrouping"
        [shelfTalkerCardData]="viewModel.shelfTalkerCardData$ | push"
        [cardSize]="viewModel.cardSize$ | push"
        [variantsGroupedByBrand]="secondGrouping"
        [viewingInLiveView]="viewModel.viewingInLiveView$ | async" />
    </div>
  </div>
</div>
