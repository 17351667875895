import { BehaviorSubject, combineLatest, fromEvent, iif, Observable } from 'rxjs';
import { CardData } from '../../../../models/print-cards/card-data';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { SectionRowViewModelUtils } from '../../../../utils/section-row-view-model-utils';
import { PrintCardMenu } from '../../../../models/menu/print-card/print-card-menu';
import { CompanyConfiguration } from '../../../../models/company/dto/company-configuration';
import { LocationConfiguration } from '../../../../models/company/dto/location-configuration';
import { Injectable } from '@angular/core';
import { InjectorService } from '../../../services/injector.service';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Menu } from '../../../../models/menu/menu';
import { exists } from '../../../../functions/exists';
import { VariantAssetService } from '../../../services/variant-asset-service';
import { DistinctUtils } from '../../../../utils/distinct.utils';
import { LabelDomainModel } from '../../../../domain/label-domain-model';
import { Label } from '../../../../models/menu/labels/label';
import { LocationDomainModel } from '../../../../domain/location-domain-model';
import { CompanyDomainModel } from '../../../../domain/company-domain-model';
import { ShelfTalkerMenu } from '../../../../models/menu/shelf-talkers/shelf-talker-menu';
import { ShelfTalkerCardData } from '../../../../models/shelf-talkers/shelf-talker-card-data';
import { ShelfTalkerUtils } from '../../../../utils/shelf-talker-utils';

@Injectable()
export class RenderPrintCardLiveViewViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private variantAssetService: VariantAssetService,
    private injectorService: InjectorService
  ) {
    super();
  }

  connectToLabels = (labelsData: any|null) => {
    const labels = this.injectorService?.Deserialize?.arrayOf(Label, labelsData);
    this.labelDomainModel.labelDataSentFromDashboardApp(labels);
  };

  public readonly companyConfig$ = this.companyDomainModel.companyConfig$;
  connectToCompanyConfig = (companyConfigData: any|null) => {
    const companyConfig = this.injectorService?.Deserialize?.instanceOf(CompanyConfiguration, companyConfigData);
    this.companyDomainModel.companyConfigSentFromDashboardApp(companyConfig);
  };

  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  connectToLocationConfig = (locationConfigData: any|null) => {
    const locationConfig = this.injectorService?.Deserialize?.instanceOf(LocationConfiguration, locationConfigData);
    this.locationDomainModel.locationConfigSentFromDashboardApp(locationConfig);
  };

  private readonly _metadata = new BehaviorSubject<any|null>(null);
  public readonly metadata$ = this._metadata as Observable<any|null>;
  connectToMetadata = (metadata: any|null) => this._metadata.next(metadata);

  private readonly _printCardMenu = new BehaviorSubject<PrintCardMenu|null>(null);
  public readonly printCardMenu$ = combineLatest([
    this._printCardMenu,
    this.companyConfig$,
    this.locationConfig$
  ]).pipe(
    map(([menu, companyConfig, locationConfig]) => {
      menu?.setImplicitProperties(companyConfig, locationConfig);
      return menu;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToPrintCardMenu = (printCardMenuData: any|null) => {
    const menu = this.injectorService?.Deserialize?.instanceOf(Menu, printCardMenuData);
    const printCardMenu = menu instanceof PrintCardMenu ? menu : null;
    this._printCardMenu.next(printCardMenu);
  };

  public readonly cardStack$ = this.printCardMenu$.pipe(map(menu => menu?.getCardStack()));

  private readonly _variantIds = new BehaviorSubject<string[]|null>(null);
  public readonly variantIds$ = this._variantIds as Observable<string[]|null>;
  connectToVariantIds = (variantIds: string[]|null) => this._variantIds.next(variantIds);

  private readonly _shelfTalkerMenu = new BehaviorSubject<ShelfTalkerMenu|null>(null);
  public readonly shelfTalkerMenu$ = this._shelfTalkerMenu as Observable<ShelfTalkerMenu|null>;
  connectToShelfTalkerMenu = (shelfTalkerMenuData: any|null) => {
    const menu = this.injectorService?.Deserialize?.instanceOf(Menu, shelfTalkerMenuData);
    const shelfTalkerMenu = menu instanceof ShelfTalkerMenu ? menu : null;
    this._shelfTalkerMenu.next(shelfTalkerMenu);
  };

  private readonly _sectionId = new BehaviorSubject<string|null>(null);
  public readonly sectionId$ = this._sectionId as Observable<string|null>;
  connectToSectionId = (sectionId: string|null) => this._sectionId.next(sectionId);

  protected readonly printCardGrouping$: Observable<CardData|null> = combineLatest([
    this.printCardMenu$,
    this.cardStack$,
    this.variantIds$,
    this.companyConfig$,
    this.locationConfig$,
    this.metadata$
  ]).pipe(
    map(([menu, cardStack, variantIds, companyConfig, locConfig, metadata]) => {
      const generateCardData = SectionRowViewModelUtils.generateRowViewModels;
      const products = cardStack?.products;
      return generateCardData(cardStack, menu, companyConfig, locConfig, products, [], variantIds, metadata)
        ?.filter((card): card is CardData => card instanceof CardData)
        ?.filter(cardData => cardData?.rowVariants?.map(v => v?.id)?.intersection(variantIds)?.length > 0)
        ?.firstOrNull();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected shelfTalkerGrouping$: Observable<ShelfTalkerCardData|null> = combineLatest([
    this.shelfTalkerMenu$,
    this.sectionId$,
    this.companyConfig$,
    this.locationConfig$
  ]).pipe(
    map(([menu, sectionId, companyConfig, locConfig]) => {
      const cardId = sectionId || menu?.sections?.firstOrNull()?.id;
      const generateData = ShelfTalkerUtils.generateShelfTalkerCardData;
      return generateData(menu, companyConfig, locConfig, [cardId])
        ?.filter((data): data is ShelfTalkerCardData => data instanceof ShelfTalkerCardData)
        ?.firstOrNull();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cardGrouping$: Observable<CardData|ShelfTalkerCardData|null> = this.shelfTalkerMenu$.pipe(
    switchMap(shelfTalkerMenu => {
      return iif(() => exists(shelfTalkerMenu), this.shelfTalkerGrouping$, this.printCardGrouping$);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* *************************** Local Threads of Execution *************************** */

  /**
   * All messages coming from window get intercepted here. This means messages that the display app sends to the
   * dashboard app will be received here as well. Therefore, we must check if the specific message contains
   * relevant data, or else we can end up in an infinite loop.
   */
  private listenToMessages = fromEvent(window, 'message').pipe(
    filter((event: MessageEvent) => {
      const origin = event?.origin?.toLowerCase();
      const hasData = exists(event?.data);
      return hasData && (origin?.includes('localhost') || origin?.includes('mybudsense.com'));
    }),
    map((event: MessageEvent) => event?.data)
  ).subscribeWhileAlive({
    owner: this,
    next: (data: any) => {
      if (data?.companyConfig) this.connectToCompanyConfig(data?.companyConfig);
      if (data?.locationConfig) this.connectToLocationConfig(data?.locationConfig);
      if (data?.printCardMenu) this.connectToPrintCardMenu(data?.printCardMenu);
      if (data?.variantIds) this.connectToVariantIds(data?.variantIds);
      if (data?.shelfTalkerMenu) this.connectToShelfTalkerMenu(data?.shelfTalkerMenu);
      if (data?.sectionId) this.connectToSectionId(data?.sectionId);
      if (data?.labels) this.connectToLabels(data?.labels);
      if (data?.metadata) this.connectToMetadata(data?.metadata);
    }
  });

  private sendCardDimensionsToDashboard = this.cardStack$.subscribeWhileAlive({
    owner: this,
    next: (cardStack) => {
      const msg = {
        cardHeight: cardStack?.cardPreviewHeightInPx(),
        cardWidth: cardStack?.cardPreviewWidthInPx()
      };
      window?.parent?.postMessage(msg, '*');
    }
  });

  private connectWithVariantAssetService = this.printCardMenu$.pipe(
    filter(exists),
    distinctUntilChanged(DistinctUtils.distinctMenuById)
  ).subscribeWhileAlive({
    owner: this,
    next: (printCardMenu) => {
      this.variantAssetService.fetchVariantAssetsForMenusAssignedToDisplay([printCardMenu]);
    }
  });

}
