@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/business-cards-2x3/stiiizy-card-stack-content/stiiizy-side-bar/stiiizy-side-bar.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  display: block;
  height: 100%;
  width: 100%;
}
.sidebar-section {
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-transform: uppercase;
  background-color: black;
}
.sidebar-section .sidebar-text {
  position: absolute;
  transform-origin: top left;
  transform: rotate(-90deg);
  top: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-wrap: nowrap;
  white-space: pre-wrap;
  font-family: Archivo, sans-serif;
  font-weight: 900;
  font-size: 0.5625rem;
  line-height: 0.5625rem;
  color: white;
  letter-spacing: -0.21px;
}
/*# sourceMappingURL=stiiizy-side-bar.component.css.map */
