import { CardData } from './card-data';
import { AssetLibraryType } from '../enum/dto/asset-library-type.enum';
import { SectionLayoutType } from '../enum/dto/section-layout-type.enum';
import { exists } from '../../functions/exists';
import { ProductType } from '../enum/dto/product-type.enum';
import type { Variant } from '../product/dto/variant';
import { SectionColumnConfigDataValue } from '../menu/section/section-column-config';
import { VariantTypeUtils } from '../../utils/variant-type-utils';

export class StiiizyCardData extends CardData {

  override getCannabinoid(
    cannabinoid: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    const c = super.getCannabinoid(cannabinoid, displayFormat);
    return (c?.includes('<') || c === 'N/A' || c === '-' || c === '--') ? null : c;
  }

  override getTerpene(
    terpenePascalCased: string,
    displayFormat: SectionColumnConfigDataValue = SectionColumnConfigDataValue.NumericValue
  ): string {
    const t = super.getTerpene(terpenePascalCased, displayFormat);
    return (t?.includes('<') || t === 'N/A' || t === '-' || t === '--') ? null : t;
  }

  override getCardColor(): string {
    if (this.isStiiizyBrandedCard()) {
      return 'black';
    }
    return super.getCardColor();
  }

  override getHeaderTextColor(): string {
    if (this.isStiiizyBrandedCard()) {
      return 'white';
    }
    return super.getHeaderTextColor();
  }

  override getBodyTextColor(): string {
    if (this.isStiiizyBrandedCard()) {
      return 'white';
    }
    return super.getBodyTextColor();
  }

  protected override getSizeHelperForEdiblesThatAreNotReadyToDrinkBeverages(variant: Variant): string | null {
    return null;
  }

  protected override getGridSizeHelperForEdiblesThatAreNotReadyToDrinkBeverages(variant: Variant): string | null {
    return null;
  }

  protected override getSizeHelperForPreRolls(variant: Variant): string | null {
    const quantity = variant?.packagedQuantity;
    const formattedUnitSize = this.getFormattedUnitSizeFor(variant);
    const hasQty = Number.isFinite(quantity) && (quantity > 0);
    const hasSize = exists(formattedUnitSize)
      && (formattedUnitSize !== '0')
      && (formattedUnitSize !== '-')
      && (formattedUnitSize !== '--');
    const singlePack = hasQty && quantity === 1 && hasSize;
    switch (true) {
      case singlePack:         return formattedUnitSize;
      case hasQty && hasSize:  return `${quantity} x ${formattedUnitSize}`;
      case hasQty && !hasSize: return `${quantity}`;
      default:                 return '-';
    }
  }

  protected override getGridSizeHelperForPreRolls(variant: Variant): string | null {
    const quantity = variant?.packagedQuantity;
    const formattedUnitSize = this.getFormattedUnitSizeFor(variant);
    const hasQty = Number.isFinite(quantity);
    const hasSize = exists(formattedUnitSize) && (formattedUnitSize !== '-');
    const singlePack = hasQty && quantity === 1 && hasSize;
    switch (true) {
      case singlePack:         return formattedUnitSize;
      case hasQty && hasSize:  return `${quantity} x ${formattedUnitSize}`;
      case hasQty && !hasSize: return `${quantity}`;
      default:                 return '-';
    }
  }

  protected getGridSizeHelperForCapsules(variant: Variant): string | null {
    return null;
  }

  protected override getGridSizeHelperForEverythingElse(variant: Variant): string | null {
    switch (this.rowProductType()) {
      case ProductType.Accessories:
      case ProductType.Wellness:
      case ProductType.Oil:
        return null;
      default:
        return super.getGridSizeHelperForEverythingElse(variant);
    }
  }

  protected override seedPackageQuantityWord(variant: Variant): string {
    return 'PER PACK';
  }

  protected override edibleNonReadyToDrinkPackageQuantityWord(variant: Variant): string {
    return 'PACK';
  }

  protected override quantityWordForEverythingElse(variant: Variant): string {
    return 'PACK';
  }

  /**
   * The main title for Stiiizy cards are variant/product types, not variant/product names.
   * Custom1 is set via Flowhub POS, and is considered the "Flowhub Variant Type".
   */
  override getRowTitle(): string {
    return this.rowVariants?.map(v => v?.custom1)?.mode()
        || this.rowVariants?.map(it => it?.variantType)?.mode()
        || this.rowVariants?.map(it => it?.productType)?.mode();
  }

  isStiiizyBrandedCard(): boolean {
    const brand = this.getBrand()?.toLowerCase();
    return brand?.includes('stiiizy') || brand?.includes('stiizy') || brand?.includes('stizy');
  }

  /**
   * Don't delete even if the IDE says it's unused. It's used in the template via angular pipe.
   * Extra space in return value is intentional, so don't remove it.
   */
  getSidebarText(): string | null {
    const productType = this.rowProductType();
    const nFlavorsOrStains = this.numberOfFlavorsOrStrains();
    if (productType === ProductType.Beverage || productType === ProductType.Edible) {
      return nFlavorsOrStains > 1 ? `${nFlavorsOrStains}   Flavors` : null;
    } else {
      let useTypes = productType === ProductType.Accessories;
      if (productType === ProductType.Other) {
        useTypes = this.rowVariants.some(v => !VariantTypeUtils.isOtherTypeWithCannabinoids(v?.variantType));
      }
      const strainsOrTypes = useTypes ? 'Types' : 'Strains';
      return nFlavorsOrStains > 1 ? `Multiple   ${strainsOrTypes}   Available` : null;
}
  }

  numberOfFlavorsOrStrains(): number {
    return this.isFlavorCardOrMultiStrainCard()
      ? this.getScopedVisibleVariants()?.length ?? 0
      : 1;
  }

  isFlavorCardOrMultiStrainCard(): boolean {
    return this.section?.layoutType === SectionLayoutType.ChildVariantList;
  }

  /**
   * If it's a Stiiizy branded card (black background), we want to show the alternative brand asset if available
   * because they are usually white.
   */
  variantAssetTypeSortOrder(): string[] {
    if (this.isStiiizyBrandedCard()) {
      return [
        AssetLibraryType.AlternateBrand,
        AssetLibraryType.PrimaryBrand,
        AssetLibraryType.Brand
      ];
    }
    return this.menu?.variantAssetTypeSortOrder();
  }

}
