import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import type { CardsThatLayoutOntoPaper } from '../../../../models/menu/section/cards-that-layout-onto-paper';

@Component({ selector: 'app-cut-lines', template: '' })
export abstract class CutLinesComponent extends BaseComponent {

  @Input() layoutInstructions: CardsThatLayoutOntoPaper;
  @Input() nCardsOnPage: number;

  setupBindings() {
  }

  setupViews() {
  }

}
