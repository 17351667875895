import { Pipe, PipeTransform } from '@angular/core';
import type { VariantsGroupedByBrand } from '../../../models/variants-grouped-by-brand';

@Pipe({
  name: 'variantGroupedByBrandMethod'
})
export class VariantGroupedByBrandMethodPipe implements PipeTransform {

  transform(
    variantsGroupedByBrand: VariantsGroupedByBrand,
    methodKey: string,
    ...args: any[]
  ): any {
    return variantsGroupedByBrand?.[methodKey]?.(...args);
  }

}
