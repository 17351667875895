@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/business-cards-2x3/honey-haze-business-card-stack-content/honey-haze-business-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  padding: 0.5rem;
  overflow: hidden;
}
:host .card-content {
  position: relative;
  width: 100%;
  height: 100%;
}
:host .card-content .background-content {
  position: absolute;
  inset: 0;
  z-index: -1;
}
:host .card-content .card-separator {
  height: 0.0625rem;
  background: #dadbde;
  margin: 0;
  border: 0;
}
:host .top-container,
:host .bottom-container {
  box-sizing: border-box;
  height: calc(50% - 0.0625rem);
}
:host .top-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0.5rem 0.5rem 0.5rem;
}
:host .top-container .top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
:host .top-container .top-row .product-title {
  font-family: poppins, sans-serif;
  font-weight: 800;
  font-size: 1.5625rem;
  letter-spacing: 0.09375rem;
  line-height: 1.5625rem;
  text-transform: uppercase;
}
:host .top-container .top-row .product-card-container {
  display: flex;
}
:host .top-container .top-row .product-card-container .product-card-size {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.625rem;
  flex: 1;
  text-align: end;
  white-space: nowrap;
}
:host .top-container .top-row .product-card-container .product-card-size.two-col {
  text-align: center;
  width: 3.6rem;
}
:host .top-container .bottom-row {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
:host .top-container .bottom-row .content-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.1875rem;
}
:host .top-container .bottom-row .content-container .product-badge-container {
  align-self: center;
  margin-right: 0.375rem;
  max-width: 1.375rem;
  max-height: 1.375rem;
  display: flex;
  align-items: center;
}
:host .top-container .bottom-row .content-container .product-badge-container .product-badge {
  height: 100%;
  width: 100%;
}
:host .top-container .bottom-row .content-container .product-badge-container .polygon-badge {
  fill: #dadbde;
}
:host .top-container .bottom-row .content-container .outer-content-container {
  width: 100%;
}
:host .top-container .bottom-row .content-container .outer-content-container .top-row .product-name {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.625rem;
  margin-bottom: 0.125rem;
  line-height: 0.75rem;
  overflow: clip;
  overflow-clip-margin: 0rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .top-container .bottom-row .content-container .outer-content-container .inner-content-container {
  display: flex;
  align-items: center;
}
:host .top-container .bottom-row .product-brand {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.4375rem;
  line-height: 0.75rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .top-container .bottom-row .print-card-label {
  align-self: flex-start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .top-container .bottom-row .print-card-label ::ng-deep .product-label {
  font-family: rubik, sans-serif;
  font-weight: 500;
  font-size: 0.4375rem;
  display: block;
  max-width: 6rem;
  border-radius: 0.125rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
:host .top-container .bottom-row .product-price-container {
  text-align: end;
}
:host .top-container .bottom-row .product-price-container.grid-mode {
  display: flex;
  text-align: end;
}
:host .top-container .bottom-row .product-price-container.grid-mode.two-col {
  text-align: center;
}
:host .top-container .bottom-row .product-price-container.grid-mode .product-price {
  width: 3.6rem;
}
:host .top-container .bottom-row .product-price-container .product-price {
  font-family: poppins, sans-serif;
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.0225rem;
}
:host .top-container .bottom-row .product-price-container ::ng-deep .regular-price .product-variant-previous-price {
  display: none;
}
:host .top-container .bottom-row .product-price-container ::ng-deep .sale-price .product-variant-current-price.and-has-previous-price {
  display: none;
}
:host .top-container .bottom-row .product-price-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.5625rem;
}
:host .bottom-container {
  padding: 0.5rem;
  display: flex;
}
:host .bottom-container .left-column {
  display: flex;
  align-items: center;
  margin-right: 1.375em;
}
:host .bottom-container .left-column .cannabinoids {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  white-space: nowrap;
}
:host .bottom-container .left-column .cannabinoids .property-container .title {
  font-family: poppins, sans-serif;
  font-weight: 800;
  font-size: 0.75rem;
  margin-bottom: 0.0625rem;
  line-height: 0.75rem;
  letter-spacing: 0.0375rem;
}
:host .bottom-container .left-column .cannabinoids .property-container .value {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.5625rem;
  line-height: 0.5625rem;
  letter-spacing: 0.028125rem;
}
:host .bottom-container .product-column-desc .product-card-desc {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 0.66rem;
  letter-spacing: 0.02rem;
  overflow: clip;
  overflow-clip-margin: 0rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
/*# sourceMappingURL=honey-haze-business-card-stack-content.component.css.map */
