import { exists } from '../functions/exists';
import type { Menu } from '../models/menu/menu';
import type { PrintMenu } from '../models/menu/print-menu';
import type { PrintCardMenu } from '../models/menu/print-card/print-card-menu';
import { ShelfTalkerMenu } from '../models/menu/shelf-talkers/shelf-talker-menu';

export class MenuUtils {

  /**
   * Used to remove circular imports
   */
  public static isShelfTalkerMenu = (menu: Menu): menu is ShelfTalkerMenu => {
    return exists((menu as any)?.getShelfTalkerCards);
  };

  /**
   * Used to remove circular imports
   */
  public static isPrintMenu = (menu: Menu): menu is PrintMenu => {
    return exists((menu as any)?.getPrintHeaderLayoutType);
  };

  /**
   * Used to remove circular imports
   */
  public static isPrintCardMenu = (menu: Menu): menu is PrintCardMenu => {
    return exists((menu as any)?.getCardStack);
  };

}
