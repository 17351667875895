import '../../utils/array.extensions';
import '../../utils/map.extensions';
import '../../utils/number.extensions';
import '../../utils/behavior-subject.extensions';
import '../../utils/replay-subject.extensions';
import '../../utils/observable.extensions';
import { NgModule } from '@angular/core';
import { RenderCardsComponent } from './components/render-cards/render-cards.component';
import { PrintCardsRoutingModule } from './print-cards-routing.module';
import { CommonModule } from '@angular/common';
import { CardGridComponent } from './components/card-grid/card-grid.component';
import { PrintCardGridManagerDirective } from './directives/print-card-grid-manager.directive';
import { CardJigComponent } from './components/card-jig/card-jig.component';
import { RenderCardBackgroundComponent } from './components/cards/render-card-background/render-card-background.component';
import { PrintCardMenuBasicMethodPipe } from './pipes/print-card-menu-basic-method.pipe';
import { CardContentInflatorComponent } from './components/cards/inflators/print-card-content-inflator/card-content-inflator.component';
import { PrintCardBackgroundInflatorComponent } from './components/cards/inflators/print-card-background-inflator/print-card-background-inflator.component';
import { WithContentBleedPipe } from './pipes/content-bleed.pipe';
import { IsOnPerforatedPaperPipe } from './pipes/is-perforated.pipe';
import { PrintCardTopCutLinesComponent } from './components/cut-lines/print-card-top-cut-lines/print-card-top-cut-lines.component';
import { PrintCardLeftCutLinesComponent } from './components/cut-lines/print-card-left-cut-lines/print-card-left-cut-lines.component';
import { PrintCardRightCutLinesComponent } from './components/cut-lines/print-card-right-cut-lines/print-card-right-cut-lines.component';
import { PrintCardBottomCutLinesComponent } from './components/cut-lines/print-card-bottom-cut-lines/print-card-bottom-cut-lines.component';
import { IterableCardStackColumnsPipe } from './pipes/iterable-card-stack-columns.pipe';
import { CardContentWidthPipe } from './pipes/card-content-width.pipe';
import { CardContentHeightPipe } from './pipes/card-content-height.pipe';
import { IterableCardStackRowsPipe } from './pipes/iterable-card-stack-rows.pipe';
import { RenderCardPreviewComponent } from './components/render-card-preview/render-card-preview.component';
import { IsExtendedCutLinePipe } from './pipes/is-extended-cut-line.pipe';
import { CardPreviewWidthInPxPipe } from './pipes/card-preview-width-in-px.pipe';
import { CardPreviewHeightInPxPipe } from './pipes/card-preview-height-in-px.pipe';
import { CustomTopPaperMarginInPxPipe } from './pipes/custom-top-paper-margin-in-inches.pipe';
import { VerticalCardStackJustificationPipe } from './pipes/vertical-card-stack-justification.pipe';
import { GenericCardStackBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/generic-card-stack-background/generic-card-stack-background.component';
import { GenericCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/generic-card-stack-content/generic-card-stack-content.component';
import { PrintCardBadgesPipe } from './pipes/print-card-badges.pipe';
import { PrintCardBackgroundImageWithColorOverlayAndOpacityComponent } from './components/cards/backgrounds/print-card-background-image-with-color-overlay-and-opacity/print-card-background-image-with-color-overlay-and-opacity.component';
import { PopsCannabis5By5CardBackgroundComponent } from './components/cards/backgrounds/custom-cards-5x5/pops-cannabis-5-by5-card-background/pops-cannabis-5-by-5-card-background.component';
import { PopsCannabis5By5CardStackContentComponent } from './components/cards/card-content/custom-cards-5x5/pops-cannabis-5-by-5-card-stack-content/pops-cannabis-5-by-5-card-stack-content.component';
import { ScaleDownTextModule } from '../scale-down-text/scale-down-text.module';
import { LeftCutLineTextPipe } from './pipes/left-cut-line-text.pipe';
import { CutLineExtendedLengthInPxPipe } from './pipes/cut-line-extended-length.pipe';
import { CalculateExtendedCutLineLengthStringPipe } from './pipes/calculate-extended-cut-line-length-string.pipe';
import { RenderPrintCardLiveViewComponent } from './components/render-print-card-live-view/render-print-card-live-view.component';
import { SharedModule } from '../shared/shared.module';
import { DougPostCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/doug-post-card-stack-content/doug-post-card-stack-content.component';
import { DougBusinessCardStackContentComponent } from './components/cards/card-content/business-cards-2x3/doug-business-card-stack-content/doug-business-card-stack-content.component';
import { DougIndexCardStackContentComponent } from './components/cards/card-content/index-cards-3x5/doug-index-card-stack-content/doug-index-card-stack-content.component';
import { DougPostCardStackBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/doug-post-card-stack-background/doug-post-card-stack-background.component';
import { DougBusinessCardStackBackgroundComponent } from './components/cards/backgrounds/business-cards-2x3/doug-business-card-stack-background/doug-business-card-stack-background.component';
import { PopsCannabisPriceComponent } from './components/cards/card-content/custom-cards-5x5/pops-cannabis-5-by-5-card-stack-content/pops-cannabis-price/pops-cannabis-price.component';
import { LinkWidthDirective } from './components/cards/card-content/custom-cards-5x5/pops-cannabis-5-by-5-card-stack-content/link-width.directive';
import { PlainJanePostCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/plain-jane-post-card-stack-content/plain-jane-post-card-stack-content.component';
import { PlainJanePostCardStackBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/plain-jane-post-card-stack-background/plain-jane-post-card-stack-background.component';
import { PlainJaneIndexCardStackBackgroundComponent } from './components/cards/backgrounds/index-cards-3x5/plain-jane-index-card-stack-background/plain-jane-index-card-stack-background.component';
import { PlainJaneIndexCardStackContentComponent } from './components/cards/card-content/index-cards-3x5/plain-jane-index-card-stack-content/plain-jane-index-card-stack-content.component';
import { PlainJaneBusinessCardStackBackgroundComponent } from './components/cards/backgrounds/business-cards-2x3/plain-jane-business-card-stack-background/plain-jane-business-card-stack-background.component';
import { PlainJaneBusinessCardStackContentComponent } from './components/cards/card-content/business-cards-2x3/plain-jane-business-card-stack-content/plain-jane-business-card-stack-content.component';
import { OGKushBusinessCardStackContentComponent } from './components/cards/card-content/business-cards-2x3/og-kush-business-card-stack-content/og-kush-business-card-stack-content.component';
import { OGKushIndexCardStackContentComponent } from './components/cards/card-content/index-cards-3x5/og-kush-index-card-stack-content/og-kush-index-card-stack-content.component';
import { OGKushPostCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/og-kush-post-card-stack-content/og-kush-post-card-stack-content.component';
import { CardHeaderUnitSizePipe } from './components/cards/pipes/card-header-unit-size.pipe';
import { MissJonesCardStackBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/miss-jones-card-stack-background/miss-jones-card-stack-background.component';
import { MissJonesPostCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/miss-jones-post-card-stack-content/miss-jones-post-card-stack-content.component';
import { AgentOrangePostCardStackBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/agent-orange-post-card-stack-background/agent-orange-post-card-stack-background.component';
import { AgentOrangePostCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/agent-orange-post-card-stack-content/agent-orange-post-card-stack-content.component';
import { AgentOrangeIndexCardStackContentComponent } from './components/cards/card-content/index-cards-3x5/agent-orange-index-card-stack-content/agent-orange-index-card-stack-content.component';
import { AgentOrangeIndexCardStackBackgroundComponent } from './components/cards/backgrounds/index-cards-3x5/agent-orange-index-card-stack-background/agent-orange-index-card-stack-background.component';
import { TrueNorthPortraitCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/true-north-post-card-stack-content/true-north-portrait-card-stack-content/true-north-portrait-card-stack-content.component';
import { TrueNorthPortraitCardStackBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/true-north-portrait-card-stack-background/true-north-portrait-card-stack-background.component';
import { PrintCardInternalGridCutLinesComponent } from './components/cut-lines/print-card-internal-grid-cut-lines/print-card-internal-grid-cut-lines.component';
import { GetNInternalGridCrossesPipe } from './pipes/get-n-internal-grid-crosses.pipe';
import { InternalGridCutLineCrossComponent } from './components/cut-lines/print-card-internal-grid-cut-lines/internal-grid-cut-line-cross/internal-grid-cut-line-cross.component';
import { GetInternalCrossDimensionPipe } from './pipes/get-internal-cross-dimension.pipe';
import { GetInternalGridCutLineCrossTopOffsetPipe } from './pipes/get-internal-grid-cut-line-cross-top-offset.pipe';
import { GetInternalGridCutLineCrossLeftOffsetPipe } from './pipes/get-internal-grid-cut-line-cross-left-offset.pipe';
import { TrueNorthLandscapeCardStackBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/true-north-landscape-card-stack-background/true-north-landscape-card-stack-background.component';
import { TrueNorthLandscapeCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/true-north-post-card-stack-content/true-north-landscape-card-stack-content/true-north-landscape-card-stack-content.component';
import { FikaIndexCardStackBackgroundComponent } from './components/cards/backgrounds/index-cards-3x5/fika-index-card-stack-background/fika-index-card-stack-background.component';
import { FikaIndexCardStackContentComponent } from './components/cards/card-content/index-cards-3x5/fika-index-card-stack-content/fika-index-card-stack-content.component';
import { JaneDoeAddressCardStackContentComponent } from './components/cards/card-content/address-cards-1x3/jane-doe-address-card-stack-content/jane-doe-address-card-stack-content.component';
import { JaneDoeAddressCardStackBackgroundComponent } from './components/cards/backgrounds/address-cards-1x3/jane-doe-address-card-stack-background/jane-doe-address-card-stack-background.component';
import { JaneDoeBusinessCardStackBackgroundComponent } from './components/cards/backgrounds/business-cards-2x3/jane-doe-business-card-stack-background/jane-doe-business-card-stack-background.component';
import { JaneDoeBusinessCardStackContentComponent } from './components/cards/card-content/business-cards-2x3/jane-doe-business-card-stack-content/jane-doe-business-card-stack-content.component';
import { JaneDoePostCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/jane-doe-post-card-stack-content/jane-doe-post-card-stack-content.component';
import { JaneDoePostCardStackBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/jane-doe-post-card-stack-background/jane-doe-post-card-stack-background.component';
import { NgStringPipesModule } from 'ngx-pipes';
import { SessionsPostCardStackBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/sessions-post-card-stack-background/sessions-post-card-stack-background.component';
import { SessionsPostCardStackContentComponent } from './components/cards/card-content/post-cards-4x6/sessions-post-card-stack-content/sessions-post-card-stack-content.component';
import { SessionsPriceComponent } from './components/cards/card-content/post-cards-4x6/sessions-post-card-stack-content/sessions-price/sessions-price.component';
import { FikaSizeAndPriceComponent } from './components/cards/card-content/index-cards-3x5/fika-index-card-stack-content/fika-size-and-price/fika-size-and-price.component';
import { GetSalePriceWasTextPipe } from './components/cards/card-content/index-cards-3x5/fika-index-card-stack-content/fika-size-and-price/get-sale-price-was-text.pipe';
import { GetFikaGridSizePipe } from './components/cards/card-content/index-cards-3x5/fika-index-card-stack-content/fika-size-and-price/get-fika-grid-size.pipe';
import { NVariantsAreOnSalePipe } from './components/cards/pipes/n-variants-are-on-sale.pipe';
import { InflatorModule } from '../inflator/inflator.module';
import { ScaleDownTextAndAdjustLinesModule } from '../scale-down-text-and-adjust-lines/scale-down-text-and-adjust-lines.module';
import { FikaBeverageAddressCardStackBackgroundComponent } from './components/cards/backgrounds/address-cards-1x3/fika-beverage-address-card-stack-background/fika-beverage-address-card-stack-background.component';
import { FikaBeverageAddressCardStackContentComponent } from './components/cards/card-content/address-cards-1x3/fika-beverage-address-card-stack-content/fika-beverage-address-card-stack-content.component';
import { HoneyHazeBusinessCardStackContentComponent } from './components/cards/card-content/business-cards-2x3/honey-haze-business-card-stack-content/honey-haze-business-card-stack-content.component';
import { FireAndFlowerCardStackBackgroundComponent } from './components/cards/backgrounds/custom-cards-2x2/fire-and-flower-card-stack-background/fire-and-flower-card-stack-background.component';
import { FireAndFlowerCardStackContentComponent } from './components/cards/card-content/custom-cards-2x2/fire-and-flower-card-stack-content/fire-and-flower-card-stack-content.component';
import { PlantlifeBusinessCardStackContentComponent } from './components/cards/card-content/business-cards-2x3/plantlife-business-card-stack-content/plantlife-business-card-stack-content.component';
import { FikaAccessoriesIndexCardStackContentComponent } from './components/cards/card-content/index-cards-3x5/fika-accessories-index-card-stack-content/fika-accessories-index-card-stack-content.component';
import { FikaAccessoriesIndexCardBackgroundComponent } from './components/cards/backgrounds/index-cards-3x5/fika-accessories-index-card-background/fika-accessories-index-card-background.component';
import { PrintCardScaleDownTextDirective } from './directives/print-card-scale-down-text.directive';
import { PrintCardDescriptionContainerComponent } from './components/cards/card-content/shared/components/print-card-description-container/print-card-description-container.component';
import { FireAndFlowerDefaultCardStackBackgroundComponent } from './components/cards/backgrounds/custom-cards-2x2/fire-and-flower-default-card-stack-background/fire-and-flower-default-card-stack-background.component';
import { FireAndFlowerDefaultCardStackContentComponent } from './components/cards/card-content/custom-cards-2x2/fire-and-flower-default-card-stack-content/fire-and-flower-default-card-stack-content.component';
import { FireAndFlowerSaleCardStackBackgroundComponent } from './components/cards/backgrounds/custom-cards-2x2/fire-and-flower-sale-card-stack-background/fire-and-flower-sale-card-stack-background.component';
import { FireAndFlowerSaleCardStackContentComponent } from './components/cards/card-content/custom-cards-2x2/fire-and-flower-sale-card-stack-content/fire-and-flower-sale-card-stack-content.component';
import { FireAndFlowerMemberCardStackContentComponent } from './components/cards/card-content/custom-cards-2x2/fire-and-flower-member-card-stack-content/fire-and-flower-member-card-stack-content.component';
import { FireAndFlowerMemberCardStackBackgroundComponent } from './components/cards/backgrounds/custom-cards-2x2/fire-and-flower-member-card-stack-background/fire-and-flower-member-card-stack-background.component';
import { BaseInventoryLabelStackBackgroundComponent } from './components/print-labels/backgrounds/custom-labels-2x4/base-inventory-label-stack-background/base-inventory-label-stack-background.component';
import { BaseInventoryLabelStackContentComponent } from './components/print-labels/label-content/custom-labels-2x4/base-inventory-label-stack-content/base-inventory-label-stack-content.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { IsOnRegularPaperPipe } from './pipes/is-regular-paper.pipe';
import { IsOnLaserLabelPaperPipe } from './pipes/is-on-laser-label-paper.pipe';
import { DougArBusinessCardStackBackgroundComponent } from './components/cards/backgrounds/business-cards-2x3/doug-ar-business-card-stack-background/doug-ar-business-card-stack-background.component';
import { DougArBusinessCardStackContentComponent } from './components/cards/card-content/business-cards-2x3/doug-ar-business-card-stack-content/doug-ar-business-card-stack-content.component';
import { NycdBusinessCardStackBackgroundComponent } from './components/cards/backgrounds/business-cards-2x3/nycd-business-card-stack-background/nycd-business-card-stack-background.component';
import { NycdBusinessCardStackContentComponent } from './components/cards/card-content/business-cards-2x3/nycd-business-card-stack-content/nycd-business-card-stack-content.component';
import { NycdBusinessPriceComponent } from './components/cards/card-content/business-cards-2x3/nycd-business-card-stack-content/nycd-business-price/nycd-business-price.component';
import { NycdIndexCardStackContentComponent } from './components/cards/card-content/index-cards-3x5/nycd-index-card-stack-content/nycd-index-card-stack-content.component';
import { NycdIndexCardStackBackgroundComponent } from './components/cards/backgrounds/index-cards-3x5/nycd-index-card-stack-background/nycd-index-card-stack-background.component';
import { NycdIndexPriceComponent } from './components/cards/card-content/index-cards-3x5/nycd-index-card-stack-content/nycd-index-price/nycd-index-price.component';
import { StiiizyCardStackContentComponent } from './components/cards/card-content/business-cards-2x3/stiiizy-card-stack-content/stiiizy-card-stack-content.component';
import { StiiizyProductInformationComponent } from './components/cards/card-content/business-cards-2x3/stiiizy-card-stack-content/stiiizy-product-information/stiiizy-product-information.component';
import { StiiizySideBarComponent } from './components/cards/card-content/business-cards-2x3/stiiizy-card-stack-content/stiiizy-side-bar/stiiizy-side-bar.component';
import { GanjikaBeverageAddressCardStackBackgroundComponent } from './components/cards/backgrounds/address-cards-1x3/ganjika-beverage-address-card-stack-background/ganjika-beverage-address-card-stack-background.component';
import { GanjikaBeverageAddressCardStackContentComponent } from './components/cards/card-content/address-cards-1x3/ganjika-beverage-address-card-stack-content/ganjika-beverage-address-card-stack-content.component';
import { FikaEdiblesIndexCardStackBackgroundComponent } from './components/cards/backgrounds/index-cards-3x5/fika-edibles-index-card-stack-background/fika-edibles-index-card-stack-background.component';
import { FikaEdiblesIndexCardStackContentComponent } from './components/cards/card-content/index-cards-3x5/fika-edibles-index-card-stack-content/fika-edibles-index-card-stack-content.component';
import { FikaIndexCardContentComponent } from './components/cards/card-content/index-cards-3x5/fika-index-card-stack-content/fika-index-card-content/fika-index-card-content.component';
import { FikaSpoofedGridColViewModelsPipe } from './components/cards/card-content/index-cards-3x5/fika-index-card-stack-content/fika-size-and-price/fika-spoofed-grid-col-view-models.pipe';
import { FireAndFlowerFiveBySevenBackgroundComponent } from './components/cards/backgrounds/custom-cards-5x7/fire-and-flower-five-by-seven-background/fire-and-flower-five-by-seven-background.component';
import { FireAndFlowerShelfTalkerPostCardBackgroundComponent } from './components/cards/backgrounds/post-cards-4x6/fire-and-flower-shelf-talker-post-card-background/fire-and-flower-shelf-talker-post-card-background.component';
import { FireAndFlower5By7ShelfTalkerContentComponent } from './components/cards/card-content/custom-cards-5x7/fire-and-flower-5-by-7-shelf-talker-content/fire-and-flower-5-by-7-shelf-talker-content.component';
import { FireAndFlowerShelfTalkerPostCardContentComponent } from './components/cards/card-content/post-cards-4x6/fire-and-flower-shelf-talker-post-card-content/fire-and-flower-shelf-talker-post-card-content.component';
import { FireAndFlowerShelfTalkerContentComponent } from './components/cards/card-content/shared/components/fire-and-flower-shelf-talker-content/fire-and-flower-shelf-talker-content.component';
import { RxPush } from '@rx-angular/template/push';
import { RxIf } from '@rx-angular/template/if';
import { RxFor } from '@rx-angular/template/for';
import { FireAndFlowerShelfTalkerItemComponent } from './components/cards/card-content/shared/components/fire-and-flower-shelf-talker-content/fire-and-flower-shelf-talker-item/fire-and-flower-shelf-talker-item.component';
import { FireAndFlowerBrandFallbackPipe } from './components/cards/card-content/shared/components/fire-and-flower-shelf-talker-content/fire-and-flower-shelf-talker-item/fire-and-flower-brand-fallback.pipe';
import { VariantGroupedByBrandMethodPipe } from './components/cards/pipes/variant-grouped-by-brand-method.pipe';

@NgModule({
  declarations: [
    CardContentInflatorComponent,
    PrintCardBackgroundInflatorComponent,
    RenderCardsComponent,
    CardGridComponent,
    PrintCardGridManagerDirective,
    CardJigComponent,
    RenderCardBackgroundComponent,
    PrintCardMenuBasicMethodPipe,
    WithContentBleedPipe,
    IsOnPerforatedPaperPipe,
    PrintCardTopCutLinesComponent,
    PrintCardLeftCutLinesComponent,
    PrintCardRightCutLinesComponent,
    PrintCardBottomCutLinesComponent,
    IterableCardStackColumnsPipe,
    CardContentWidthPipe,
    CardContentHeightPipe,
    IterableCardStackRowsPipe,
    RenderCardPreviewComponent,
    IsExtendedCutLinePipe,
    CardPreviewWidthInPxPipe,
    CardPreviewHeightInPxPipe,
    CustomTopPaperMarginInPxPipe,
    VerticalCardStackJustificationPipe,
    CardPreviewHeightInPxPipe,
    GenericCardStackBackgroundComponent,
    GenericCardStackContentComponent,
    PrintCardBadgesPipe,
    PrintCardBackgroundImageWithColorOverlayAndOpacityComponent,
    PopsCannabis5By5CardBackgroundComponent,
    PopsCannabis5By5CardStackContentComponent,
    LeftCutLineTextPipe,
    CutLineExtendedLengthInPxPipe,
    CalculateExtendedCutLineLengthStringPipe,
    RenderPrintCardLiveViewComponent,
    DougArBusinessCardStackBackgroundComponent,
    DougArBusinessCardStackContentComponent,
    DougBusinessCardStackBackgroundComponent,
    DougBusinessCardStackContentComponent,
    DougIndexCardStackContentComponent,
    DougPostCardStackBackgroundComponent,
    DougPostCardStackContentComponent,
    PopsCannabisPriceComponent,
    LinkWidthDirective,
    RenderPrintCardLiveViewComponent,
    PlainJanePostCardStackContentComponent,
    PlainJanePostCardStackBackgroundComponent,
    PlainJaneIndexCardStackBackgroundComponent,
    PlainJaneIndexCardStackContentComponent,
    PlainJaneBusinessCardStackBackgroundComponent,
    PlainJaneBusinessCardStackContentComponent,
    OGKushBusinessCardStackContentComponent,
    OGKushIndexCardStackContentComponent,
    OGKushPostCardStackContentComponent,
    CardHeaderUnitSizePipe,
    MissJonesCardStackBackgroundComponent,
    MissJonesPostCardStackContentComponent,
    AgentOrangeIndexCardStackBackgroundComponent,
    AgentOrangeIndexCardStackContentComponent,
    AgentOrangePostCardStackBackgroundComponent,
    AgentOrangePostCardStackContentComponent,
    TrueNorthPortraitCardStackBackgroundComponent,
    TrueNorthPortraitCardStackContentComponent,
    TrueNorthLandscapeCardStackBackgroundComponent,
    TrueNorthLandscapeCardStackContentComponent,
    PrintCardInternalGridCutLinesComponent,
    GetNInternalGridCrossesPipe,
    InternalGridCutLineCrossComponent,
    GetInternalCrossDimensionPipe,
    GetInternalGridCutLineCrossTopOffsetPipe,
    GetInternalGridCutLineCrossLeftOffsetPipe,
    FikaIndexCardStackBackgroundComponent,
    FikaIndexCardStackContentComponent,
    FikaBeverageAddressCardStackBackgroundComponent,
    FikaBeverageAddressCardStackContentComponent,
    GanjikaBeverageAddressCardStackBackgroundComponent,
    GanjikaBeverageAddressCardStackContentComponent,
    JaneDoeAddressCardStackBackgroundComponent,
    JaneDoeAddressCardStackContentComponent,
    JaneDoeBusinessCardStackBackgroundComponent,
    JaneDoeBusinessCardStackContentComponent,
    JaneDoePostCardStackContentComponent,
    JaneDoePostCardStackBackgroundComponent,
    SessionsPostCardStackBackgroundComponent,
    SessionsPostCardStackContentComponent,
    SessionsPriceComponent,
    FikaSizeAndPriceComponent,
    GetSalePriceWasTextPipe,
    GetFikaGridSizePipe,
    NVariantsAreOnSalePipe,
    HoneyHazeBusinessCardStackContentComponent,
    FireAndFlowerCardStackBackgroundComponent,
    FireAndFlowerCardStackContentComponent,
    FireAndFlowerDefaultCardStackBackgroundComponent,
    FireAndFlowerDefaultCardStackContentComponent,
    FireAndFlowerSaleCardStackBackgroundComponent,
    FireAndFlowerSaleCardStackContentComponent,
    FireAndFlowerMemberCardStackBackgroundComponent,
    FireAndFlowerMemberCardStackContentComponent,
    PlantlifeBusinessCardStackContentComponent,
    FikaAccessoriesIndexCardStackContentComponent,
    FikaAccessoriesIndexCardBackgroundComponent,
    NycdBusinessCardStackBackgroundComponent,
    NycdBusinessCardStackContentComponent,
    NycdBusinessPriceComponent,
    NycdIndexCardStackBackgroundComponent,
    NycdIndexCardStackContentComponent,
    NycdIndexPriceComponent,
    PrintCardScaleDownTextDirective,
    PrintCardDescriptionContainerComponent,
    BaseInventoryLabelStackBackgroundComponent,
    BaseInventoryLabelStackContentComponent,
    IsOnRegularPaperPipe,
    IsOnLaserLabelPaperPipe,
    StiiizyCardStackContentComponent,
    StiiizyProductInformationComponent,
    StiiizySideBarComponent,
    FikaEdiblesIndexCardStackBackgroundComponent,
    FikaEdiblesIndexCardStackContentComponent,
    FikaIndexCardContentComponent,
    FikaSpoofedGridColViewModelsPipe,
    FireAndFlowerFiveBySevenBackgroundComponent,
    FireAndFlowerShelfTalkerPostCardBackgroundComponent,
    FireAndFlower5By7ShelfTalkerContentComponent,
    FireAndFlowerShelfTalkerPostCardContentComponent,
    FireAndFlowerShelfTalkerContentComponent,
    FireAndFlowerShelfTalkerItemComponent,
    FireAndFlowerBrandFallbackPipe,
    VariantGroupedByBrandMethodPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PrintCardsRoutingModule,
    NgStringPipesModule,
    ScaleDownTextModule,
    InflatorModule,
    ScaleDownTextAndAdjustLinesModule,
    NgxBarcode6Module,
    RxPush,
    RxIf,
    RxFor
  ],
  exports: [
    RenderPrintCardLiveViewComponent
  ]
})
export class PrintCardsModule { }
