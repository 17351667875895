import { Pipe, PipeTransform } from '@angular/core';
import { VariantAssetService } from '../../services/variant-asset-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortUtils } from '../../../utils/sort-utils';
import type { VariantAsset } from '../../../models/image/dto/variant-asset';
import type { ShelfTalkerCardData } from '../../../models/shelf-talkers/shelf-talker-card-data';
import type { VariantsGroupedByBrand } from '../../print-cards/models/variants-grouped-by-brand';

@Pipe({
  name: 'getVariantAssetForShelfTalker'
})
export class GetVariantAssetForShelfTalkerPipe implements PipeTransform {

  constructor(
    protected variantAssetService: VariantAssetService
  ) {
  }

  transform(
    shelfTalkerCardData: ShelfTalkerCardData,
    variantsGroupedByBrand: VariantsGroupedByBrand
  ): Observable<VariantAsset> {
    const variants = variantsGroupedByBrand?.variants ?? [];
    return this.variantAssetService.variantAssetMap$.pipe(
      map(variantAssetMap => {
        const variantAssets = variants
          ?.map(variant => variantAssetMap?.get(variant?.id))
          ?.flatten<VariantAsset[]>()
          ?.filterNulls();
        const sortOrder = shelfTalkerCardData?.menu?.variantAssetTypeSortOrder();
        const byAssetOrder = (a: VariantAsset, b: VariantAsset) => SortUtils.variantAssetLibrarySort(a, b, sortOrder);
        const sortedVariantAssets = variantAssets?.sort(byAssetOrder);
        return sortedVariantAssets?.firstOrNull();
      })
    );
  }

}
