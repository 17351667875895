import { PostCardStack } from './post-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { TrueNorthPortraitCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/post-cards-4x6/true-north-post-card-stack-content/true-north-portrait-card-stack-content/true-north-portrait-card-stack-content.component';
import { TrueNorthPortraitCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/post-cards-4x6/true-north-portrait-card-stack-background/true-north-portrait-card-stack-background.component';

export class TrueNorthPortraitCardStack extends PostCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return TrueNorthPortraitCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return TrueNorthPortraitCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
