/* src/app/modules/print-cards/components/cards/backgrounds/post-cards-4x6/true-north-landscape-card-stack-background/true-north-landscape-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0.625rem;
  box-sizing: border-box;
}
:host .card-left-edge {
  background-color: #000000;
  width: 5.7%;
  height: 100%;
  border-radius: 1rem 0 0 1rem;
}
:host .main-content {
  flex: 1;
  border-radius: 0 1rem 1rem 0;
}
:host .main-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
:host .main-content ::ng-deep .image-with-color-overlay-and-opacity {
  border-radius: 0 1rem 1rem 0;
}
/*# sourceMappingURL=true-north-landscape-card-stack-background.component.css.map */
