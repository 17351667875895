@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/business-cards-2x3/stiiizy-card-stack-content/stiiizy-product-information/stiiizy-product-information.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  display: contents;
}
.stiiizy-product-information {
  position: relative;
  flex: 1;
  font-size: calc(0.75rem * 1.6);
  font-family: Archivo, sans-serif;
  font-weight: 400;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stiiizy-product-information .background-content {
  position: absolute;
  inset: 0;
  z-index: -1;
}
.stiiizy-product-information .background-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
.stiiizy-product-information .top-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stiiizy-product-information .top-content .brand-logo {
  max-width: 80%;
  max-height: 3.5rem;
}
.stiiizy-product-information .top-content .brand-logo.shrink-brand-logo {
  max-height: 3.125rem;
}
.stiiizy-product-information .middle-content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
}
.stiiizy-product-information .middle-content .product-card-variant-title {
  font-family: Archivo, sans-serif;
  font-weight: 900;
  font-size: calc(0.74rem * 1.6);
  line-height: calc(0.74rem * 1.6);
  letter-spacing: -0.33px;
  text-transform: uppercase;
  text-align: center;
}
.stiiizy-product-information .middle-content .product-card-variant-title.shrink {
  font-size: calc(0.6875rem * 1.6);
  line-height: calc(0.6875rem * 1.6);
}
.stiiizy-product-information .middle-content .cannabinoids {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.25rem;
  row-gap: 0.125rem;
}
.stiiizy-product-information .middle-content .cannabinoids .product-cannabinoid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: max-content;
  column-gap: 0.125rem;
  text-transform: uppercase;
  font-family: Archivo, sans-serif;
  font-weight: 700;
  font-size: calc(0.5rem * 1.6);
  line-height: 100%;
  letter-spacing: -0.21px;
}
.stiiizy-product-information .middle-content .cannabinoids .product-cannabinoid span {
  font-family: Archivo, sans-serif;
  font-weight: 900;
}
.stiiizy-product-information .middle-content .size-and-label {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: Archivo, sans-serif;
  font-weight: 700;
  font-size: calc(0.5rem * 1.6);
}
.stiiizy-product-information .middle-content .size-and-label .product-card-size {
  width: max-content;
  white-space: nowrap;
}
.stiiizy-product-information .middle-content .size-and-label ::ng-deep .product-label {
  font-family: Archivo, sans-serif;
  font-weight: 700;
  font-size: calc(0.4rem * 1.6);
  margin-left: 0;
  padding: 0.05rem 0.125rem;
  border-radius: 0.125rem;
}
.stiiizy-product-information .bottom-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.stiiizy-product-information .bottom-content .product-card-price {
  position: relative;
}
.stiiizy-product-information .bottom-content .product-card-price .badge-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translate(calc(-100% - 0.5rem), 0);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.125rem;
  justify-content: center;
  align-items: center;
}
.stiiizy-product-information .bottom-content .product-card-price .badge-container .badge {
  height: calc(0.875rem * 1.6);
}
.stiiizy-product-information .bottom-content .product-card-price ::ng-deep .product-variant-price {
  position: relative;
  font-family: Archivo, sans-serif;
  font-weight: 800;
  font-size: calc(0.875rem * 1.6);
  letter-spacing: -0.42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  line-height: 100%;
  overflow: visible;
}
.stiiizy-product-information .bottom-content .product-card-price ::ng-deep .product-variant-price .product-variant-previous-price {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(calc(100% + 0.5rem), 50%);
  font-family: Archivo, sans-serif;
  font-weight: 800;
  font-size: calc(0.4375rem * 1.6);
  letter-spacing: -0.21px;
  opacity: 0.5;
  line-height: 100%;
  overflow: visible;
}
.stiiizy-product-information ::ng-deep .product-column-general {
  overflow-x: visible !important;
}
.stiiizy-product-information ::ng-deep .force-bold-style * {
  font-family: Archivo, sans-serif;
  font-weight: 700;
}
.stiiizy-product-information ::ng-deep .force-italic-style * {
  font-style: italic !important;
}
/*# sourceMappingURL=stiiizy-product-information.component.css.map */
