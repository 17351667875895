import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { NycdBusinessCardStackContentViewModel } from './nycd-business-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';

@Component({
  selector: 'app-nycd-business-card-stack-content',
  templateUrl: './nycd-business-card-stack-content.component.html',
  styleUrls: ['./nycd-business-card-stack-content.component.scss'],
  providers: [NycdBusinessCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NycdBusinessCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: NycdBusinessCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
