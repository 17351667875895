import { BusinessCardStack } from './business-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { NycdBusinessCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/business-cards-2x3/nycd-business-card-stack-background/nycd-business-card-stack-background.component';
import { NycdBusinessCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/business-cards-2x3/nycd-business-card-stack-content/nycd-business-card-stack-content.component';

export class NycdBusinessCardStack extends BusinessCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return NycdBusinessCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return NycdBusinessCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
