import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'isOnLaserLabelPaper'
})
export class IsOnLaserLabelPaperPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper): boolean {
    return cardsThatLayoutOntoPaper?.isOnLaserLabelPaper() || false;
  }

}
