@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/post-cards-4x6/agent-orange-post-card-stack-content/agent-orange-post-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
:host .main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 0.5rem;
  width: 75%;
}
:host .main-content .product-card-brand {
  font-family: "Metropolis", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
  box-sizing: border-box;
  width: 100%;
}
:host .main-content .product-card-name {
  font-family: "Metropolis", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.5rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
  box-sizing: border-box;
  width: 100%;
}
:host .main-content .product-label-badge-size-unit-qty-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  vertical-align: middle;
  padding: 1rem 0 1.5rem;
}
:host .main-content .product-label-badge-size-unit-qty-container .print-card-label {
  align-self: flex-start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .product-label-badge-size-unit-qty-container .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 600;
  width: fit-content;
  display: block;
  max-width: 12rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
:host .main-content .product-label-badge-size-unit-qty-container .print-card-label ::ng-deep .product-flag-label {
  border-radius: unset;
}
:host .main-content .product-label-badge-size-unit-qty-container .print-card-label ::ng-deep .flag-body {
  display: block;
  max-width: 10rem;
  justify-content: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
:host .main-content .product-label-badge-size-unit-qty-container .product-card-badge-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.25rem;
}
:host .main-content .product-label-badge-size-unit-qty-container .product-card-badge-container .product-card-badge {
  width: 1.875rem;
}
:host .main-content .product-label-badge-size-unit-qty-container .product-card-size {
  font-family: "Metropolis", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  align-self: center;
}
:host .main-content .product-card-cannabinoid-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 2rem;
  color: #777777;
}
:host .main-content .product-card-cannabinoid-container .property-container {
  display: flex;
  flex-direction: column;
}
:host .main-content .product-card-cannabinoid-container .property-container .title {
  font-family: "Metropolis", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
}
:host .main-content .product-card-cannabinoid-container .property-container .value {
  font-family: "Metropolis", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
}
:host .main-content .pricing-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: row;
  font-family: "Metropolis", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 2.5rem;
  height: 100%;
  gap: 0.5rem;
}
:host .main-content .pricing-container ::ng-deep .product-variant-price .product-variant-current-price,
:host .main-content .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}
:host .main-content .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 2.5rem;
}
:host .main-content .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 2rem;
  color: rgba(68, 68, 68, 0.5019607843);
}
:host .color-strip {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
:host .color-strip .product-card-image {
  display: block;
  object-fit: contain;
  width: 7.75rem;
}
/*# sourceMappingURL=agent-orange-post-card-stack-content.component.css.map */
