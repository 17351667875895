import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { map } from 'rxjs/operators';
import { exists } from '../../../../../../../functions/exists';
import { Observable } from 'rxjs';
import { JaneDoeCardData } from '../../../../../../../models/print-cards/jane-doe-card-data';

@Injectable()
export class JaneDoeBusinessCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<JaneDoeCardData>;

  public readonly quantityAndSizeText$ = this.cardData$.pipe(
    map(cardData => cardData?.getQuantityAndSizeString())
  );

  public cannabinoidTextColor$ = this.cardData$.pipe(
    map(cardData => {
      const productsInfoBackgroundColor = cardData?.section?.metadata?.productsInfoBackgroundColor;
      return exists(productsInfoBackgroundColor) ? productsInfoBackgroundColor : cardData?.getCardBorderColor();
    })
  );

  public readonly bottomText$ = this.cardData$.pipe(
    map(cardData => cardData?.getBottomText())
  );

  public readonly cardBorderColor$ = this.cardData$.pipe(
    map(cardData => cardData?.getCardBorderColor())
  );

}
