<div class="description-container">
  <ng-container
    *ngIf="
      (cardData | sectionRowViewModelBasicMethod: 'showFullDescription') &&
        (cardData | sectionRowViewModelBasicMethod: 'getRichTextDescription') as richTextDescription;
      else descTemplate
    ">
    <div class="description rich-text" [style.color]="bodyTextColor" [innerHTML]="richTextDescription"></div>
  </ng-container>
  <ng-template #descTemplate>
    <ng-container *ngIf="cardData | sectionRowViewModelBasicMethod: 'getProductDesc' as productDesc">
      <div class="description plain-text" [style.color]="bodyTextColor">
        {{ productDesc }}
      </div>
    </ng-container>
  </ng-template>
</div>
