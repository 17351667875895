import { Injectable } from '@angular/core';
import { CardBackgroundViewModel } from '../../card-background-view-model';
import { combineLatest, Observable } from 'rxjs';
import { JaneDoeCardData } from '../../../../../../../models/print-cards/jane-doe-card-data';
import { map } from 'rxjs/operators';
import { CardStack } from '../../../../../../../models/menu/section/card-stacks/card-stack';

@Injectable()
export class JaneDoeBusinessCardStackBackgroundViewModel extends CardBackgroundViewModel {

  static readonly ColorBorderThicknessInPx = 8;
  static readonly ColorHeaderFooterThicknessInPx = 18;

  public readonly cardData$: Observable<JaneDoeCardData>;

  public borderAdjustmentWidth$ = combineLatest([
    this.cardStack$,
    this.preview$
  ]).pipe(
    map(([cardStack, preview]) => {
      const bleed = (cardStack?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH;
      const additionalHeight = cardStack?.isOnRegularPaper() && !preview ? bleed : 0;
      const borderThickness = JaneDoeBusinessCardStackBackgroundViewModel.ColorBorderThicknessInPx;
      return `calc(100% - ${additionalHeight * 2}px - ${borderThickness * 2}px)`;
    })
  );

  public borderAdjustmentHeight$ = combineLatest([
    this.cardStack$,
    this.preview$
  ]).pipe(
    map(([cardStack, preview]) => {
      const bleed = (cardStack?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH;
      const additionalHeight = cardStack?.isOnRegularPaper() && !preview ? bleed : 0;
      const borderThickness = JaneDoeBusinessCardStackBackgroundViewModel.ColorBorderThicknessInPx;
      const headerFooterThickness = JaneDoeBusinessCardStackBackgroundViewModel.ColorHeaderFooterThicknessInPx;
      return `calc(100% - ${additionalHeight * 2}px - ${borderThickness * 2}px - ${headerFooterThickness * 2}px)`;
    })
  );

  public borderColor$ = this.cardData$.pipe(
    map(cardData => cardData?.getCardBorderColor())
  );

}
