import { Deserializable } from '../../protocols/deserializable';
import type { Orientation } from '../../enum/dto/orientation.enum';
import type { CardSize } from '../../enum/dto/card-size';

export class PrintConfig implements Deserializable {

  public marginBottom: number;
  public marginLeft: number;
  public marginRight: number;
  public marginTop: number;
  public paperSizes: string[];
  public orientations: string[];
  public cardOrientationMap: Map<CardSize, Orientation>;
  public gridCountMap: Map<CardSize, number>;

  onDeserialize() {
    this.cardOrientationMap = window?.injector?.Deserialize.genericMap(this.cardOrientationMap);
    this.gridCountMap = window?.injector?.Deserialize.genericMap(this.gridCountMap);
  }

}
