import { ChangeDetectionStrategy, Component, ElementRef, EnvironmentInjector, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { InflatorComponent } from '../../../../../inflator/inflator.component';
import { CardBackgroundComponent } from '../../backgrounds/card-background.component';
import { CardBackground } from '../../../../../../models/protocols/card-background';
import { CardBackgroundInterface } from '../../../../interface/card-background-interface';
import { CardData } from '../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-print-card-background-inflator',
  templateUrl: './print-card-background-inflator.component.html',
  styleUrls: ['./print-card-background-inflator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardBackgroundInflatorComponent extends InflatorComponent
  implements CardBackgroundInterface, OnChanges {

  constructor(
    public elementRef: ElementRef,
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() cardBackground: CardBackground;
  @Input() preview: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardBackground) this.connectToComponentType(this.getBackgroundType());
    super.ngOnChanges(changes);
  }

  protected getBackgroundType(): Type<CardBackgroundComponent> | null {
    if (this.cardBackground instanceof CardData) {
      return this.cardBackground?.getLayoutInstructions()?.getCardBackgroundComponent(this.cardBackground);
    }
    return null;
  }

}
