/* src/app/modules/print-cards/components/cards/backgrounds/print-card-background-image-with-color-overlay-and-opacity/print-card-background-image-with-color-overlay-and-opacity.component.scss */
:host {
  display: contents;
}
:host .component-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}
:host .image-with-color-overlay-and-opacity {
  position: absolute;
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=print-card-background-image-with-color-overlay-and-opacity.component.css.map */
