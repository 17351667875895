import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { UnitOfMeasure } from '../../enum/dto/unit-of-measure.enum';
import { NumberUtils } from '../../../utils/number.utils';

export const PRICING_TIER_UPPER_LIMIT = 9999;

export class VariantPricingTier implements Deserializable, UniquelyIdentifiable {

  public name: string;
  public startWeight: number;   // Lower bounded weight for pricing tier
  public startQuantity: number; // Quantity of BaseVariant (StartWeight/VariantDTO.UnitSize)
  public endWeight: number;     // Upper bounded weight for pricing tier
  public endQuantity: number;   // Quantity of BaseVariant (StartWeight/VariantDTO.UnitSize)
  public price: number;         // Indicates price per unit/uom
  public isDiscountable: boolean;

  onDeserialize() {
  }

  /**
   * This functionality directly matches what is implemented on the API.
   * The selected values are validated on the API, so it is important that
   * this is only changed in conjunction with the API
   */
  getGridColumnName(useWeight: boolean, uom: UnitOfMeasure): string {
    if (useWeight && (this.startWeight > 0 || this.endWeight > 0)) {
      // Return column name using Weight
      if (this.endWeight === PRICING_TIER_UPPER_LIMIT) {
        return `${NumberUtils.formatToSigFigDecimals(this.startWeight)}${uom}+`;
      } else {
        const startWeight = NumberUtils.formatToSigFigDecimals(this.startWeight);
        const endWeight = NumberUtils.formatToSigFigDecimals(this.endWeight);
        return `${startWeight}-${endWeight}${uom}`;
      }
    } else {
      // Default to using quantity
      if (this.endQuantity === PRICING_TIER_UPPER_LIMIT) {
        return `${NumberUtils.formatToSigFigDecimals(this.startQuantity)}+`;
      } else {
        const startQty = NumberUtils.formatToSigFigDecimals(this.startQuantity);
        const endQty = NumberUtils.formatToSigFigDecimals(this.endQuantity);
        return `${startQty}-${endQty}`;
      }
    }
  }

  is1Gram(): boolean {
    return this.startWeight >= 0.85 && this.endWeight <= 1.35;
  }

  is3Point5Grams(): boolean {
    return this.startWeight >= 3.45 && this.endWeight <= 3.85;
  }

  is7Grams(): boolean {
    return this.startWeight >= 7 && this.endWeight <= 7.5;
  }

  is14Grams(): boolean {
    return this.startWeight >= 14 && this.endWeight <= 14.5;
  }

  is28Grams(): boolean {
    return this.startWeight >= 28 && this.endWeight <= 28.5;
  }

  /**
   * Support flower sizes: 1g, 3.5g (1/8 oz), 7g (1/4 oz), 14g (1/2 oz), and 28g (1 oz).
   */
  getFlowerGridOunceColumnName(useWeight: boolean): string | null {
    switch (useWeight) {
      case this.is1Gram():        return '1 g';   // 1 g
      case this.is3Point5Grams(): return '3.5 g'; // 1/8 oz
      case this.is7Grams():       return '7 g';   // 1/4 oz
      case this.is14Grams():      return '14 g';  // 1/2 oz
      case this.is28Grams():      return '28 g';  // 1 oz
      default:                    return null;
    }
  }

  classicFlowerPricing(): number | null {
    if (!this.price) return null;
    const calculateOuncesPrice = (grams: number) => NumberUtils.roundToTwoDecimalPlaces(this.price * grams);
    switch (true) {
      case this.is1Gram():        return calculateOuncesPrice(1);   // 1 g
      case this.is3Point5Grams(): return calculateOuncesPrice(3.5); // 1/8 oz
      case this.is7Grams():       return calculateOuncesPrice(7);   // 1/4 oz
      case this.is14Grams():      return calculateOuncesPrice(14);  // 1/2 oz
      case this.is28Grams():      return calculateOuncesPrice(28);  // 1 oz
      default:                    return null;
    }
  }

  getUniqueIdentifier(): string {
    return `
      -${this.name}
      -${this.startWeight}
      -${this.startQuantity}
      -${this.endWeight}
      -${this.endQuantity}
      -${this.price}
      -${this.isDiscountable}
    `;
  }

}
