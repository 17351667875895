import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'calculateExtendedCutLineLengthString'
})
export class CalculateExtendedCutLineLengthStringPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper, addBleed: number = 0): string {
    const extendedLength = cardsThatLayoutOntoPaper?.extendCutLineIntoContentAreaByNPx() ?? 0;
    return `calc(100% + ${extendedLength}px + ${addBleed}px)`;
  }

}
