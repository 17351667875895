import { Section } from './section';
import type { SectionSortOption } from '../../enum/dto/section-sort-option.enum';
import { SectionLayoutType } from '../../enum/dto/section-layout-type.enum';
import type { Variant } from '../../product/dto/variant';
import { Product } from '../../product/dto/product';
import { VariantBadge } from '../../product/dto/variant-badge';
import type { ColWidth } from '../../shared/col-width';
import type { Menu } from '../menu';
import type { CompanyConfiguration } from '../../company/dto/company-configuration';
import type { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { MenuStyle } from '../dto/menu-style';
import { SectionColumnConfig, SectionColumnConfigFontStyle, SectionColumnConfigKey, SectionColumnConfigProductInfoKey, SectionColumnConfigState } from './section-column-config';
import type { ProductMenu } from '../product-menu';
import { SortUtils } from '../../../utils/sort-utils';
import type { LocationConfiguration } from '../../company/dto/location-configuration';
import type { SaleLabelFormat } from '../../enum/shared/sale-label-format.enum';
import { exists } from '../../../functions/exists';
import { VariantGroup } from '../../product/dto/variant-group';
import { SortVariantGroupUtils } from '../../../utils/sort-variant-group-utils';
import type { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { SortVariantsInSectionUtils } from '../../../utils/sort-variants-in-section-utils';
import { DisplayableMenuItem } from '../../protocols/displayable-menu-item';
import { Type } from '@angular/core';
import type { MenuItemComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-item/menu-item.component';
import { ProductSectionItemComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-item/product-section-item/product-section-item.component';
import { SectionUtils } from '../../../utils/section-utils';
import { MenuUtils } from '../../../utils/menu-utils';
import { StringUtils } from '../../../utils/string-utils';
import { SectionColumnProductInfoType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { Cannabinoid } from '../../enum/shared/cannabinoid';

export class SectionWithProducts extends Section implements DisplayableMenuItem {

  public rowCount: number;
  public productIds: string[];
  public products: Product[] = [];
  public showZeroStockItems: boolean;
  public sorting: SectionSortOption;
  public secondarySorting: SectionSortOption;
  public layoutType: SectionLayoutType;
  public enabledVariantIds: string[];
  public originalVariants: Variant[] = [];
  public variantBadgeIdsMap: Map<string, string[]>;
  public variantBadgeMap: Map<string, VariantBadge[]>;
  public columnConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>;
  public saleLabelFormat: SaleLabelFormat;
  // Not returned from api
  public empty: boolean = false;
  // This contains all variants, not just the visible variants. See getScopedVisibleVariants() for visible variants
  public variants: Variant[] = [];
  // Do not set this directly. It gets set from the overflow calculator.
  public collapseContent: boolean = false;
  // Forces a specific height so the section doesn't bounce around when using section transitions.
  // Set by the overflow calculator when menu.isSectionLevelOverflow() is true.
  public fixedHeightInPx: number;
  // Not returned from api, set by menu onDeserialize
  public enabledSecondaryCannabinoids: Cannabinoid[];
  // Not returned from api, set by menu onDeserialize
  public enabledTerpenesPascalCased: string[];

  public onDeserialize() {
    super.onDeserialize();
    this.productIds = Array.from(this.productIds || []);
    this.enabledVariantIds = Array.from(this.enabledVariantIds || []);
    this.removeRowCountIfNegativeOne();
    this.deserializeProducts();
    this.variantBadgeIdsMap = window?.injector?.Deserialize?.genericArrayMap(this.variantBadgeIdsMap);
    this.variantBadgeMap = window?.injector?.Deserialize?.typedArrayMapOf(VariantBadge, this.variantBadgeMap);
    this.setOriginalVariants();
    this.deserializeColumnConfig();
  }

  protected removeRowCountIfNegativeOne() {
    if (this.rowCount === -1) this.rowCount = undefined;
  }

  protected deserializeProducts() {
    this.products = window?.injector?.Deserialize?.arrayOf(Product, this.products);
  }

  /**
   * Original variants are a shallow copy of the data instead of a deep copy. This cuts down the amount of memory
   * needed to store variants by a significant amount (it's also faster). This is important because we can have
   * hundreds of variants on sections.
   */
  protected setOriginalVariants() {
    this.variants = this?.products?.map(it => (it?.variants || [])).flatten();
    this.originalVariants = [...(this.variants || [])];
  }

  protected deserializeColumnConfig() {
    if (!this.columnConfig) {
      this.columnConfig = new Map<SectionColumnConfigKey, SectionColumnConfig>();
    }
    if (!(this.columnConfig instanceof Map)) {
      this.columnConfig = window?.injector?.Deserialize?.mapOf(SectionColumnConfig, this.columnConfig);
    }
  }

  protected override getCombineWithTemplateDataShortCircuitedProperties(): string[] {
    const list = super.getCombineWithTemplateDataShortCircuitedProperties();
    const ignore = [
      'productIds', 'products',
      'enabledVariantIds', 'variants', 'originalVariants',
      'variantBadgeIdsMap', 'variantBadgeMap',
      'columnConfig',
      'empty',
      'collapseContent',
      'fixedHeightInPx',
    ];
    return list?.filter(key => !ignore.includes(key)) || [];
  }

  protected override mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate: Section): void {
    super.mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate);
    if (SectionUtils.isSectionWithProducts(sectionTemplate)) {
      this.mergeProductsFromTemplateSection(sectionTemplate);
      this.mergeVariantsFromTemplateSection(sectionTemplate);
      this.mergeVariantBadgeMapFromTemplateSection(sectionTemplate);
      this.mergeColumnConfigFromTemplateSection(sectionTemplate);
    }
  }

  /**
   * Combines the products from the section and template together.
   * The result contains a unique list of products.
   */
  protected mergeProductsFromTemplateSection(sectionTemplate: SectionWithProducts): void {
    this.productIds = [
      ...(this.productIds ?? []),
      ...(sectionTemplate?.productIds ?? [])
    ]?.unique();
    if (!this.products) this.products = [];
    sectionTemplate?.products?.forEach(sectionTemplateProduct => {
      const product = this.products?.find(p => p?.id === sectionTemplateProduct?.id);
      exists(product)
        ? product.mergeWithTemplateProduct(sectionTemplateProduct)
        : this.products.push(sectionTemplateProduct);
    });
  }

  /**
   * Combines the variants from the section and the template.
   * The result contains a unique list of variants.
   */
  protected mergeVariantsFromTemplateSection(sectionTemplate: SectionWithProducts): void {
    this.enabledVariantIds = [
      ...(this.enabledVariantIds ?? []),
      ...(sectionTemplate.enabledVariantIds ?? [])
    ]?.unique();
    this.variants = [
      ...(this.variants ?? []),
      ...(sectionTemplate.variants ?? [])
    ]?.uniqueByProperty('id');
    this.originalVariants = [
      ...(this.originalVariants ?? []),
      ...(sectionTemplate.originalVariants ?? [])
    ]?.uniqueByProperty('id');
  }

  /**
   * Combines the variantBadgeMap from the section and the template.
   * The result contains a unique list of badges for each variant id.
   */
  protected mergeVariantBadgeMapFromTemplateSection(sectionTemplate: SectionWithProducts): void {
    const variantBadgeIds = window.injector.Deserialize.genericMap(this.variantBadgeIdsMap) ?? new Map();
    sectionTemplate?.variantBadgeIdsMap?.forEach((value, key) => {
      const current = variantBadgeIds.get(key) ?? [];
      const combined = [...(current ?? []), ...(value ?? [])].unique();
      variantBadgeIds.set(key, combined);
    });
    this.variantBadgeIdsMap = variantBadgeIds;
    const varBadges = window.injector.Deserialize.typedArrayMapOf(VariantBadge, this.variantBadgeMap) ?? new Map();
    sectionTemplate?.variantBadgeMap?.forEach((value, key) => {
      const current = varBadges.get(key) ?? [];
      const combined = [...(current ?? []), ...(value ?? [])].uniqueByProperty('id');
      varBadges.set(key, combined);
    });
    this.variantBadgeMap = varBadges;
  }

  /**
   * Combines the columnConfig from the section and the template.
   * The section column config (if set) takes precedence over the template column config.
   */
  protected mergeColumnConfigFromTemplateSection(sectionTemplate: SectionWithProducts): void {
    const columnConfig = window.injector.Deserialize.mapOf(SectionColumnConfig, this.columnConfig) ?? new Map();
    sectionTemplate?.columnConfig?.forEach((value, key) => {
      if (!columnConfig?.get(key)) columnConfig.set(key, value);
    });
    this.columnConfig = columnConfig;
  }

  override resetOverflowMetaData() {
    super.resetOverflowMetaData();
    this.collapseContent = false;
    this.fixedHeightInPx = undefined;
  }

  getMenuItemType(): Type<MenuItemComponent> {
    return ProductSectionItemComponent;
  }

  public preRollVariantExists(product: Product): boolean {
    const preRollVariant = product.variants.find(v => {
      if (!this.enabledVariantIds.find(i => i === v.id)) {
        return false;
      }
      return v.variantType === 'Pre-Roll';
    });

    return !!preRollVariant;
  }

  public getActiveGridColumns(): string[] {
    return this.metadata?.gridColumnNames?.split(',')?.unique() ?? [];
  }

  public getActiveColumnsAsGridColumnComparisonStrings(): string[] {
    return this.getActiveGridColumns().map(StringUtils.gridColumnComparisonString);
  }

  generateGridLayoutColumnViewModels(menu: ProductMenu, widths: ColWidth[]): SectionColumnViewModel[] {
    const enabledColumns = this.getGridColumnNames();
    const colVms: SectionColumnViewModel[] = [];
    enabledColumns.forEach(columnTitle => {
      const priceColConfig = this.columnConfig?.get(SectionColumnConfigProductInfoKey.Price);
      const newColumn = new SectionColumnViewModel(this.layoutType, priceColConfig);
      newColumn.columnTitle = columnTitle;
      newColumn.columnType = SectionColumnProductInfoType.VariantPrice;
      newColumn.state = SectionColumnConfigState.On;
      newColumn.widthPercentage = widths
        ?.find(it => it.type === SectionColumnProductInfoType.VariantPrice)
        ?.widthPercentage;
      colVms.push(newColumn);
    });
    colVms.sort(SortUtils.columnViewModelByTitle);
    colVms.forEach((priceColumnViewModel, index) => {
      const variantPricePositionInteger = menu?.getColumnOrdering()?.get(SectionColumnProductInfoType.VariantPrice);
      const variantPricePositionDecimal = ((index + 1) / 100);
      priceColumnViewModel.position = variantPricePositionInteger + variantPricePositionDecimal;
    });
    return colVms;
  }

  isInLineMode(): boolean {
    return this.layoutType === SectionLayoutType.List;
  }

  isGridMode(): boolean {
    return this.layoutType === SectionLayoutType.Grid;
  }

  isChildVariantListMode(): boolean {
    return this.layoutType === SectionLayoutType.ChildVariantList;
  }

  isPricingTierGridMode(): boolean {
    return this.layoutType === SectionLayoutType.PricingTierGrid;
  }

  isClassicFlowerGridMode(): boolean {
    return this.layoutType === SectionLayoutType.ClassicFlowerGrid;
  }

  hasMultipleVariantsPerLineItem(): boolean {
    return this.isGridMode() || this.isPricingTierGridMode();
  }

  getGridColumnNames(): string[] {
    if (this.metadata && this.metadata.gridColumnNames) {
      return this.metadata.gridColumnNames.split(',');
    }
    return null;
  }

  showQuantityColumn(): boolean {
    const variantsWithPackageQuantityMoreThanOne = this.inStockVariantsWithPackageQuantityMoreThanOne();
    return variantsWithPackageQuantityMoreThanOne.length > 0;
  }

  inStockVariantsWithPackageQuantityMoreThanOne(): Variant[] {
    return this.originalVariants.orEmpty().filter(v => v.inventory.inStock()).filter(v => v.packagedQuantity > 1);
  }

  /**
   * Show size if any of the variants in the list fall under the specified product types.
   * Default product types are: Flower, Concentrate, Vape, Wellness, Oil
   */
  showSizeColumn(sectionRowViewModels: SectionRowViewModel[]): boolean {
    return sectionRowViewModels
      ?.map(sectionRowViewModel => sectionRowViewModel.getScopedVisibleVariants())
      ?.flatten<Variant[]>()
      ?.map(variant => variant?.getFormattedUnitSize())
      ?.unique()?.length > 1;
  }

  hasBadges(): boolean {
    if (this.originalVariants && this.originalVariants.length > 0) {
      const boolMap = this.originalVariants.map(it => it.hasBadges(this.variantBadgeMap));
      return boolMap.contains(true);
    } else {
      return false;
    }
  }

  /**
   * "Scoped visible" means that the variants returned are being displayed on the menu.
   */
  getScopedVisibleLineItemCount(
    menu: Menu,
    locationPriceStream: LocationPriceStream,
    sectionLevelOverflow: boolean
  ): number {
    switch (true) {
      case this.isChildVariantListMode(): {
        const forChildVariantList = this.getScopedVisibleVariantsForChildVariantList.bind(this);
        return forChildVariantList(menu, locationPriceStream, sectionLevelOverflow)?.length || 0;
      }
      case this.isGridMode(): {
        const forGridMode = this.getScopedVisibleVariantsForGridMode.bind(this);
        return forGridMode(menu, locationPriceStream, sectionLevelOverflow)?.length || 0;
      }
      default: {
        const forLineItemMode = this.getScopedVisibleVariantsForLineItemMode.bind(this);
        return forLineItemMode(menu, locationPriceStream, sectionLevelOverflow)?.length || 0;
      }
    }
  }

  /**
   * "Scoped visible" means that the variants returned are visible to the customer.
   */
   public getScopedVisibleVariants(
    menu: Menu,
    locPriceStream: LocationPriceStream,
    sectionLevelOverflow: boolean,
    onlyCheckTheFollowingVariants?: Variant[]
  ): Variant[] {
    switch (true) {
      case this.isChildVariantListMode(): {
        const forChildVarList = this.getScopedVisibleVariantsForChildVariantList.bind(this);
        return forChildVarList(menu, locPriceStream, sectionLevelOverflow, onlyCheckTheFollowingVariants)?.flatten();
      }
      case this.isGridMode(): {
        const forGridMode = this.getScopedVisibleVariantsForGridMode.bind(this);
        return forGridMode(menu, locPriceStream, sectionLevelOverflow, onlyCheckTheFollowingVariants)?.flatten();
      }
      default: {
        const forLineItemMode = this.getScopedVisibleVariantsForLineItemMode.bind(this);
        return forLineItemMode(menu, locPriceStream, sectionLevelOverflow, onlyCheckTheFollowingVariants);
      }
    }
  }

  /**
   * Don't use this unless absolutely necessary. Use getScopedVisibleVariants() instead.
   */
  public getScopedVisibleVariantsWithoutFlattening(
    menu: Menu,
    locationPriceStream: LocationPriceStream,
    sectionLevelOverflow: boolean
  ): Variant[] | Variant[][] {
    switch (true) {
      case this.isChildVariantListMode(): {
        const forChildVarList = this.getScopedVisibleVariantsForChildVariantList.bind(this);
        return forChildVarList(menu, locationPriceStream, sectionLevelOverflow);
      }
      case this.isGridMode(): {
        const forGridMode = this.getScopedVisibleVariantsForGridMode.bind(this);
        return forGridMode(menu, locationPriceStream, sectionLevelOverflow);
      }
      default: {
        const forLineItemMode = this.getScopedVisibleVariantsForLineItemMode.bind(this);
        return forLineItemMode(menu, locationPriceStream, sectionLevelOverflow);
      }
    }
  }

  protected getScopedVisibleVariantsForLineItemMode(
    menu: Menu,
    locationPriceStream: LocationPriceStream,
    sectionLevelOverflow: boolean,
    onlyCheckTheFollowingVariants?: Variant[]
  ): Variant[] {
    const variantPool = onlyCheckTheFollowingVariants || this.variants;
    const variants = variantPool?.filter(v => this.enabledVariantIds?.find(x => x === v.id));
    // always show out of stock products for print card menus
    const showZeroStockItems = MenuUtils.isPrintCardMenu(menu) || this.showZeroStockItems;
    const scoped = variants?.filter(variant => showZeroStockItems || variant?.inventory?.inStock()) || [];
    const sorted = SortVariantsInSectionUtils.sortVariantsBySectionSortOptions(scoped, menu, this, locationPriceStream);
    return (this.rowCount && !sectionLevelOverflow) ? sorted?.take(this.rowCount) : sorted;
  }

  /**
   * "Scoped visible" means that the variants returned are visible to the customer.
   *
   * Is the variant scoped to the currently selected grid columns and is the variant price visible and greater than 0?
   * Then the variant is scoped and visible, so add it to the list of returned variants.
   *
   * @returns a 2D array of variants, where each row represents a line item of a product
   * (can have multiple variants in a line item).
   */
  protected getScopedVisibleVariantsForGridMode(
    menu: Menu,
    locationPriceStream: LocationPriceStream,
    sectionLevelOverflow: boolean,
    onlyCheckTheFollowingVariants?: Variant[]
  ): Variant[][] {
    const locationId = menu?.locationId;
    const companyId = menu?.companyId;
    const hideSale = menu?.menuOptions?.hideSale;
    const variantPool = onlyCheckTheFollowingVariants || this.variants;
    let enabledVariants = variantPool?.filter(v => this.enabledVariantIds?.find(x => x === v.id));
    if (MenuUtils.isPrintCardMenu(menu)) {
      enabledVariants = enabledVariants?.filter(v => {
        return this.getActiveColumnsAsGridColumnComparisonStrings()?.includes(v?.getGridNameAsColumnComparisonString());
      }) || [];
    }
    const enabledProductIds = enabledVariants?.map(v => v?.productId)?.unique();
    // always show out of stock products for print card menus
    const isPrintCardMenu = MenuUtils.isPrintCardMenu(menu);
    const showZeroStockItems = isPrintCardMenu || this.showZeroStockItems;
    const scoped = enabledProductIds?.map(productId => {
      const rowVariants = enabledVariants?.filter(v => v?.productId === productId);
      return this.metadata
        ?.gridColumnNames
        ?.split(',')
        ?.unique()
        ?.map(groupName => {
          const hidePriceOnVariantIds = rowVariants
            ?.filter(variant => !variant?.inStock() && showZeroStockItems && !isPrintCardMenu)
            ?.map(variant => variant?.id);
          return rowVariants?.filter(variant => {
            const isWithinGridScope = variant?.withinGridColumn(groupName);
            const hidePrice = exists(hidePriceOnVariantIds?.find(id => id === variant?.id));
            let price: number;
            // always show out of stock products for print card menus
            if (showZeroStockItems || variant?.inventory?.inStock()) {
              price = variant?.getVisiblePrice(menu?.theme, locationId, companyId, locationPriceStream, hideSale);
            }
            return (!isWithinGridScope || hidePrice) ? false : price > 0;
          });
        })
        ?.filter(group => group?.length > 0)
        ?.flatten<Variant[]>() || [];
    });
    const variantGroups = scoped?.map(variants => {
      const product = this.products?.find(p => p?.id === variants?.firstOrNull()?.productId);
      return new VariantGroup(product, variants);
    });
    const sort = SortVariantGroupUtils.variantGroupsBySortOptions(variantGroups, menu, this, locationPriceStream);
    const sorted = sort?.map(it => it?.variants);
    return (this.rowCount && !sectionLevelOverflow) ? sorted?.take(this.rowCount) : sorted;
  }

  protected getScopedVisibleVariantsForChildVariantList(
    menu: Menu,
    locationPriceStream: LocationPriceStream,
    sectionLevelOverflow: boolean,
    onlyCheckTheFollowingVariants?: Variant[]
  ): Variant[][] {
    const variantPool = onlyCheckTheFollowingVariants || this.variants;
    const enabledVariants = variantPool?.filter(v => this.enabledVariantIds?.find(x => x === v?.id));
    const enabledProductIds = enabledVariants?.map(v => v?.productId)?.unique();
    const showZeroStockItems = MenuUtils.isPrintCardMenu(menu) || this.showZeroStockItems;
    const scoped = enabledProductIds?.map(productId => {
      return enabledVariants?.filter(v => v?.productId === productId && (showZeroStockItems || v?.inStock()));
    });
    const variantGroups = scoped?.map(variantsInGroup => {
      const product = this.products?.find(p => p?.id === variantsInGroup?.firstOrNull()?.productId);
      return new VariantGroup(product, variantsInGroup);
    });
    const sort = SortVariantGroupUtils.variantGroupsBySortOptions(variantGroups, menu, this, locationPriceStream);
    const sorted = sort?.map(it => it?.variants);
    return (this.rowCount && !sectionLevelOverflow) ? sorted?.take(this.rowCount) : sorted;
  }

  /* ************************ Font Styling ************************ */

  forceRowBoldStyling(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): boolean {
    const [, rowStyle] = this.getForcedSectionStyling(menu, rowVM, columnVM);
    return rowStyle?.bold ?? false;
  }

  forceRowItalicStyling(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): boolean {
    const [, rowStyle] = this.getForcedSectionStyling(menu, rowVM, columnVM);
    return rowStyle?.italics ?? false;
  }

  forceColumnBoldStyling(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): boolean {
    const forceBoldFromColumnConfig = columnVM?.columnConfig?.fontStyle === SectionColumnConfigFontStyle.Bold;
    const [gridSizeColumnStyling, ] = this.getForcedSectionStyling(menu, rowVM, columnVM);
    return gridSizeColumnStyling?.bold || forceBoldFromColumnConfig || false;
  }

  forceColumnItalicStyling(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): boolean {
    const forceItalicFromColumnConfig = columnVM?.columnConfig?.fontStyle === SectionColumnConfigFontStyle.Italics;
    const [gridSizeColumnStyling, ] = this.getForcedSectionStyling(menu, rowVM, columnVM);
    return gridSizeColumnStyling?.italics || forceItalicFromColumnConfig || false;
  }

  forcedRowTextColor(
    menu: Menu,
    rowVM: SectionRowViewModel
  ): string {
    const [, rowStyle] = this.getForcedSectionStyling(menu, rowVM, null);
    return rowStyle?.fontColor;
  }

  forcedColumnTextColor(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): string {
    const forceTextColorFromColumnConfig = columnVM?.columnConfig?.fontColor;
    const [gridSizeColumnStyling, ] = this.getForcedSectionStyling(menu, rowVM, columnVM);
    return gridSizeColumnStyling?.fontColor || forceTextColorFromColumnConfig;
  }

  forcedColumnTextDecoration(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): string {
    return SectionUtils.getColumnTextDecoration(columnVM?.columnConfig?.fontStyle);
  }

  /* ************************ Cell Color Styling ************************ */

  forceRowBackgroundColor(
    menu: Menu,
    rowVM: SectionRowViewModel,
  ): string {
    const [, rowStyle] = this?.getForcedSectionStyling(menu, rowVM, null) || [null, null];
    return rowStyle?.backgroundColor ?? '';
  }

  /**
   * If there is no forced column color, fall back to forced row color.
   * Exclude background color from grid mode row background colors.
   *
   * This is counterintuitive, but correct. We don't allow product level styling for
   * background colors to work when the section is in grid mode.
   *
   * IE, if background-color for say a 5g variant is set to red, but
   * the section is in grid mode, then this color gets ignored.
   */
  forceColumnBackgroundColor(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): string {
    const forcedColumnConfigBackgroundColor = columnVM?.columnConfig?.columnColor;
    // Counterintuitive, but correct.
    const [_, forcedRowStyling] = this.getForcedSectionStyling(menu, rowVM, columnVM);
    return forcedColumnConfigBackgroundColor || forcedRowStyling?.backgroundColor;
  }

  /* ************************ Line Item Zoom ************************ */

  forcedRowZoom(
    menu: Menu,
    rowVM: SectionRowViewModel,
  ): number {
    const accountForMenuLevelProductZoom = menu.menuOptions?.productZoom || 1;
    const [, forcedRowStyle] = this.getForcedSectionStyling(menu, rowVM, null);
    return ((forcedRowStyle?.fontSize || 100) / 100) * accountForMenuLevelProductZoom;
  }

  forcedColumnZoom(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): number {
    const [gridSizeColumnStyling, ] = this.getForcedSectionStyling(menu, rowVM, columnVM);
    return (gridSizeColumnStyling?.fontSize || 100) / 100;
  }

  /* ************************ Line Item Opacity ************************ */

  columnConfigOpacity(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): number {
    // This means zero is an invalid value
    return columnVM?.columnConfig?.columnOpacity || null;
  }

  /* ************************************************************************ */

  /**
   * @returns [gridSizeColumnStyling, rowStyle]
   * gridSizeColumnStyling represents the styling for each size column
   * respectively when the section is in grid mode.
   * gridSizeColumnStyling is null when the section is in list mode.
   */
  private getForcedSectionStyling(
    menu: Menu,
    rowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
  ): [MenuStyle, MenuStyle] {
    if (!menu || menu?.styling === null) return [null, null];
    // line item mode - aka one variant per line item
    const rowStyle = rowVM?.style;
    if (!!rowStyle && rowVM?.variantLineItemMode) return [null, rowStyle];
    // no row style or in grid mode - search for variant within grid column
    const variants = rowVM?.rowVariants?.filter(v => {
      const layoutType = columnVM?.sectionLayoutType;
      if (!this.enabledVariantIds?.find(i => i === v.id)) {
        return false;
      } else if (layoutType === SectionLayoutType.List || layoutType === SectionLayoutType.ChildVariantList) {
        return true;
      } else {
        return v?.getGridNames(layoutType, menu?.locationId)?.includes(columnVM?.columnTitle);
      }
    });
    // if a variant was found, look for its styling
    const variant = variants?.firstOrNull();
    const gridSizeColumnStyling = menu?.styling?.find(style => {
      return style.objectType === 'variant'
          && style.objectId === variant?.id
          && this.id?.includes(style.sectionId);
    });
    return (!!gridSizeColumnStyling || !!rowStyle) ? [gridSizeColumnStyling, rowStyle] : [null, null];
  }

  setUniqueIdentifier(
    menu: Menu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
  ): string {
    // We need to immediately set the unique identifier after decoding data from API
    // Once data is laid out, the sections are reorded for overflow, which will always have a different id
    const superUniqueId = super.setUniqueIdentifier(menu, companyConfig, locationConfig);
    const zeroStockId = `${this.showZeroStockItems}`;
    const sortingId = this.sorting ?? '';
    const layoutTypeId = this.layoutType ?? '';
    const rowCountId = this.rowCount ?? '';
    const variantBadgeIds: string[] = [];
    this.variantBadgeIdsMap?.forEach((val, key) => {
      const vbId = val.sort().join(',') ?? '';
      variantBadgeIds.push(`${key}-${vbId}`);
    });
    const variantBadgesId = variantBadgeIds.sort().join(',') ?? '';
    const variantIds: string[] = this.originalVariants?.map((v) => {
      return v.setUniqueIdentifier(menu, companyConfig, locationConfig);
    });
    const variantId = variantIds?.sort()?.join(',');
    this.uniqueIdentifier = `
            -${superUniqueId}
            -${zeroStockId}
            -${sortingId}
            -${layoutTypeId}
            -${variantBadgesId}
            -${variantId}
            -${rowCountId}
        `;
    return this.uniqueIdentifier;
  }

  isVariantWithinGridScope(v: Variant, layoutType: SectionLayoutType, locationId: number): boolean {
    const enabledColumns = this.getGridColumnNames();
    return v?.getGridNames(layoutType, locationId)?.intersection(enabledColumns)?.length > 0;
  }

  isHidingPrices(): boolean {
    return JSON.parse(this.metadata.hidePrices);
  }

  mergeColumnConfigWithThemeColumnConfig(themeColumnConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>) {
    this.columnConfig = SectionColumnConfig.mergeUserAndThemeColumnConfigs(this.columnConfig, themeColumnConfig);
  }

  roundCannabinoids(shouldRound: boolean): void {
    if (!shouldRound) return;
    this.originalVariants?.forEach(variant => variant?.roundCannabinoids(shouldRound));
  }

  // For Plantlife menu
  getAutoBalanceThreshold(): number {
    const threshold = this.metadata?.autoBalanceThreshold;
    return !!threshold ? parseInt(threshold, 10) : 0;
  }

  getBorderColor(menu: Menu): string {
    return null;
  }

  getBorderRadius(menu: Menu): string {
    return null;
  }

  /**
   * Works in tandem with getBottomBorder(menu: Menu). Don't use unless
   * you are using getBottomBorder(menu: Menu) to call this method.
   */
  getBottomBorderHeightInPx(): number {
    return null;
  }

  /**
   * If you're going to use this method, then use it in combination
   * with getBottomBorderHeightInPx(). This will prevent the section
   * overflow calculator from blowing up.
   */
  getBottomBorder(menu: Menu): string {
    return null;
  }

  strainTypeColumnTurnedOff(): boolean {
    return this.columnConfig
      ?.get(SectionColumnConfigProductInfoKey.StrainType)
      ?.defaultState === SectionColumnConfigState.Off;
  }

}
