import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { CardBackgroundComponent } from '../../card-background.component';
import { PopsCannabis5By5CardBackgroundViewModel } from './pops-cannabis-5-by-5-card-background-view-model';
import { CardData } from '../../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-pops-cannabis-5-by-5-card-background',
  templateUrl: './pops-cannabis-5-by-5-card-background.component.html',
  styleUrls: ['./pops-cannabis-5-by-5-card-background.component.scss'],
  providers: [PopsCannabis5By5CardBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopsCannabis5By5CardBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public override viewModel: PopsCannabis5By5CardBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @Input() override cardBackground: CardData;

}
