@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/custom-cards-5x7/fire-and-flower-5-by-7-shelf-talker-content/fire-and-flower-5-by-7-shelf-talker-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  display: contents;
}
/*# sourceMappingURL=fire-and-flower-5-by-7-shelf-talker-content.component.css.map */
