<div
  class="fire-and-flower-shelf-talker-item"
  [class.larger]="cardSize === Types.ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7">
  <img
    *rxIf="shelfTalkerCardData | getVariantAssetForShelfTalker: variantsGroupedByBrand | push as asset; else noImage"
    appImage
    class="brand-logo"
    [asset]="asset"
    alt="brand"
    src="" />
  <ng-template #noImage>
    <img class="brand-logo" [src]="variantsGroupedByBrand | fireAndFlowerBrandFallback" alt="fallback" />
  </ng-template>
  <div class="text-content">
    <div
      *rxIf="!(shelfTalkerCardData | getVariantAssetForShelfTalker: variantsGroupedByBrand | push)"
      appPrintCardScaleDownText
      [maxHeightInPx]="18"
      [maxWidthInPx]="cardSize === Types.ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7 ? 120 : 97"
      [transparentWhileScaling]="viewingInLiveView"
      class="brand-name">
      {{ variantsGroupedByBrand | variantGroupedByBrandMethod: 'getBrandName' }}
    </div>
    <div
      *rxIf="variantsGroupedByBrand | variantGroupedByBrandMethod: 'getAdditionalInfo' as additionalInfo"
      class="additional-info">
      {{ additionalInfo }}
    </div>
    <div *rxIf="variantsGroupedByBrand | variantGroupedByBrandMethod: 'getSizing' as sizing" class="sizing-info">
      {{ sizing }}
    </div>
    <div
      *rxIf="variantsGroupedByBrand | variantGroupedByBrandMethod: 'getPriceRange' : shelfTalkerCardData as pricing"
      appPrintCardScaleDownText
      [maxHeightInPx]="18"
      [maxWidthInPx]="cardSize === Types.ShelfTalkerCardSize.ShelfTalkerSize_Custom5x7 ? 120 : 97"
      [transparentWhileScaling]="viewingInLiveView"
      class="price-info">
      {{ pricing }}
    </div>
  </div>
</div>
