/**
 * Layout Descriptions:
 *
 * 1. Grid: Displays multiple product variants in a grid format, allowing you to view price information for each
 * variant at a glance.
 *
 * 2. List: While commonly used for digital/print menus, this layout can be misleading in other contexts.
 * It displays only a single variant per line item or card, meaning if a product with multiple variants is added to a
 * List layout section, each variant will be shown as a separate item.
 *
 * 3. PricingTierGrid: Similar to the Grid layout, but designed for products with multiple pricing tiers.
 * Each tier is displayed in a grid, making it easy to compare prices across different tiers.
 *
 * 4. ChildVariantList: Used for products with multiple child variants, this layout groups each child variant under
 * a single line item or card. An example of this is Stiiizy print cards, where child variants are treated as different
 * flavors, and the number of available flavors is displayed based on the number of child variants.
 *
 * 5. ClassicFlowerGrid: Used for Flower products with Pricing tier, throughout the app data is in grams and for
 * displaying price and name we do conversion/calculation grams to ounces.
 * Support flower sizes: 1g, 3.5g (1/8 oz), 7g (1/4 oz), 14g (1/2 oz), and 28g (1 oz).
 */
export enum SectionLayoutType {
  Grid = 'SectionLayoutType_Grid',
  List = 'SectionLayoutType_List',
  PricingTierGrid = 'SectionLayoutType_PricingTierGrid',
  ChildVariantList = 'SectionLayoutType_ChildVariantList',
  ClassicFlowerGrid = 'SectionLayoutType_ClassicFlowerGrid',
}
