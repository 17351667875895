import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { FireAndFlowerShelfTalkerPostCardContentViewModel } from './fire-and-flower-shelf-talker-post-card-content-view-model';
import { ShelfTalkerCardContentComponent } from '../../shared/shelf-talker-card-content.component';

@Component({
  selector: 'app-fire-and-flower-shelf-talker-post-card-content',
  templateUrl: './fire-and-flower-shelf-talker-post-card-content.component.html',
  styleUrl: './fire-and-flower-shelf-talker-post-card-content.component.scss',
  providers: [FireAndFlowerShelfTalkerPostCardContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireAndFlowerShelfTalkerPostCardContentComponent extends ShelfTalkerCardContentComponent {

  constructor(
    public viewModel: FireAndFlowerShelfTalkerPostCardContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
