import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { of } from 'rxjs';
import { SpecificPriceWithoutDiscounts } from '../../../../../../../models/enum/shared/specific-price-without-discounts.enum';

@Injectable()
export class FireAndFlowerSaleCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly bottomPriceSpecificPriceWithoutDiscounts$ = of([
    SpecificPriceWithoutDiscounts.LocationSpecificBasePrice,
    SpecificPriceWithoutDiscounts.CompanySpecificBasePrice,
    SpecificPriceWithoutDiscounts.BasePrice
  ]);

  public addSpaceAfterDollarSign = (price: string): string => price?.replace('$', '$ ');

}
