import { BaseViewModel } from '../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { CardData } from '../../../models/print-cards/card-data';
import { ShelfTalkerCardData } from '../../../models/shelf-talkers/shelf-talker-card-data';

export abstract class RotateCardContentViewModel extends BaseViewModel {

  private readonly _parentHeight = new BehaviorSubject<number>(0);
  public readonly parentHeight$ = this._parentHeight.pipe(distinctUntilChanged());
  connectToParentHeight = (parentHeight: number) => this._parentHeight.next(parentHeight);

  private readonly _parentWidth = new BehaviorSubject<number>(0);
  public readonly parentWidth$ = this._parentWidth.pipe(distinctUntilChanged());
  connectToParentWidth = (parentWidth: number) => this._parentWidth.next(parentWidth);

  private readonly _preview = new BehaviorSubject<boolean>(false);
  public readonly preview$ = this._preview.pipe(distinctUntilChanged());
  connectToPreview = (preview: boolean) => this._preview.next(preview);

  private readonly _data = new BehaviorSubject<CardData|ShelfTalkerCardData|null>(null);
  public readonly data$ = this._data as BehaviorSubject<CardData|ShelfTalkerCardData|null>;
  connectToCardData = (cardData: CardData|ShelfTalkerCardData) => this._data.next(cardData);

  public readonly layoutInstructions$ = this.data$.pipe(
    map(data => data?.getLayoutInstructions() || null)
  );

  public readonly rotateContent$ = combineLatest([
    this.preview$,
    this.layoutInstructions$
  ]).pipe(
    map(([preview, layoutInstructions]) => {
      return !preview && layoutInstructions?.orientation !== layoutInstructions?.cardOrientationOnPortraitPage();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly contentHeight$ = combineLatest([
    this.rotateContent$,
    this.parentHeight$,
    this.parentWidth$
  ]).pipe(
    map(([rotateContent, parentHeight, parentWidth]) => (rotateContent ? parentWidth : parentHeight) || null),
    distinctUntilChanged()
  );

  public readonly contentWidth$ = combineLatest([
    this.rotateContent$,
    this.parentHeight$,
    this.parentWidth$
  ]).pipe(
    map(([rotateContent, parentHeight, parentWidth]) => (rotateContent ? parentHeight : parentWidth) || null),
    distinctUntilChanged()
  );

  public readonly transform$ = this.rotateContent$.pipe(
    map(rotateContent => rotateContent ? 'rotate(-90deg) translateX(-100%)' : null),
    distinctUntilChanged()
  );

  public readonly transformOrigin$ = this.rotateContent$.pipe(
    map(rotateContent => rotateContent ? 'top left' : null),
    distinctUntilChanged()
  );

}
