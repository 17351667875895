import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PostCardShelfTalker } from './post-card-shelf-talker';
import { ShelfTalkerCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/shelf-talker-card-content.component';
import { FireAndFlowerShelfTalkerPostCardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/post-cards-4x6/fire-and-flower-shelf-talker-post-card-background/fire-and-flower-shelf-talker-post-card-background.component';
import { FireAndFlowerShelfTalkerPostCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/post-cards-4x6/fire-and-flower-shelf-talker-post-card-content/fire-and-flower-shelf-talker-post-card-content.component';
import { GroupedByBrandShelfTalkerCardData } from '../../../../shelf-talkers/grouped-by-brand-shelf-talker-card-data';

export class FireAndFlowerPostCardShelfTalker extends PostCardShelfTalker {

  getCardBackgroundComponent(data: GroupedByBrandShelfTalkerCardData): Type<CardBackgroundComponent> {
    return FireAndFlowerShelfTalkerPostCardBackgroundComponent;
  }

  getCardContentComponent(data: GroupedByBrandShelfTalkerCardData): Type<ShelfTalkerCardContentComponent> {
    return FireAndFlowerShelfTalkerPostCardContentComponent;
  }

  public nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
