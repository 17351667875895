import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'isOnRegularPaper'
})
export class IsOnRegularPaperPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper): boolean {
    return cardsThatLayoutOntoPaper?.isOnRegularPaper() || false;
  }

}
