import { Component, ChangeDetectionStrategy, ElementRef, Renderer2 } from '@angular/core';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';
import { OGKushIndexCardStackContentViewModel } from './og-kush-index-card-stack-content-view-model';

@Component({
  selector: 'app-og-kush-index-card-stack-content',
  templateUrl: './og-kush-index-card-stack-content.component.html',
  styleUrls: ['./og-kush-index-card-stack-content.component.scss'],
  providers: [OGKushIndexCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OGKushIndexCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: OGKushIndexCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
