import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { CardContentComponent } from './card-content.component';
import { ShelfTalkerCardContentViewModel } from './shelf-talker-card-content-view-model';
import type { ShelfTalkerCardData } from '../../../../../../models/shelf-talkers/shelf-talker-card-data';
import { ShelfTalkerCardInterface } from '../../../../interface/shelf-talker-card-interface';

@Component({ selector: 'app-shelf-talker-card-content', template: '' })
export abstract class ShelfTalkerCardContentComponent extends CardContentComponent
  implements ShelfTalkerCardInterface, OnChanges {

  constructor(
    public viewModel: ShelfTalkerCardContentViewModel,
    protected elementRef: ElementRef,
    protected renderer2: Renderer2
  ) {
    super();
  }

  @Input() data: ShelfTalkerCardData;
  @Input() parentHeight: number;
  @Input() parentWidth: number;
  @Input() preview: boolean;

  setupViews() {
    this.handleBleedClass();
  }

  setupBindings() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.handleBleedClass();
      this.viewModel.connectToData(this.data);
    }
    if (changes.preview) {
      this.handleBleedClass();
      this.viewModel.connectToPreview(this.preview);
    }
  }

  private handleBleedClass(): void {
    (this.data?.section?.isOnRegularPaper() && !this.preview)
      ? this.renderer2.addClass(this.elementRef.nativeElement, 'bleeding')
      : this.renderer2.removeClass(this.elementRef.nativeElement, 'bleeding');
  }

}
