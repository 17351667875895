import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { Observable } from 'rxjs';
import { HoneyHazeCardData } from '../../../../../../../models/print-cards/honey-haze-card';

@Injectable()
export class HoneyHazeBusinessCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<HoneyHazeCardData>;

}
