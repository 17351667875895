<div class="card-content">
  <div class="sidebar-spacer"></div>
  <div class="sidebar-section" [style.background-color]="viewModel.cardAccentColor$ | async">
    <div
      *ngIf="data | sectionRowViewModelBasicMethod: 'getSidebarText' as text"
      class="sidebar-text"
      appScaleDownText
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
      {{ text }}
    </div>
  </div>

  <div class="main-section">
    <div class="card-top">
      <div class="title-brand-label-and-description-container">
        <div class="title-brand-and-label-container">
          <div
            *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
            [style.color]="viewModel.headerTextColor$ | async"
            class="product-card-name">
            {{ title | trimSafely }}
          </div>
          <div class="brand-and-label">
            <div
              *ngIf="data | sectionRowViewModelBasicMethod: 'getBrand' as brand"
              class="product-card-brand"
              [style.color]="viewModel.bodyTextColor$ | async">
              {{ brand | trimSafely }}
            </div>
            <app-label-inflator
              class="print-card-label"
              [columnLabel]="false"
              [labelComponentInterface]="data"
              [reset]="false">
            </app-label-inflator>
          </div>
        </div>
        <app-print-card-description-container
          [cardData]="data"
          [bodyTextColor]="viewModel.bodyTextColor$ | async"></app-print-card-description-container>
      </div>
      <div
        *ngIf="(data?.menu | printCardMenuBasicMethod: 'getNumberOfBadgesForVariant' : data) > 0"
        class="product-card-badge-container">
        <img
          *ngFor="let variantBadge of data | printCardBadges"
          appImage
          class="product-card-badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>
    </div>

    <div class="card-bottom">
      <div class="properties-and-price-container">
        <ng-container *ngIf="viewModel.isLineItemMode$ | async; else gridModeProperties">
          <div class="properties" [style.color]="viewModel.bodyTextColor$ | async">
            <ng-container *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)">
              <div *ngIf="data | sectionRowViewModelBasicMethod: 'getThc' as thc" class="property-container">
                <div class="title">THC</div>
                <div
                  class="value"
                  appScaleDownTextAndAdjustLines
                  [maxFontSizeInPx]="24"
                  [scaleDownLinkedKey]="viewModel.variantId$ | async"
                  [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                  {{ thc }}
                </div>
              </div>
              <div *ngIf="data | sectionRowViewModelBasicMethod: 'getCbd' as cbd" class="property-container">
                <div class="title">CBD</div>
                <div
                  class="value"
                  appScaleDownTextAndAdjustLines
                  [maxFontSizeInPx]="24"
                  [scaleDownLinkedKey]="viewModel.variantId$ | async"
                  [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                  {{ cbd }}
                </div>
              </div>
              <div
                *ngIf="
                  (viewModel.showQuantity$ | async) &&
                  (data | sectionRowViewModelBasicMethod: 'getQuantityString') as quantity
                "
                class="property-container">
                <div class="title">QTY</div>
                <div
                  class="value"
                  appScaleDownTextAndAdjustLines
                  [maxFontSizeInPx]="24"
                  [scaleDownLinkedKey]="viewModel.variantId$ | async"
                  [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                  {{ quantity }}
                </div>
              </div>
              <div
                *ngIf="(viewModel.showSize$ | async) && (data | sectionRowViewModelBasicMethod: 'getSize') as size"
                class="property-container">
                <div class="title">SIZE</div>
                <div
                  class="value"
                  appScaleDownTextAndAdjustLines
                  [maxFontSizeInPx]="24"
                  [scaleDownLinkedKey]="viewModel.variantId$ | async"
                  [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                  {{ size }}
                </div>
              </div>
            </ng-container>
          </div>
          <div class="product-card-price" [style.color]="viewModel.bodyTextColor$ | async">
            <app-column-primary-price
              [menu]="viewModel.cardStackMenu$ | async"
              [section]="viewModel.cardStack$ | async"
              [colVm]="viewModel.spoofedColViewModel$ | async"
              [rowVm]="viewModel.cardData$ | async"
              [locConfig]="viewModel.locationConfig$ | async"
              [locationId]="viewModel.locationId$ | async"
              [productStylingVm]="viewModel">
            </app-column-primary-price>
          </div>
        </ng-container>
      </div>

      <ng-template #gridModeProperties>
        <ng-container *ngIf="viewModel.spoofedGridColViewModels$ | async as spoofedGridColVMs">
          <div class="properties" [style.color]="viewModel.bodyTextColor$ | async">
            <div class="property-container" [class.hidden]="viewModel.isNonCannabinoidVariant$ | async">
              <div class="title grid-title">{{ viewModel.gridHeader$ | async }}</div>
              <div
                *ngFor="let spoofedVM of spoofedGridColVMs"
                class="value grid-value"
                appScaleDownTextAndAdjustLines
                [maxFontSizeInPx]="24"
                [scaleDownLinkedKey]="viewModel.variantId$ | async"
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                <div class="value-text">
                  {{ viewModel.getGridSize$(spoofedVM) | async }}
                </div>
              </div>
            </div>
            <div class="property-container" [class.hidden]="viewModel.isNonCannabinoidVariant$ | async">
              <div class="title grid-title">THC</div>
              <div
                *ngFor="let spoofedVM of spoofedGridColVMs"
                class="value grid-value"
                appScaleDownTextAndAdjustLines
                [maxFontSizeInPx]="24"
                [scaleDownLinkedKey]="viewModel.variantId$ | async"
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                <div class="value-text">
                  {{ spoofedVM | getGridVariantThc: viewModel.cardStackMenu$ : viewModel.cardData$ | async }}
                </div>
              </div>
            </div>
            <div class="property-container" [class.hidden]="viewModel.isNonCannabinoidVariant$ | async">
              <div class="title grid-title">CBD</div>
              <div
                *ngFor="let spoofedVM of spoofedGridColVMs"
                class="value grid-value"
                appScaleDownTextAndAdjustLines
                [maxFontSizeInPx]="24"
                [scaleDownLinkedKey]="viewModel.variantId$ | async"
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                <div class="value-text">
                  {{ spoofedVM | getGridVariantCbd: viewModel.cardStackMenu$ : viewModel.cardData$ | async }}
                </div>
              </div>
            </div>
            <div class="property-container">
              <div class="title grid-title">PRICE</div>
              <div *ngFor="let spoofedVM of spoofedGridColVMs" class="value grid-value grid-regular-price">
                <app-column-primary-price
                  #priceColumn
                  [menu]="viewModel.cardStackMenu$ | async"
                  [section]="viewModel.cardStack$ | async"
                  [colVm]="spoofedVM"
                  [rowVm]="viewModel.cardData$ | async"
                  [locConfig]="viewModel.locationConfig$ | async"
                  [locationId]="viewModel.locationId$ | async"
                  [productStylingVm]="viewModel">
                </app-column-primary-price>
              </div>
            </div>
            <div *ngIf="viewModel.atLeastOneGridVariantOnSale$ | async" class="property-container">
              <div class="title grid-title">SALE</div>
              <div *ngFor="let spoofedVM of spoofedGridColVMs" class="value grid-value grid-sale-price">
                <app-column-primary-price
                  [menu]="viewModel.cardStackMenu$ | async"
                  [section]="viewModel.cardStack$ | async"
                  [colVm]="spoofedVM"
                  [rowVm]="viewModel.cardData$ | async"
                  [locConfig]="viewModel.locationConfig$ | async"
                  [locationId]="viewModel.locationId$ | async"
                  [productStylingVm]="viewModel"
                  [showNgContentContainerIfNoDiscount]="true">
                  --
                </app-column-primary-price>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
