import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShelfTalkerCardData } from '../../../../../../models/shelf-talkers/shelf-talker-card-data';

@Injectable()
export class ShelfTalkerCardContentViewModel extends BaseViewModel {

  private readonly _data = new BehaviorSubject<ShelfTalkerCardData | null>(null);
  public readonly data$ = this._data as Observable<ShelfTalkerCardData | null>;
  connectToData = (data: ShelfTalkerCardData) => this._data.next(data || null);

  private readonly _preview = new BehaviorSubject<boolean>(false);
  public readonly preview$ = this._preview as Observable<boolean>;
  connectToPreview = (preview: boolean) => this._preview.next(preview);

}
