import { ProductMenu } from '../product-menu';
import { CardsThatLayoutOntoPaper } from '../section/cards-that-layout-onto-paper';
import { PaperSize } from '../../shared/paper-size';

export abstract class LaysOutCardsOnToPaperMenu extends ProductMenu {

  public override displaySize: PaperSize;
  public override template?: LaysOutCardsOnToPaperMenu;
  public override sections: CardsThatLayoutOntoPaper[];
  public override templateSections: CardsThatLayoutOntoPaper[];

  protected abstract forceSectionType(section: CardsThatLayoutOntoPaper): void;

  protected override deserializeDisplaySize(): void {
    this.displaySize = window?.injector?.Deserialize?.instanceOf(PaperSize, this.displaySize);
  }

  protected setCardInfoDataOnSection(section: CardsThatLayoutOntoPaper): void {
    const isTemplatedMenu = this.isTemplatedMenu();
    this.forceSectionType(section);
    section.singleCardPerPage = isTemplatedMenu
      ? (this.menuOptions?.singlePageMenu || this.template?.menuOptions?.singlePageMenu || false)
      : this.menuOptions?.singlePageMenu || false;
    section.paperSize = isTemplatedMenu
      ? (this.displaySize?.name || this.template?.displaySize?.name)
      : this.displaySize?.name;
    section.cardSize = isTemplatedMenu
      ? (this.metadata?.printCardSize || this.template?.metadata?.printCardSize)
      : this.metadata?.printCardSize;
    section.orientation = isTemplatedMenu
      ? (this.hydratedTheme?.printConfig?.cardOrientationMap?.get(section?.cardSize)
        || this.template?.hydratedTheme?.printConfig?.cardOrientationMap?.get(section?.cardSize))
      : this.hydratedTheme?.printConfig?.cardOrientationMap?.get(section?.cardSize);
    section.themeId = isTemplatedMenu
      ? (this.theme || this.template?.theme)
      : this.theme;
  }

}
