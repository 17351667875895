<ng-container *ngIf="cardsAsData?.length > 0">
  <div
    appPrintCardGridManager
    class="print-card-grid"
    [layoutInstructions]="viewModel.layoutInstructions$ | async"
    [nCardsOnPage]="cardsAsData?.length">
    <app-card-jig *ngFor="let data of cardsAsData" [data]="data" />
  </div>
  <app-print-card-internal-grid-cut-lines
    *ngIf="viewModel.layoutInstructions$ | async | isOnRegularPaper"
    [layoutInstructions]="viewModel.layoutInstructions$ | async"
    [nCardsOnPage]="cardsAsData?.length" />
</ng-container>
