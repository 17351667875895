import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { CardBackgroundComponent } from '../../card-background.component';
import { FikaAccessoriesIndexCardBackgroundViewModel } from './fika-accessories-index-card-background-view-model';
import { CardData } from '../../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-fika-accessories-index-card-background',
  templateUrl: './fika-accessories-index-card-background.component.html',
  styleUrls: ['./fika-accessories-index-card-background.component.scss'],
  providers: [FikaAccessoriesIndexCardBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FikaAccessoriesIndexCardBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public override viewModel: FikaAccessoriesIndexCardBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
) {
    super(viewModel, elementRef, renderer2);
  }

  @Input() override cardBackground: CardData;

}
