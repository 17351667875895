import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GanjikaBeverageCardData } from '../../../../../../../models/print-cards/ganjika-beverage-card-data';

@Injectable()
export class GanjikaBeverageAddressCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<GanjikaBeverageCardData>;

  public override virtualLabelText$ = this._virtualLabelText.pipe(
    map(virtualLabelText => {
      // If Ganjika House uses special terms to capture special sales, check against virtualLabelText
      return 'NOW';
    })
  );

}
