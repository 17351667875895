import { IndexCardStack } from './index-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { FikaAccessoriesIndexCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/index-cards-3x5/fika-accessories-index-card-stack-content/fika-accessories-index-card-stack-content.component';
import { FikaAccessoriesIndexCardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/index-cards-3x5/fika-accessories-index-card-background/fika-accessories-index-card-background.component';

export class FikaAccessoriesIndexCardStack extends IndexCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return FikaAccessoriesIndexCardBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return FikaAccessoriesIndexCardStackContentComponent;
  }

}
