import { PostCardStack } from './post-card-stack';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { GenericCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/post-cards-4x6/generic-card-stack-background/generic-card-stack-background.component';
import { GenericCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/post-cards-4x6/generic-card-stack-content/generic-card-stack-content.component';
import { CardData } from '../../../../print-cards/card-data';

export class GenericPostCardStack extends PostCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> {
    return GenericCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> {
    return GenericCardStackContentComponent;
  }

}
