import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RenderContextComponent } from '../../../../models/base/render-context-component';
import { RenderCardsViewModel } from './render-cards-view-model';
import { RenderContextService } from '../../../services/render-context.service';

@Component({
  selector: 'app-render-cards',
  templateUrl: './render-cards.component.html',
  styleUrls: ['./render-cards.component.scss'],
  providers: [RenderCardsViewModel, RenderContextService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderCardsComponent extends RenderContextComponent {

  constructor(
    public viewModel: RenderCardsViewModel
  ) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
  }

}
