import { Pipe, PipeTransform } from '@angular/core';
import { VariantsGroupedByBrand } from '../../../../../../../models/variants-grouped-by-brand';
import { AssetUtils } from '../../../../../../../../../utils/asset-utils';

@Pipe({
  name: 'fireAndFlowerBrandFallback'
})
export class FireAndFlowerBrandFallbackPipe implements PipeTransform {

  transform(variantsGroupedByBrand: VariantsGroupedByBrand): string {
    const productType = variantsGroupedByBrand?.productType();
    const variantType = variantsGroupedByBrand?.variantType();
    return AssetUtils.budSenseProductTypeUrls(productType, variantType);
  }

}
