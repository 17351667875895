import { Deserializable } from '../../protocols/deserializable';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { UnitOfMeasure } from '../../enum/dto/unit-of-measure.enum';
import { CannabisUnitOfMeasure } from '../../enum/dto/cannabis-unit-of-measure.enum';
import { ProductType } from '../../enum/dto/product-type.enum';
import { VariantType } from '../../enum/dto/variant-type.enum';
import { VariantInventory } from './variant-inventory';
import { VariantPricing } from './variant-pricing';
import { DisplayAttribute } from '../../display/dto/display-attribute';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { CompanyConfiguration } from '../../company/dto/company-configuration';
import { Menu } from '../../menu/menu';
import { VariantBadge } from './variant-badge';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { LocationPromotion } from './location-promotion';
import { MenuType } from '../../enum/dto/menu-type.enum';
import { CannabinoidDisplayType } from '../../enum/shared/cannabinoid-display-type.enum';
import { NumberUtils } from '../../../utils/number.utils';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import { LocationConfiguration } from '../../company/dto/location-configuration';
import { PriceUtils } from '../../../utils/price-utils';
import { SectionLayoutType } from '../../enum/dto/section-layout-type.enum';
import { VariantPricingTier } from './variant-pricing-tier';
import { Label } from '../../menu/labels/label';
import { StringUtils } from '../../../utils/string-utils';
import { VariantTypeUtils } from '../../../utils/variant-type-utils';
import { TerpeneUnitOfMeasure } from '../../enum/dto/terpene-unit-of-measure';
import { TerpeneDisplayType } from '../../enum/shared/terpene-display-type';
import { Cannabinoid } from '../../enum/shared/cannabinoid';
import { exists } from '../../../functions/exists';

export class Variant implements Deserializable, Cachable, UniquelyIdentifiable {

  // DTO
  public companyId: number;
  public id: string;
  public catalogItemId: string;
  public catalogSKU: string;
  public barcode: string;
  public name: string;
  public price: number;
  public lastModified: number;
  public brand: string;
  public manufacturer: string;
  public description: string;
  public shortDescription: string;
  public richTextDescription: string;
  public terpenes: string;
  public classification: StrainType;
  public unitSize: number;
  public unitOfMeasure: UnitOfMeasure;
  public cannabisUnitOfMeasure: CannabisUnitOfMeasure;
  public terpeneUnitOfMeasure: TerpeneUnitOfMeasure;
  public packagedQuantity: number;
  public productType: ProductType;
  public variantType: VariantType;
  public strain: string;
  public minTHC: string;
  public maxTHC: string;
  public THC: string;
  public minCBD: string;
  public maxCBD: string;
  public CBD: string;
  public inventory: VariantInventory;
  public locationPricing: VariantPricing[];
  public inventoryCount: number;
  public displayAttributes: DisplayAttribute;
  public locationPromotion: LocationPromotion;
  public dateCreated: number;
  // These custom properties aren't always set. They are conditionally set based on the company, POS, etc.
  public custom1: string;
  public custom2: string;
  public custom3: string;

  /**
   * Below information only pertains to the [Display], and not [Dashboard] or [Admin]
   *
   * This property gets consolidated from 2 into 1 before the data is sent back to the display app.
   * The property variant.useCannabinoidRange gets ||'d with companyConfiguration.cannabinoidDisplayType === 'range'.
   * Therefore, this signal is simplified to a single boolean at the variant level, so that the display app
   * doesn't need context to the company configuration everywhere in order to know if the variant is using
   * ranged cannabinoids.
   *
   * So the data is transformed like this on the backend:
   * this.useCannabinoidRange = variant.useCannabinoidRange || companyConfiguration.cannabinoidDisplayType === 'range';
   */
  public useCannabinoidRange: boolean;

  /**
   * Below information only pertains to the [Display], and not [Dashboard] or [Admin]
   *
   * This property gets consolidated from 2 into 1 before the data is sent back to the display app.
   * The property variant.useTerpeneRange gets ||'d with companyConfiguration.terpeneDisplayType === 'range'.
   * Therefore, this signal is simplified to a single boolean at the variant level, so that the display app
   * doesn't need context to the company configuration everywhere in order to know if the variant is using
   * ranged terpenes.
   *
   * So the data is transformed like this on the backend:
   * this.useTerpeneRange = variant.useTerpeneRange || companyConfiguration.terpeneDisplayType === 'range';
   */
  public useTerpeneRange: boolean;

  // Cache
  public cachedTime: number;
  // Parent Product
  public productId: string;
  public productName: string;
  // Unique Identifier
  public uniqueIdentifier: string = '';
  // Not set from api.
  public enabledSecondaryCannabinoids: string[];
  // Not set from api.
  // Set by sativa/indica/hybrid section on themes supporting Auto-Balance column overflow (Plantlife)
  moveToCenterColumn: boolean = false;

  static buildArrayCacheKey(companyId, locationId: number, productId: string): string {
    return `Variants-${companyId}-${locationId}-${productId}`;
  }

  static buildCacheKey(companyId, locationId: number, variantId: string): string {
    return `Variant-${companyId}-${locationId}-${variantId}`;
  }

  public onDeserialize() {
    this.inventory = window?.injector?.Deserialize?.instanceOf(VariantInventory, this.inventory);
    this.locationPricing = window?.injector?.Deserialize?.arrayOf(VariantPricing, this.locationPricing);
    this.displayAttributes = window?.injector?.Deserialize?.instanceOf(DisplayAttribute, this.displayAttributes);
    this.locationPromotion = window?.injector?.Deserialize?.instanceOf(LocationPromotion, this.locationPromotion);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(companyId, locationId: number): string {
    return Variant.buildCacheKey(companyId, locationId, this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  inStock(): boolean {
    return this.inventory?.inStock();
  }

  withinGridColumn(columnName: string): boolean {
    const unitForComparison = StringUtils.gridColumnComparisonString(this.getGridName());
    const comparingTo = StringUtils.gridColumnComparisonString(columnName);
    return (unitForComparison === comparingTo);
  }

  withinGridColumnAndInStock(columnName: string, showOutOfStock: boolean): boolean {
    return this.withinGridColumn(columnName) && (showOutOfStock || this.inStock());
  }

  isSaleLabelActive(
    themeId: string,
    locationId: number,
    companyId: number | null,
    priceStream: LocationPriceStream
  ): boolean {
    return this.hasDiscount(themeId, locationId, companyId, priceStream);
  }

  isRestockLabelActive(restockLabel: Label): boolean {
    const currentTimestamp = DateUtils.nowInUnixSeconds();
    const threshold = restockLabel?.timeThreshold * DateUtils.unixOneHour();
    const validRestockUntil = this.inventory?.lastThresholdRestock + threshold;
    const validRestockThreshold = NumberUtils.floatFirstGreaterThanSecond(this.inventory?.lastThresholdRestock, 0);
    const isStocked = NumberUtils.floatFirstGreaterThanSecond(this.inventory?.quantityInStock, 0);
    const withinRestockThreshold = currentTimestamp < validRestockUntil;
    return validRestockThreshold && isStocked && withinRestockThreshold;
  }

  isLowStockLabelActive(lowStockLabel: Label): boolean {
    const quantityInStock = this.inventory?.quantityInStock;
    const threshold = lowStockLabel?.numericThreshold;
    return NumberUtils.floatFirstGreaterThanSecond(quantityInStock, 0)
      && NumberUtils.floatFirstGreaterThanSecond(threshold, quantityInStock);
  }

  isNewLabelActive(newLabel: Label): boolean {
    const threshold = (this.inventory?.firstInventory ?? 0) + ((newLabel?.timeThreshold ?? 0) * DateUtils.unixOneDay());
    return NumberUtils.floatFirstGreaterThanSecond(threshold, DateUtils.nowInUnixSeconds());
  }

  /* ************************************* Pricing ************************************* */

  /**
   * Location SALE > Company SALE > Location Price > Company Price
   *
   * If no companyId is provided, this method will use the companyId attached to the variant.
   *
   * @returns the current sticker price of the variant, ie the price that a customer would
   * pay if they were to buy the variant at the store.
   */
  getVisiblePrice(
    themeId: string,
    locationId: number,
    companyId: number | null,
    priceStream: LocationPriceStream,
    hideSale: boolean,
    sectionLayoutType: SectionLayoutType = null,
    columnTitle: string = null
  ): number | null {
    if (sectionLayoutType === SectionLayoutType.PricingTierGrid) {
      const pricingTier = this.getPricingTierForGridName(companyId, locationId, columnTitle);
      return pricingTier?.price?.applyPriceRounding(themeId, companyId, locationId, priceStream) || 0;
    } else if (sectionLayoutType === SectionLayoutType.ClassicFlowerGrid) {
      const pricingTier = this.getClassicFlowerPricingGridName(companyId, locationId, columnTitle);
      return pricingTier?.classicFlowerPricing()?.applyPriceRounding(themeId, companyId, locationId, priceStream) || 0;
    } else {
      // Return pricing logic as normal
      const company = this.locationPricing?.find(p => p.locationId === (companyId || this.companyId));
      const location = this.locationPricing?.find(p => p.locationId === locationId);
      const locPromo = this.locationPromotion;
      const returnNull = (): null => null;
      type DiscountPriceInterface = (
        themeId: string,
        companyId: number,
        locationId: number,
        locationPriceStream: LocationPriceStream,
        locationPrice: LocationPromotion
      ) => number;
      const locationBestDiscountedPriceOrNull: DiscountPriceInterface = !hideSale
        ? location?.getBestDiscountedPriceOrNull?.bind(location) ?? returnNull
        : returnNull;
      const companyBestDiscountedPriceOrNull: DiscountPriceInterface = !hideSale
        ? company?.getBestDiscountedPriceOrNull?.bind(company) ?? returnNull
        : returnNull;
      const calculatePromotionOnOriginalPriceOrNull = (): number => locPromo
        ?.applyPromotionDiscountOrNull(this.price)
        ?.applyPriceRounding(themeId, companyId, locationId, priceStream) || null;
      const promotionOnOriginalPriceOrNull: () => number = !hideSale
        ? calculatePromotionOnOriginalPriceOrNull
        : returnNull;
      const visiblePrice = locationBestDiscountedPriceOrNull?.(themeId, companyId, locationId, priceStream, locPromo)
        || companyBestDiscountedPriceOrNull?.(themeId, companyId, locationId, priceStream, locPromo)
        || location?.getPriceWithoutDiscountsOrNull(themeId, companyId, locationId, priceStream)
        || company?.getPriceWithoutDiscountsOrNull(themeId, companyId, locationId, priceStream)
        || promotionOnOriginalPriceOrNull()
        || this.price?.applyPriceRounding(themeId, companyId, locationId, priceStream)
        || null;
      return Number.isFinite(visiblePrice) ? PriceUtils.roundPrice(visiblePrice) : null;
    }
  }

  /**
   * If no companyId is provided, this method will use the companyId attached to the variant.
   * Location Price > Company Price > Regular Price > null
   *
   * I did not break this up into separate variables because I want the logic to be calculated
   * via a short-circuit to reduce the amount of computation being done.
   *
   * @returns the price of the variant without promotions or discounts applied.
   */
  getPriceWithoutDiscounts(
    tId: string, // themeId
    lId: number, // locationId
    compId: number | null,
    priceStream: LocationPriceStream
  ): number {
    const prices = this.locationPricing;
    const cId = compId || this.companyId;
    return prices?.find(p => p.locationId === lId)?.getPriceWithoutDiscountsOrNull(tId, cId, lId, priceStream)
      || prices?.find(p => p.locationId === cId)?.getPriceWithoutDiscountsOrNull(tId, cId, lId, priceStream)
      || this.price?.applyPriceRounding(tId, cId, lId, priceStream)
      || null;
  }

  /**
   * Location Best Discount > Company Best Discount > Regular Price with Discount? > null
   * If no companyId is provided, this method will use the companyId attached to the variant.
   *
   * I did not break this up into separate variables because I want the logic to be calculated
   * via a short-circuit to reduce the amount of computation being done.
   *
   * @returns the sale or promotion price of the variant, else null.
   */
  getDiscountedPriceOrNull(
    tId: string, // themeId
    lId: number, // locationId
    compId: number | null,
    priceStream: LocationPriceStream
  ): number | null {
    const prices = this.locationPricing;
    const promo = this.locationPromotion;
    const cId = compId || this.companyId;
    return prices?.find(p => p.locationId === lId)?.getBestDiscountedPriceOrNull(tId, cId, lId, priceStream, promo)
      || prices?.find(p => p.locationId === cId)?.getBestDiscountedPriceOrNull(tId, cId, lId, priceStream, promo)
      || promo?.applyPromotionDiscountOrNull(this.price)?.applyPriceRounding(tId, cId, lId, priceStream)
      || null;
  }

  /**
   * If no companyId is provided, this method will use the companyId attached to the variant.
   *
   * @returns true if the variant is discounted, else false.
   */
  hasDiscount(
    themeId: string,
    locationId: number,
    companyId: number | null,
    priceStream: LocationPriceStream
  ): boolean {
    return this.getDiscountedPriceOrNull(themeId, locationId, companyId, priceStream) !== null;
  }

  getSecondaryPrice(
    themeId: string,
    companyId: number,
    locationId: number,
    priceSteam: LocationPriceStream
  ): number {
    companyId = Number(companyId ? companyId : this.companyId);
    locationId = Number(locationId);
    const company = this.locationPricing?.find(p => p.locationId === (companyId || this.companyId));
    const location = this.locationPricing?.find(p => p.locationId === locationId);
    let priceVal: number;
    if (location?.secondaryPrice > 0) {
      priceVal = location.secondaryPrice;
    } else if (company?.secondaryPrice > 0) {
      priceVal = company.secondaryPrice;
    }
    // Don't pass in the price stream. Price stream is only relevant for primary price.
    return priceVal?.applyPriceRounding(themeId, companyId, locationId, null);
  }

  getPricePerUOM(
    themeId: string,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream,
    hideSale: boolean
  ): number {
    companyId = Number(companyId ? companyId : this.companyId);
    locationId = Number(locationId);
    const numerator = this.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale);
    const denominator = ((this.packagedQuantity ?? 1) * (this.unitSize ?? 1));
    return (isFinite(denominator) && denominator !== 0)
      ? PriceUtils.roundToNearestHundredthOrNull(numerator / denominator)
      : 0;
  }

  getTaxesInPrice(themeId: string, locationId: number, companyId: number): number {
    return this.getVisiblePrice(themeId, locationId, companyId, LocationPriceStream.TaxesIn, false);
  }

  getTaxesInRoundedPrice(themeId: string, locationId: number, companyId: number): number {
    return this.getVisiblePrice(themeId, locationId, companyId, LocationPriceStream.TaxesInRounded, false);
  }

  getPreTaxPrice(themeId: string, locationId: number, companyId: number): number {
    return this.getVisiblePrice(themeId, locationId, companyId, LocationPriceStream.Default, false);
  }

  /**
   * Location SALE > Company SALE > Location Price > Company Price
   *
   * @returns [price, priceText, isSale]
   */
  getFormattedPrice(
    themeId: string,
    compId: number,
    locId: number,
    priceStream: LocationPriceStream,
    ignoreSalePrice: boolean = false,
    hidePriceDecimal: boolean = false,
    locName?: string,
    compName?: string
  ): [string, string, boolean] {
    const location = this.locationPricing?.find(p => p.locationId === locId);
    const company = this.locationPricing?.find(p => p.locationId === this.companyId);
    const locPromo = this.locationPromotion;
    let priceVal: number;
    let priceText: string;
    let isSale = false;
    const buildPrice = (): [string, string, boolean] => {
      return [PriceUtils.formatPrice(priceVal, hidePriceDecimal), priceText, isSale];
    };
    // location discount
    priceVal = location?.getBestDiscountedPriceOrNull(themeId, compId, locId, priceStream, locPromo);
    priceText = locName || 'location';
    isSale = true;
    if (priceVal > 0 && !ignoreSalePrice) {
      const isPromo = priceVal === location?.getPromotionPriceOrNull(themeId, compId, locId, priceStream, locPromo);
      priceText = isPromo ? 'promotion price' : priceText + ' sale price';
      return buildPrice();
    }
    // company discount
    priceVal = company?.getBestDiscountedPriceOrNull(themeId, compId, locId, priceStream, locPromo);
    priceText = compName || 'company';
    isSale = true;
    if (priceVal > 0 && !ignoreSalePrice) {
      const isPromo = priceVal === company?.getPromotionPriceOrNull(themeId, compId, locId, priceStream, locPromo);
      priceText = isPromo ? 'promotion price' : priceText + ' sale price';
      return buildPrice();
    }
    // location pricing
    priceVal = location?.getPriceWithoutDiscountsOrNull(themeId, compId, locId, priceStream);
    priceText = locName || 'location price';
    isSale = false;
    if (priceVal > 0) return buildPrice();
    // company pricing
    priceVal = company?.getPriceWithoutDiscountsOrNull(themeId, compId, locId, priceStream);
    priceText = compName || 'company price';
    isSale = false;
    if (priceVal > 0) return buildPrice();
    // default price
    priceVal = this.price?.applyPriceRounding(themeId, compId, locId, priceStream);
    priceText = 'default price';
    isSale = false;
    return buildPrice();
  }

  getBasePriceOrNull(
    themeId: string,
    companyId: number,
    locationId: number,
    priceStream: LocationPriceStream
  ): number | null {
    const base = this.price?.applyPriceRounding(themeId, companyId, locationId, priceStream);
    return Number.isFinite(base) ? PriceUtils.roundPrice(base) : null;
  }

  getCompanyPriceWithoutDiscountsOrNull(
    themeId: string,
    companyId: number,
    locationId: number,
    priceStream: LocationPriceStream
  ): number | null {
    const company = this.locationPricing?.find(p => p.locationId === this.companyId);
    const companyPrice = company?.getPriceWithoutDiscountsOrNull(themeId, companyId, locationId, priceStream) || null;
    return Number.isFinite(companyPrice) ? PriceUtils.roundPrice(companyPrice) : null;
  }

  getCompanySecondaryPriceOrNull(): number | null {
    const company = this.locationPricing?.find(p => p.locationId === this.companyId);
    const companySecondaryPrice = company?.secondaryPrice || null;
    return Number.isFinite(companySecondaryPrice) ? PriceUtils.roundPrice(companySecondaryPrice) : null;
  }

  getLocationPriceWithoutDiscountsOrNull(
    themeId: string,
    companyId: number,
    locationId: number,
    priceStream: LocationPriceStream
  ): number | null {
    const location = this.locationPricing?.find(p => p.locationId === locationId);
    const locationPrice = location?.getPriceWithoutDiscountsOrNull(themeId, companyId, locationId, priceStream) || null;
    return Number.isFinite(locationPrice) ? PriceUtils.roundPrice(locationPrice) : null;
  }

  getLocationSecondaryPriceOrNull(locationId: number): number | null {
    const location = this.locationPricing?.find(p => p.locationId === locationId);
    const locationSecondaryPrice = location?.secondaryPrice || null;
    return Number.isFinite(locationSecondaryPrice) ? PriceUtils.roundPrice(locationSecondaryPrice) : null;
  }

  onSale(
    themeId: string,
    locationId: number,
    ignoreSalePrice: boolean = false,
    priceStream: LocationPriceStream
  ): boolean {
    if (ignoreSalePrice) return false;
    return this.getDiscountedPriceOrNull(themeId, locationId, this.companyId, priceStream) !== null;
  }

  /**
   * The original price on the variant is conditionally shown if a sale, discount or promotion is
   * applied to the variant. If no sale, discount or promotion is applied, then no value is shown in the column.
   */
  getSaleOriginalPriceOrNull(
    themeId: string,
    locationId: number,
    companyId: number,
    priceStream: LocationPriceStream
  ): number | null {
    return this.onSale(themeId, locationId, false, priceStream)
      ? this.getVisiblePrice(themeId, locationId, companyId, priceStream, true)
      : null;
  }

  /* *************************** Cannabinoids and Terpenes *************************** */

  /**
   * TAC = Total Active Cannabinoids
   */
  getNumericTAC(enabledSecondaryCannabinoid: string[]): number {
    const displayAttr: DisplayAttribute[] = this.getDisplayAttributes();
    const explicitlySetTAC: string = displayAttr.find(da => da?.TAC)?.TAC || '';
    return exists(explicitlySetTAC)
      ? Number(explicitlySetTAC)
      : this.getInferredTAC(enabledSecondaryCannabinoid);
  }

  getNumericMinTAC(enabledSecondaryCannabinoid: string[]): number {
    const displayAttr: DisplayAttribute[] = this.getDisplayAttributes();
    const explicitlySetTAC: string = displayAttr.find(da => da?.minTAC)?.minTAC || '';
    return exists(explicitlySetTAC)
      ? Number(explicitlySetTAC)
      : this.getInferredTAC(enabledSecondaryCannabinoid, 'min');
  }

  getNumericMaxTAC(enabledSecondaryCannabinoid: string[]): number {
    const displayAttr: DisplayAttribute[] = this.getDisplayAttributes();
    const explicitlySetTAC: string = displayAttr.find(da => da?.maxTAC)?.maxTAC || '';
    return exists(explicitlySetTAC)
      ? Number(explicitlySetTAC)
      : this.getInferredTAC(enabledSecondaryCannabinoid, 'max');
  }

  protected getInferredTAC(enabledSecondaryCannabinoid: string[], rangeAccessor?: 'min' | 'max'): number {
    const cannabinoids = [Cannabinoid.THC, Cannabinoid.CBD, ...(enabledSecondaryCannabinoid || [])];
    const prefix = rangeAccessor ?? '';
    const sources: (DisplayAttribute | Variant)[] = [...this.getDisplayAttributes(), this];
    const count = (total: number, cannabinoid: string): number => {
      const value = sources?.reduce((acc, curr) => acc || curr?.[`${prefix}${cannabinoid}`], null);
      const valueAsNumber = exists(value) ? parseFloat(value) : 0;
      if (!valueAsNumber) return total;
      if (!Number.isFinite(total)) total = 0;
      return total + valueAsNumber;
    };
    return cannabinoids.reduce(count, 0);
  }

  /**
   * TAT = Total Active Terpenes
   */
  getNumericTAT(enabledTerpenesPascalCased: string[]): number {
    const displayAttr: DisplayAttribute[] = this.getDisplayAttributes();
    const explicitlySetTAT: string = displayAttr.find(da => da?.totalTerpene)?.totalTerpene || '';
    return exists(explicitlySetTAT)
      ? Number(explicitlySetTAT)
      : this.getInferredTAT(enabledTerpenesPascalCased);
  }

  getNumericMinTAT(enabledTerpenesPascalCased: string[]): number {
    const displayAttr: DisplayAttribute[] = this.getDisplayAttributes();
    const explicitlySetTerpenes: string = displayAttr.find(da => da?.minTotalTerpene)?.minTotalTerpene || '';
    return exists(explicitlySetTerpenes)
      ? Number(explicitlySetTerpenes)
      : this.getInferredTAT(enabledTerpenesPascalCased, 'min');
  }

  getNumericMaxTAT(enabledTerpenesPascalCased: string[]): number {
    const displayAttr: DisplayAttribute[] = this.getDisplayAttributes();
    const explicitlySetTerpenes: string = displayAttr.find(da => da?.maxTotalTerpene)?.maxTotalTerpene || '';
    return exists(explicitlySetTerpenes)
      ? Number(explicitlySetTerpenes)
      : this.getInferredTAT(enabledTerpenesPascalCased, 'max');
  }

  protected getInferredTAT(enabledTerpenes: string[], rangeAccessor?: 'min' | 'max'): number {
    const prefix = rangeAccessor ?? '';
    const sources: (DisplayAttribute | Variant)[] = [...this.getDisplayAttributes(), this];
    const count = (total: number, terpene: string): number => {
      const accessor = exists(prefix) ? `${prefix}${terpene}` : StringUtils.camelize(terpene);
      const value = sources?.reduce((acc, curr) => acc || curr?.[accessor], null);
      const valueAsNumber = exists(value) ? parseFloat(value) : 0;
      if (!valueAsNumber) return total;
      if (!Number.isFinite(total)) total = 0;
      return total + valueAsNumber;
    };
    return enabledTerpenes?.reduce(count, undefined);
  }

  getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    const str = this.displayAttributes?.[cannabinoidOrTerpeneCamelCased]
      || this.displayAttributes?.inheritedDisplayAttribute?.[cannabinoidOrTerpeneCamelCased]
      || this?.[cannabinoidOrTerpeneCamelCased]
      || null;
    return this.getCannabinoidOrTerpeneNumericValueFromString(str);
  }

  getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    const accessor = StringUtils.capitalize(cannabinoidOrTerpeneCamelCased);
    const displayMinValue = this.displayAttributes?.[`min${accessor}`] !== ''
      ? this.displayAttributes?.[`min${accessor}`]
      : null;
    const displayInheritedMinValue = this.displayAttributes?.inheritedDisplayAttribute?.[`min${accessor}`] !== ''
      ? this.displayAttributes?.inheritedDisplayAttribute?.[`min${accessor}`]
      : null;
    const minValue = this?.[`min${accessor}`] !== ''
      ? this?.[`min${accessor}`]
      : null;
    const str = displayMinValue ?? displayInheritedMinValue ?? minValue;
    return this.getCannabinoidOrTerpeneNumericValueFromString(str);
  }

  getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    const accessor = StringUtils.capitalize(cannabinoidOrTerpeneCamelCased);
    const displayMinValue = this.displayAttributes?.[`max${accessor}`] !== ''
      ? this.displayAttributes?.[`max${accessor}`]
      : null;
    const displayInheritedMinValue = this.displayAttributes?.inheritedDisplayAttribute?.[`max${accessor}`] !== ''
      ? this.displayAttributes?.inheritedDisplayAttribute?.[`max${accessor}`]
      : null;
    const minValue = this?.[`max${accessor}`] !== ''
      ? this?.[`max${accessor}`]
      : null;
    const str = displayMinValue ?? displayInheritedMinValue ?? minValue;
    return this.getCannabinoidOrTerpeneNumericValueFromString(str);
  }

  getTopTerpene(enabledTerpenesPascalCased: string[]): string {
    return this.displayAttributes?.getTopTerpene(this.useTerpeneRange, enabledTerpenesPascalCased);
  }

  /**
   * if number is negative, returns 0.
   * if v doesn't exist, returns -1.
   */
  getCannabinoidOrTerpeneNumericValueFromString(v: string): number {
    if (!!v) {
      if (v.includes('-')) {
        v = v.split('-')[0];
      }
      return Number(v.replace(/[^0-9.]+/g, ''));
    } else {
      return -1;
    }
  }

  getFormattedCannabinoidRange(cannabinoidCamelCased: string): string {
    if (this.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
      return '--';
    }
    const minVal = this.getNumericMinCannabinoidOrTerpene(cannabinoidCamelCased);
    const maxVal = this.getNumericMaxCannabinoidOrTerpene(cannabinoidCamelCased);
    if (minVal !== -1 && maxVal !== -1) {
      if (minVal === maxVal) {
        return `${minVal}`;
      }
      return `${minVal} - ${maxVal}`;
    }
    return '';
  }

  getFormattedTerpeneRange(terpeneCamelCased: string): string {
    if (this.terpeneUnitOfMeasure === TerpeneUnitOfMeasure.NA) {
      return '--';
    }
    const minVal = this.getNumericMinCannabinoidOrTerpene(terpeneCamelCased);
    const maxVal = this.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased);
    if (minVal !== -1 && maxVal !== -1) {
      if (minVal === maxVal) {
        return `${minVal}`;
      }
      return `${minVal} - ${maxVal}`;
    }
    return '';
  }

  getFormattedCannabinoidWithUnits(menu: Menu, cannabinoidCamelCased: string): string {
    const minVal = this.getNumericMinCannabinoidOrTerpene(cannabinoidCamelCased);
    const maxVal = this.getNumericMaxCannabinoidOrTerpene(cannabinoidCamelCased);
    let rangeWithUnits: string = '';
    if (minVal !== -1 && maxVal !== -1) {
      if (minVal === maxVal) {
        rangeWithUnits = `${minVal}${this.getCannabinoidUnitOfMeasureString(menu)}`;
      }
      const min = `${minVal}${this.getCannabinoidUnitOfMeasureString(menu)}`;
      const max = `${maxVal}${this.getCannabinoidUnitOfMeasureString(menu)}`;
      rangeWithUnits = `${min} - ${max}`;
    }
    return rangeWithUnits || 'N/A';
  }

  getFormattedTerpeneWithUnits(menu: Menu, terpeneCamelCased: string): string {
    const minVal = this.getNumericMinCannabinoidOrTerpene(terpeneCamelCased);
    const maxVal = this.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased);
    let rangeWithUnits: string = '';
    if (minVal !== -1 && maxVal !== -1) {
      if (minVal === maxVal) {
        rangeWithUnits = `${minVal}${this.getTerpeneUnitOfMeasureString(menu)}`;
      }
      const min = `${minVal}${this.getTerpeneUnitOfMeasureString(menu)}`;
      const max = `${maxVal}${this.getTerpeneUnitOfMeasureString(menu)}`;
      rangeWithUnits = `${min} - ${max}`;
    }
    return rangeWithUnits || 'N/A';
  }

  shouldDisplayCannabinoidValue(): boolean {
    return this.productType !== ProductType.Accessories;
  }

  shouldDisplayTerpeneValue(): boolean {
    return this.productType !== ProductType.Accessories;
  }

  getCannabinoidUnitOfMeasureString(menu: Menu): string {
    let unitOfMeasure = this.cannabisUnitOfMeasure;
    if (unitOfMeasure === CannabisUnitOfMeasure.NA) {
      return '-';
    } else if (unitOfMeasure === CannabisUnitOfMeasure.UNKNOWN) {
      if (this.productType === ProductType.Flower) {
        unitOfMeasure = CannabisUnitOfMeasure.Percent;
      } else {
        unitOfMeasure = CannabisUnitOfMeasure.MilliGram;
      }
    }
    let unitOfMeasureString = unitOfMeasure?.toString();
    const isMilliGramPerGram = unitOfMeasure === CannabisUnitOfMeasure.MilliGramPerGram;
    const isMilliGramPerMilliLitre = unitOfMeasure === CannabisUnitOfMeasure.MilliGramPerMilliLitre;
    if ((isMilliGramPerGram || isMilliGramPerMilliLitre) && menu.type !== MenuType.PrintMenu) {
      unitOfMeasureString = `${unitOfMeasure}`;
    }
    return unitOfMeasureString;
  }

  getTerpeneUnitOfMeasureString(menu: Menu): string {
    let unitOfMeasure = this.terpeneUnitOfMeasure;
    if (unitOfMeasure === TerpeneUnitOfMeasure.NA) {
      return '-';
    } else if (unitOfMeasure === TerpeneUnitOfMeasure.UNKNOWN) {
      if (this.productType === ProductType.Flower) {
        unitOfMeasure = TerpeneUnitOfMeasure.Percent;
      } else {
        unitOfMeasure = TerpeneUnitOfMeasure.MilliGram;
      }
    }
    let unitOfMeasureString = unitOfMeasure?.toString();
    const isMilliGramPerGram = unitOfMeasure === TerpeneUnitOfMeasure.MilliGramPerGram;
    const isMilliGramPerMilliLitre = unitOfMeasure === TerpeneUnitOfMeasure.MilliGramPerMilliLitre;
    if ((isMilliGramPerGram || isMilliGramPerMilliLitre) && menu.type !== MenuType.PrintMenu) {
      unitOfMeasureString = `${unitOfMeasure}`;
    }
    return unitOfMeasureString;
  }

  public getCannabinoid(menu: Menu, companyConfig: CompanyConfiguration, cannabinoidCamelCased: string): string {
    const shouldDisplayCannabinoid = this.shouldDisplayCannabinoidValue();
    if (shouldDisplayCannabinoid) {
      const unitOfMeasureString = this.getCannabinoidUnitOfMeasureString(menu);
      const companyUsesCannabinoidRange = companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Range;
      const displayCannabinoidInRanges = companyUsesCannabinoidRange || this.useCannabinoidRange;
      if (displayCannabinoidInRanges) {
        const cannabinoidRange = this.getFormattedCannabinoidRange(cannabinoidCamelCased);
        if (cannabinoidRange === '') {
          return `${CannabisUnitOfMeasure.NA}`;
        } else {
          return `${cannabinoidRange} ${unitOfMeasureString}`.trim();
        }
      } else {
        const parsedCannabinoid = this.getNumericCannabinoidOrTerpene(cannabinoidCamelCased);
        if (parsedCannabinoid < 1) {
          return (`<1${unitOfMeasureString}`).trim();
        } else {
          return `${(Math.round((parsedCannabinoid + Number.EPSILON) * 100) / 100)}${unitOfMeasureString}`.trim();
        }
      }
    } else {
      return '-';
    }
  }

  public getTerpene(menu: Menu, companyConfig: CompanyConfiguration, terpeneCamelCased: string): string {
    const shouldDisplayTerpene = this.shouldDisplayTerpeneValue();
    if (shouldDisplayTerpene) {
      const unitOfMeasureString = this.getTerpeneUnitOfMeasureString(menu);
      const companyUsesTerpeneRange = companyConfig?.terpeneDisplayType === TerpeneDisplayType.Range;
      const displayTerpeneInRanges = companyUsesTerpeneRange || this.useTerpeneRange;
      if (displayTerpeneInRanges) {
        const terpeneRange = this.getFormattedTerpeneRange(terpeneCamelCased);
        if (terpeneRange === '') {
          return `${TerpeneUnitOfMeasure.NA}`;
        } else {
          return `${terpeneRange} ${unitOfMeasureString}`.trim();
        }
      } else {
        const parsedTerpene = this.getNumericCannabinoidOrTerpene(terpeneCamelCased);
        if (parsedTerpene < 1) {
          return (`<1${unitOfMeasureString}`).trim();
        } else {
          return `${(Math.round((parsedTerpene + Number.EPSILON) * 100) / 100)}${unitOfMeasureString}`.trim();
        }
      }
    } else {
      return '-';
    }
  }

  /**
   * Cannabinoids are stored as strings, therefore, we need to parse their numerical values,
   * round them, and then replace the old string number with the new rounded string number.
   */
  roundCannabinoids(shouldRound: boolean): void {
    if (!shouldRound) return;
    const cannabinoidProperties = DisplayAttribute.cannabinoidProperties();
    const roundCannabinoid = (prop) => {
      const cannabinoidString = this[prop]?.match(/(\d+\.?\d*)/)?.[0];
      const roundedCannabinoidNumber = Math.round(parseFloat(this[prop]));
      if (Number.isFinite(roundedCannabinoidNumber)) {
        const rounded = roundedCannabinoidNumber?.toString(10);
        this[prop] = (this[prop] as string)?.replace(cannabinoidString, rounded);
      }
    };
    cannabinoidProperties?.forEach(roundCannabinoid);
    this.displayAttributes?.roundCannabinoids(shouldRound);
  }

  /* ******************************************************************************** */

  getNumericSize(): number {
    // If variant is non-pre-roll flower
    const isFlowerByGram = VariantTypeUtils.isFlowerByGramType(this.variantType);
    // If variant is any of the concentrate types
    const isConcentrate = this.productType === ProductType.Concentrate;
    // If variant is any of the liquid oil types (non-capsule)
    const isLiquidOil = this.productType === ProductType.Oil && !VariantTypeUtils.isCapsuleType(this.variantType);
    // If variant is any Vape option
    const isVape = this.productType === ProductType.Vape;

    switch (true) {
      case isFlowerByGram:
      case isConcentrate:
      case isLiquidOil:
      case isVape:
        return this.unitSize;
      default:
        return this.packagedQuantity;
    }
  }

  getGridNames(layoutType: SectionLayoutType, locationId: number): string[] | null {
    switch (layoutType) {
      case SectionLayoutType.PricingTierGrid:
      case SectionLayoutType.ClassicFlowerGrid:
        return this.getPricingTierGridNames(this.companyId, locationId, layoutType);
      case SectionLayoutType.Grid:
        return [this.getGridName()];
      default:
        return null;
    }
  }

  private getPricingTierGridNames(
    companyId: number,
    locationId: number,
    layoutType: SectionLayoutType
  ): string[] | null {
    const enabled = this.shouldUseWeightForPricingTierGridColumn();
    const getGridNames = (price: VariantPricing) => {
      const getColumnName = (pricingTier: VariantPricingTier): string | null => {
        switch (layoutType) {
          case SectionLayoutType.PricingTierGrid:
            return pricingTier?.getGridColumnName(enabled, this.unitOfMeasure);
          case SectionLayoutType.ClassicFlowerGrid:
            return pricingTier?.getFlowerGridOunceColumnName(enabled);
        }
      };
      const gridNames = price.pricingTiers?.map(getColumnName)?.filter(Boolean)?.unique(true) || [];
      return gridNames.length > 0 ? gridNames : null;
    };
    return getGridNames(this.locationPricing?.find(lp => lp.locationId === locationId))
        || getGridNames(this.locationPricing?.find(lp => lp.locationId === companyId));
  }

  private getPricingTierForGridName(
    companyId: number,
    locationId: number,
    ptGridName: string
  ): VariantPricingTier | null {
    const enabled = this.shouldUseWeightForPricingTierGridColumn();
    const unitOfMeasure = this.unitOfMeasure;
    const getTier = (price: VariantPricing): VariantPricingTier | null => {
      const tier = price?.pricingTiers?.find(pt => pt?.getGridColumnName(enabled, unitOfMeasure) === ptGridName);
      return tier || null;
    };
    return getTier(this.locationPricing?.find(lp => lp.locationId === locationId))
        || getTier(this.locationPricing?.find(lp => lp.locationId === companyId));
  }

  private getClassicFlowerPricingGridName(
    companyId: number,
    locationId: number,
    ptGridName: string
  ): VariantPricingTier | null {
    const enabled = this.shouldUseWeightForPricingTierGridColumn();
    const getTier = (price: VariantPricing): VariantPricingTier | null => {
      const tier = price?.pricingTiers?.find(pt => pt?.getFlowerGridOunceColumnName(enabled) === ptGridName);
      return tier || null;
    };
    return getTier(this.locationPricing?.find(lp => lp.locationId === locationId))
        || getTier(this.locationPricing?.find(lp => lp.locationId === companyId));
  }

  // This functionality matches that on the API and must remain consistent for grid column validation logic
  shouldUseWeightForPricingTierGridColumn(): boolean {
    return VariantTypeUtils.isFlowerByGramType(this.variantType);
  }

  private getGridName(): string {
    // If variant is non-pre-roll flower
    const isFlowerByGram = VariantTypeUtils.isFlowerByGramType(this.variantType);
    // If variant is any of the concentrate types
    const isConcentrate = this.productType === ProductType.Concentrate;
    // If variant is any of the liquid oil types (non-capsule)
    const isLiquidOil = this.productType === ProductType.Oil && !VariantTypeUtils.isCapsuleType(this.variantType);
    // If variant is any Vape option
    const isVape = this.productType === ProductType.Vape;
    const isCapsule = VariantTypeUtils.isCapsuleType(this.variantType);

    switch (true) {
      case isFlowerByGram:
        return this.unitSize > 0 ? `${this.unitSize} g` : null;
      case isLiquidOil:
      case isConcentrate:
      case isVape:
        return this.unitSize > 0 ? `${this.unitSize} ${this.unitOfMeasure}` : null;
      case isCapsule:
        return this.packagedQuantity > 0 ? `${this.packagedQuantity} caps` : null;
      default:
        return this.packagedQuantity > 0 ? `${this.packagedQuantity} pk` : null;
    }
  }

  getGridNameAsColumnComparisonString(): string {
    return StringUtils.gridColumnComparisonString(this.getGridName());
  }

  getVariantTitle(): string {
    return this?.displayAttributes?.getDisplayName() || this.name;
  }

  getFormattedUnitSize(): string {
    if (this.unitOfMeasure === UnitOfMeasure.NA) {
      return '-';
    } else {
      return this.unitSize + this.unitOfMeasure;
    }
  }

  getPackageAndSize(): string {
    const pkgQty = this.packagedQuantity;
    const weightValue = this.unitSize;
    const weightUnit = this.unitOfMeasure;
    if (pkgQty > 1) {
      return pkgQty + ' Pack, ' + weightValue + weightUnit;
    } else {
      return weightValue + weightUnit;
    }
  }

  hasBadges(map: Map<string, VariantBadge[]>): boolean {
    const badges: VariantBadge[] = [];
    if (map && map.get(this.id)) {
      map.get(this.id).forEach((badge) => {
        badges.push(badge);
      });
    }
    return badges.length > 0 || this.displayAttributes?.getBadges().length > 0;
  }

  getDisplayAttributes(): DisplayAttribute[] {
    return [this.displayAttributes, this.displayAttributes?.inheritedDisplayAttribute]?.filterNulls();
  }

  setUniqueIdentifier(
    menu: Menu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration
  ): string {
    // generate unique id
    const locationPricingIds: string[] = [];
    this.locationPricing?.forEach((p) => {
      locationPricingIds.push(p.getUniqueIdentifier());
    });
    const locationPricingId = locationPricingIds.sort().join(',');
    // not including terpenes or descriptions as it is rich text and needs to be reduced
    this.uniqueIdentifier = `
      -${this.companyId}
      -${this.id}
      -${this.name}
      -${this.price}
      -${this.brand}
      -${this.manufacturer}
      -${this.shortDescription}
      -${this.classification}
      -${this.unitSize}
      -${this.unitOfMeasure}
      -${this.cannabisUnitOfMeasure}
      -${this.terpeneUnitOfMeasure}
      -${this.useCannabinoidRange}
      -${this.useTerpeneRange}
      -${this.packagedQuantity}
      -${this.productType}
      -${this.variantType}
      -${this.strain}
      -${this.THC}
      -${this.CBD}
      -${companyConfig?.getUniqueIdentifier()}
      -${this.inventory?.getUniqueIdentifier()}
      -${locationPricingId}
      -${this.getVisiblePrice(menu.theme, menu.locationId, menu.companyId, locationConfig?.priceFormat, true)}
      -${this.displayAttributes?.getUniqueIdentifier()}
    `;
    return this.uniqueIdentifier;
  }

  getUniqueIdentifier(): string {
    return this.uniqueIdentifier;
  }

}
