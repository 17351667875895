@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/business-cards-2x3/plantlife-business-card-stack-content/plantlife-business-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.content-area {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0.625rem;
}
.content-area .content {
  border: 1px solid #000;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.content-area .content .card-header {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.75rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid #000;
  min-height: 0.875rem;
}
.content-area .content .card-information-area {
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  position: relative;
}
.content-area .content .card-information-area .background-content {
  position: absolute;
  inset: 0;
  z-index: -1;
}
.content-area .content .card-information-area .background-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
.content-area .content .card-information-area .top-half {
  width: 100%;
  flex: 1 1 0;
  min-height: 0;
}
.content-area .content .card-information-area .top-half .split-logo {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.content-area .content .card-information-area .top-half .split-logo .title-brand-label-badge {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.content-area .content .card-information-area .top-half .split-logo .title-brand-label-badge .product-title {
  overflow: clip;
  overflow-clip-margin: 0.08rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.375rem;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 90%;
  margin-bottom: 0.1rem;
}
.content-area .content .card-information-area .top-half .split-logo .title-brand-label-badge .product-brand {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 0.875rem;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 113%;
}
.content-area .content .card-information-area .top-half .split-logo .title-brand-label-badge .badges-and-label {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3125rem;
}
.content-area .content .card-information-area .top-half .split-logo .title-brand-label-badge .badges-and-label .product-badge {
  height: 0.8125rem;
  width: auto;
  object-fit: contain;
}
.content-area .content .card-information-area .top-half .split-logo .title-brand-label-badge .badges-and-label .print-card-label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
.content-area .content .card-information-area .top-half .split-logo .title-brand-label-badge .badges-and-label .print-card-label ::ng-deep .product-label {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.5625rem;
  padding: 1px 3px;
  border-radius: 2px;
  margin: 0;
  width: fit-content;
  line-height: 1.4;
}
.content-area .content .card-information-area .top-half .split-logo .brand-logo {
  background-color: white;
  max-height: 3.75rem;
  width: 4rem;
  object-fit: contain;
}
.content-area .content .card-information-area .top-half .split-logo .brand-logo.include-border {
  border: 1px solid #000;
}
.content-area .content .card-information-area .bottom-half {
  width: 100%;
  display: flex;
  max-height: 2.25rem;
  overflow-y: visible;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.content-area .content .card-information-area .bottom-half .table-data th {
  font-size: 0.625rem;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: white;
  line-height: 120%;
}
.content-area .content .card-information-area .bottom-half .table-data td {
  font-size: 0.75rem;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0 0.25rem;
  min-width: 2rem;
}
.content-area .content .card-information-area .bottom-half .table-data table,
.content-area .content .card-information-area .bottom-half .table-data th,
.content-area .content .card-information-area .bottom-half .table-data td {
  border: 1px solid black;
  border-collapse: collapse;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  text-align: center;
}
.content-area .content .card-information-area .bottom-half .pricing-container {
  flex: 1;
}
.content-area .content .card-information-area .bottom-half .pricing-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  align-items: flex-end;
  column-gap: 0.5rem;
  font-size: 1.375rem;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
  overflow: visible;
  width: unset;
}
.content-area .content .card-information-area .bottom-half .pricing-container ::ng-deep .product-variant-price .product-variant-current-price,
.content-area .content .card-information-area .bottom-half .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.content-area .content .card-information-area .bottom-half .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  text-decoration: unset;
  line-height: 1;
}
.content-area .content .card-information-area .bottom-half .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 1.375rem;
}
.content-area ::ng-deep .force-bold-style * {
  font-family: bebas-neue-pro-expanded, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.content-area ::ng-deep .force-italic-style * {
  font-family: bebas-neue-pro-expanded, sans-serif;
  font-weight: 700;
  font-style: italic;
}
/*# sourceMappingURL=plantlife-business-card-stack-content.component.css.map */
