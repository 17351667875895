import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { EMPTY, iif, Observable, throwError } from 'rxjs';
import { Injectable, Type } from '@angular/core';
import { Deserializable } from '../models/protocols/deserializable';
import { APIRequestType } from '../models/enum/shared/api-request-type.enum';
import { StringifyUtils } from '../utils/stringify-utils';
import { catchError, expand, map, reduce } from 'rxjs/operators';
import { PagableObject } from '../models/protocols/pagable-object';
import { exists } from '../functions/exists';

/**
 * Some api endpoints send JSON objects that are too large for a single payload.
 * When this happens, we use a custom "Paging" system that breaks up the object into
 * multiple payloads. The json object will contain the property "pagingKey" if it is
 * a paged object. If there isn't a pagingKey, then the object sent was complete.
 */
type PagedData = { pagingKey?: string };

@Injectable({ providedIn: 'root' })
export class ApiClient {

  constructor(
    private http: HttpClient,
  ) {
  }

  public getObj<T extends Deserializable>(
    ObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null
  ): Observable<T> {
    return this.http.get<T>(url, { headers: additionalHeaders }).pipe(
      map(r => window?.injector?.Deserialize?.instanceOf(ObjectType, r))
    );
  }

  /**
   * Get the raw JSON object from the API for paginated data.
   */
  public getPagedObj(url: string, additionalHeaders: any = null): Observable<PagedData> {
    return this.http.get<PagedData>(url, { headers: additionalHeaders });
  }

  /**
   * Get all pages of a paginated object and consolidate them into a single object.
   */
  public recursiveGetObject<T extends PagableObject>(
    RespObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null
  ): Observable<T> {
    const hasParams = url?.includes('?');
    const getPaginatedUrl = (pagingKey: string) => `${url}${hasParams ? '&' : '?'}StartKey=${pagingKey || ''}`;
    const getResponse = (getObjUrl) => this.getPagedObj(getObjUrl, additionalHeaders);
    const hasPageResponse = (result) => !!result && !!result?.pagingKey;
    return getResponse(url).pipe(
      expand(result => iif(() => hasPageResponse(result), getResponse(getPaginatedUrl(result?.pagingKey)), EMPTY)),
      reduce((acc: PagedData[], val: PagedData) => [...acc, val], []),
      map((pageFragments: any[]) => {
        const builder = new RespObjectType();
        return builder.consolidatePagedData(pageFragments) as T;
      })
    );
  }

  public recursiveGetObjectWithRetry<T extends PagableObject>(
    RespObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null,
    remainingRetries: number = 5,
    publishToast?: (message: string, title: string) => void
  ): Observable<T> {
    const hasParams = url?.includes('?');
    const getPaginatedUrl = (pagingKey: string) => `${url}${hasParams ? '&' : '?'}StartKey=${pagingKey || ''}`;
    const getResponseWithRetry = (getObjUrl: string): Observable<PagedData> => {
      return this.getPagedObj(getObjUrl, additionalHeaders).pipe(
        catchError(error => {
          if (remainingRetries > 0) {
            const attempt = 5 - remainingRetries + 1;
            publishToast?.(`Retrying ${attempt}/5`, 'Get Display Error');
            // Recursive call with decremented retries
            return this.recursiveGetObjectWithRetry(
              RespObjectType,
              getObjUrl,
              additionalHeaders,
              remainingRetries - 1,
              publishToast
            );
          } else {
            publishToast?.('An error occurred getting the display', 'Get Display Error');
            return throwError(() => error);
          }
        })
      );
    };
    const hasPageResponse = (result) => exists(result?.pagingKey);
    return getResponseWithRetry(url).pipe(
      expand(result => {
        return iif(
          () => hasPageResponse(result),
          getResponseWithRetry(getPaginatedUrl(result?.pagingKey)),
          EMPTY
        );
      }),
      reduce((acc: PagedData[], val: PagedData) => [...acc, val], []),
      map((pageFragments: any[]) => {
        const builder = new RespObjectType();
        return builder.consolidatePagedData(pageFragments) as T;
      })
    );
  }

  public getArr<T extends Deserializable>(
    ObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null
  ): Observable<T[]> {
    return this.http.get<T[]>(url, { headers: additionalHeaders }).pipe(
      map(r => r.map(rr => window?.injector?.Deserialize?.instanceOf(ObjectType, rr)) as T[])
    );
  }

  public getMapArr<T extends Deserializable>(
    respObjectType: new () => T,
    url: string,
    additionalHeaders: any = null,
  ): Observable<Map<string, T[]>> {
    return this.http.get<Map<string, T[]>>(url, { headers: additionalHeaders }).pipe(
      map(r => window?.injector?.Deserialize?.typedArrayMapOf(respObjectType, r))
    );
  }

  public getBlob(url: string, includeProgressEvents: boolean): Observable<HttpEvent<Blob>> {
    const options = {
      responseType: 'blob' as 'json',
      reportProgress: includeProgressEvents
    };
    const request = new HttpRequest('GET', url, options);
    return this.http.request(request);
  }

  public postObj<T extends Deserializable>(
    respObjectType: new () => T,
    url: string,
    payload: any,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T> {
    return this.http.post<T>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => window?.injector?.Deserialize?.instanceOf(respObjectType, r))
    );
  }

  public postArr<T extends Deserializable>(
    respObjectType: new () => T,
    url: string,
    payload: any,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T[]> {
    return this.http.post<T[]>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => r.map(rr => window?.injector?.Deserialize?.instanceOf(respObjectType, rr)) as T[])
    );
  }

  public postMapArr<T extends Deserializable>(
    respObjectType: new () => T,
    url: string,
    payload: any,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<Map<string, T[]>> {
    return this.http.post<Map<string, T[]>>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => window?.injector?.Deserialize?.typedArrayMapOf(respObjectType, r))
    );
  }

  public deleteStr(
    url: string,
    payload: any,
    additionalHeaders: any = null,
    responseType: string = 'text'
  ): Observable<string> {
    return this.http.request<string>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    });
  }

  public deleteObj<T extends Deserializable>(
    respObjectType: new () => T,
    url: string,
    payload: any,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T> {
    return this.http.request<T>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    }).pipe(
      map(r => window?.injector?.Deserialize?.instanceOf(respObjectType, r))
    );
  }

  public deleteArr<T extends Deserializable>(
    respObjectType: new () => T,
    url: string,
    payload: any,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T[]> {
    return this.http.request<T[]>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload),
      responseType: responseType as 'json'
    }).pipe(
      map(r => r.map(rr => window?.injector?.Deserialize?.instanceOf(respObjectType, rr)) as T[])
    );
  }

}

