import { Injectable } from '@angular/core';
import { ShelfTalkerCardContentViewModel } from '../../shared/shelf-talker-card-content-view-model';
import { Observable } from 'rxjs';
import { FireAndFlowerShelfTalkerCardData } from '../../../../../../../models/shelf-talkers/fire-and-flower-shelf-talker-card-data';

@Injectable()
export class FireAndFlowerShelfTalkerPostCardContentViewModel extends ShelfTalkerCardContentViewModel {

  public readonly data$: Observable<FireAndFlowerShelfTalkerCardData|null>;

}
