import { PostCardStack } from './post-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { CardBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/shared/print-card-content.component';
import { JaneDoePostCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/cards/backgrounds/post-cards-4x6/jane-doe-post-card-stack-background/jane-doe-post-card-stack-background.component';
import { JaneDoePostCardStackContentComponent } from '../../../../../modules/print-cards/components/cards/card-content/post-cards-4x6/jane-doe-post-card-stack-content/jane-doe-post-card-stack-content.component';

export class JaneDoePostCardStack extends PostCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<CardBackgroundComponent> | null {
    return JaneDoePostCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return JaneDoePostCardStackContentComponent;
  }

}
