import { SectionColumnConfig, SectionColumnConfigCannabinoidKey, SectionColumnConfigCollectiveTerpeneKey, SectionColumnConfigDataValue, SectionColumnConfigKey, SectionColumnConfigProductInfoKey, SectionColumnConfigState } from '../../../../../../../../../models/menu/section/section-column-config';
import { SectionLayoutType } from '../../../../../../../../../models/enum/dto/section-layout-type.enum';
import { ColWidth } from '../../../../../../../../../models/shared/col-width';
import { EnumUtils } from '../../../../../../../../../utils/enum-utils';
import { Terpene } from '../../../../../../../../../models/enum/shared/terpene';
import { StringUtils } from '../../../../../../../../../utils/string-utils';

/**
 * Class names are affected by these names. If an enum value changes, make sure to search all css files
 * for the old value.
 */
export enum SectionColumnProductInfoType {
  Brand = 'Brand',
  Producer = 'Producer',
  Size = 'Size',
  Quantity = 'Quantity',
  Stock = 'Stock',
  VariantPrice = 'VariantPrice',
  VariantSecondaryPrice = 'VariantSecondaryPrice',
  Spacer = 'Spacer',
  TinySpacer = 'TinySpacer',
  Badge = 'Badge',
  ProductTitle = 'ProductTitle',
  StrainType = 'StrainType',
  Label = 'Label',
  Asset = 'Asset',
  QuantityAndSize = 'QuantityAndSize',
}

/**
 * Order matters here, since many places use Object.values(SectionColumnCannabinoidType) to get cannabinoids.
 *
 * Class names are affected by these names. If an enum value changes, make sure to search all css files
 * for the old value.
 */
export enum SectionColumnCannabinoidType {
  THC = 'THC',
  CBD = 'CBD',
  THCAndCBD = 'THCAndCBD',
  CBDA = 'CBDA',
  CBG = 'CBG',
  CBGA = 'CBGA',
  CBL = 'CBL',
  CBLA = 'CBLA',
  CBN = 'CBN',
  CBNA = 'CBNA',
  CBT = 'CBT',
  CBC = 'CBC',
  CBCA = 'CBCA',
  CBCV = 'CBCV',
  CBDV = 'CBDV',
  THC8 = 'THC8',
  THC9 = 'THC9',
  THCA = 'THCA',
  THCV = 'THCV',
  TAC = 'TAC',
}

/**
 * Order matters here, since many places use Object.values(SectionColumnTerpeneType) to get terpenes.
 *
 * Class names are affected by these names. If an enum value changes, make sure to search all css files
 * for the old value.
 */
export const SectionColumnTerpeneType = EnumUtils.createEnum([
  ...Object.values(SectionColumnConfigCollectiveTerpeneKey),
  ...Object.values(Terpene).map(t => StringUtils.toPascalCase(t.toString()))
]);

export type SectionColumnType = SectionColumnProductInfoType
  | SectionColumnCannabinoidType
  | keyof typeof SectionColumnTerpeneType;

export class SectionColumnViewModel {

  constructor(
    public sectionLayoutType: SectionLayoutType,
    public columnConfig: SectionColumnConfig
  ) {
  }

  /* ****************************** Column Ordering ****************************** */

  static getDefaultCannabinoidColumnOrdering(
    startingNumber: number,
    includeTHCMixedWithCBDColumn: boolean = true
  ): [SectionColumnCannabinoidType, number][] {
    const getOrderNumber = (index: number) => startingNumber + 0.001 * index;
    const filterOutPrimary = (it: SectionColumnCannabinoidType) => {
      return it !== SectionColumnCannabinoidType.THC
          && it !== SectionColumnCannabinoidType.CBD
          && it !== SectionColumnCannabinoidType.THCAndCBD;
    };
    const secondaryCannabinoids = Object.values(SectionColumnCannabinoidType).filter(filterOutPrimary);
    return [
      [SectionColumnCannabinoidType.THC, getOrderNumber(0)],
      [SectionColumnCannabinoidType.CBD, getOrderNumber(1)],
      ...(includeTHCMixedWithCBDColumn)
        ? [[SectionColumnCannabinoidType.THCAndCBD, getOrderNumber(2)] as [SectionColumnCannabinoidType, number]]
        : [],
      ...secondaryCannabinoids.map((c, i) => [c, getOrderNumber(i + 3)] as [SectionColumnCannabinoidType, number])
    ];
  }

  static getDefaultTerpeneColumnOrdering(startingNumber: number): [SectionColumnType, number][] {
    const getOrderNumber = (index: number) => startingNumber + 0.001 * index;
    return Object
      .values(SectionColumnTerpeneType)
      .map((c, i) => [c, getOrderNumber(i)] as [SectionColumnType, number]);
  }

  /* ****************************** Column Builders ****************************** */

  static getProductTitleColumn(
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>
  ): SectionColumnViewModel {
    const productTitleCol = new SectionColumnViewModel(sectionLayoutType, null);
    const type = SectionColumnProductInfoType.ProductTitle;
    productTitleCol.columnType = type;
    productTitleCol.state = SectionColumnConfigState.On;
    productTitleCol.position = columnOrdering?.get(type);
    return productTitleCol;
  }

  private static getColumn(
    columnConfigKey: SectionColumnConfigKey,
    type: SectionColumnType,
    colConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const columnConfig = colConfigMap?.get(columnConfigKey);
    const column = new SectionColumnViewModel(sectionLayoutType, columnConfig);
    column.columnTitle = colConfigMap?.get(columnConfigKey)?.columnName;
    column.columnType = type;
    column.position = columnOrdering?.get(type);
    column.state = colConfigMap?.get(columnConfigKey)?.defaultState;
    column.widthPercentage = widths.find(it => it.type === type)?.widthPercentage;
    return column;
  }

  static getAssetColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.Asset;
    const type = SectionColumnProductInfoType.Asset;
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getBadgeColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.Badges;
    const type = SectionColumnProductInfoType.Badge;
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getBrandColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.Brand;
    const type = SectionColumnProductInfoType.Brand;
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getStrainClassColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.StrainType;
    const type = SectionColumnProductInfoType.StrainType;
    const column = SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
    column.strainTypeMode = colConfig?.get(key)?.dataValue;
    return column;
  }

  static getCannabinoidColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[],
    key: SectionColumnConfigCannabinoidKey,
    type: SectionColumnCannabinoidType
  ): SectionColumnViewModel {
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getTerpeneColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[],
    key: SectionColumnConfigKey,
    type: SectionColumnType
  ): SectionColumnViewModel {
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getQuantityAndSizeColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.QuantityAndSize;
    const type = SectionColumnProductInfoType.QuantityAndSize;
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getQuantityColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.Quantity;
    const type = SectionColumnProductInfoType.Quantity;
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getStockColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.Stock;
    const type = SectionColumnProductInfoType.Stock;
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getSizeColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.Size;
    const type = SectionColumnProductInfoType.Size;
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getPriceColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.Price;
    const type = SectionColumnProductInfoType.VariantPrice;
    return SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
  }

  static getSecondaryPriceColumn(
    colConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>,
    sectionLayoutType: SectionLayoutType,
    columnOrdering: Map<SectionColumnType, number>,
    widths: ColWidth[]
  ): SectionColumnViewModel {
    const key = SectionColumnConfigProductInfoKey.SecondaryPrice;
    const type = SectionColumnProductInfoType.VariantSecondaryPrice;
    const column = SectionColumnViewModel.getColumn(key, type, colConfig, sectionLayoutType, columnOrdering, widths);
    column.secondaryPriceMode = colConfig?.get(key)?.dataValue;
    return column;
  }

  /* *************************************************************************** */

  state: SectionColumnConfigState;
  position: number;
  columnTitle: string;
  columnType: SectionColumnType;
  widthPercentage: string;
  secondaryPriceMode: SectionColumnConfigDataValue;
  data: SectionColumnConfigDataValue;
  strainTypeMode: SectionColumnConfigDataValue;

  public getPriceLongFormatColumnTitle(): string {
    if (this.sectionLayoutType === SectionLayoutType.Grid) {
      switch (this.columnTitle) {
        case '1 pk':
          return 'Single';
        /*
         * Regex explanation: https://regex101.com/
         * \b assert position at a word boundary: (^\w|\w$|\W\w|\w\W)
         * pk matches the characters' pk literally (case sensitive)
         * \b assert position at a word boundary: (^\w|\w$|\W\w|\w\W)
         *
         * Outcome: matches the WORD 'pk', and not the character sequence 'pk'.
         * NOT captured: "pumpkin" || "napkin" || "upkeep" || ...etc
         * CAPTURED: "2 pk" || "3 per pk" || "pk" || "per pk" || ...etc
         */
        default:
          return this.columnTitle.replace(/\bpk\b/, 'pack');
      }
    }
    if (this.sectionLayoutType === SectionLayoutType.ClassicFlowerGrid) {
      switch (this.columnTitle) {
        case '1 g':   return '1 g';
        case '3.5 g': return '1/8 oz';
        case '7 g':   return '1/4 oz';
        case '14 g':  return '1/2 oz';
        case '28 g':  return '1 oz';
      }
    }
    return this.columnTitle;
  }

  public isAssetColumn(): boolean {
    return this.columnType === SectionColumnProductInfoType.Asset;
  }

  public isBadgeColumn(): boolean {
    return this.columnType === SectionColumnProductInfoType.Badge;
  }

  public uniqueId(): string {
    return `
      -${this.state}
      -${this.position}
      -${this.columnTitle}
      -${this.columnType}
      -${this.widthPercentage}
      -${this.secondaryPriceMode}
      -${this.data}
      -${this.strainTypeMode}
    `;
  }

}
