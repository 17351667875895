import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { DougPostCardStackContentViewModel } from './doug-post-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../shared/print-card-content.component';

@Component({
  selector: 'lib-doug-post-card-stack-content',
  templateUrl: './doug-post-card-stack-content.component.html',
  styleUrls: ['./doug-post-card-stack-content.component.scss'],
  providers: [DougPostCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DougPostCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: DougPostCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  gridRangeColWidthInRem = 6;
  gridColWidthInRem = 3.5;

}
