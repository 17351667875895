@charset "UTF-8";

/* src/app/modules/print-cards/components/cards/card-content/post-cards-4x6/true-north-post-card-stack-content/true-north-landscape-card-stack-content/true-north-landscape-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0.625rem;
  box-sizing: border-box;
}
:host .card-left-edge {
  position: absolute;
  transform-origin: top left;
  transform: rotate(-90deg) translateX(-100%);
  width: 22.75rem;
  height: 1.875rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  box-sizing: border-box;
}
:host .card-left-edge .strain-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.1875rem;
  letter-spacing: 0.1em;
}
:host .main-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}
:host .main-content .card-body-left-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.75rem 0.875rem 0.5rem 1.875rem;
  gap: 1rem;
  width: 65%;
}
:host .main-content .card-body-left-column .company-logo {
  max-width: 80%;
  max-height: 5rem;
}
:host .main-content .card-body-left-column .product-card-name {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1.9375rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
  width: 100%;
}
:host .main-content .card-body-left-column .product-size-and-type-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 0.9375rem;
  opacity: 0.5;
  line-height: 1.125rem;
}
:host .main-content .card-body-left-column .badge-and-label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}
:host .main-content .card-body-left-column .badge-and-label-container .badge {
  height: 1.75rem;
}
:host .main-content .card-body-left-column .badge-and-label-container .print-card-label {
  align-self: start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .card-body-left-column .badge-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  margin-left: 0;
  width: fit-content;
  max-width: 10rem;
}
:host .main-content .card-body-left-column .badge-and-label-container .print-card-label ::ng-deep .flag-body {
  display: block;
  max-width: 10rem;
  justify-content: inherit;
  text-overflow: ellipsis;
}
:host .main-content .card-body-left-column .cannabinoids-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.625rem;
  gap: 1rem;
}
:host .main-content .card-body-left-column .cannabinoids-container .property-container {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
}
:host .main-content .card-body-left-column .cannabinoids-container .property-container .title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 0.9375rem;
  opacity: 0.5;
}
:host .main-content .card-body-left-column .cannabinoids-container .property-container .value {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1.3125rem;
  max-width: 7rem;
}
:host .main-content .card-body-right-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5019607843);
  border-radius: 0 1rem 1rem 0;
  height: 100%;
  width: 35%;
  flex: 1;
  gap: 1.875rem;
}
:host .main-content .card-body-right-column .brand-logo {
  max-width: 80%;
  max-height: 8rem;
}
:host .main-content .card-body-right-column .pricing-container {
  display: flex;
  flex-direction: column;
}
:host .main-content .card-body-right-column .pricing-container ::ng-deep .product-variant-price {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
:host .main-content .card-body-right-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 1.125rem;
}
:host .main-content .card-body-right-column .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 2.25rem;
}
:host ::ng-deep .force-italic-style * {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: italic;
}
:host ::ng-deep .force-bold-style * {
  font-weight: 900 !important;
}
/*# sourceMappingURL=true-north-landscape-card-stack-content.component.css.map */
