import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { GanjikaBeverageAddressCardStackBackgroundViewModel } from './ganjika-beverage-address-card-stack-background-view-model';
import { CardBackgroundComponent } from '../../card-background.component';
import { CardData } from '../../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-ganjika-beverage-address-card-stack-background',
  templateUrl: './ganjika-beverage-address-card-stack-background.component.html',
  styleUrls: ['./ganjika-beverage-address-card-stack-background.component.scss'],
  providers: [GanjikaBeverageAddressCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GanjikaBeverageAddressCardStackBackgroundComponent extends CardBackgroundComponent {

  constructor(
    public viewModel: GanjikaBeverageAddressCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

  @Input() override cardBackground: CardData;

}
