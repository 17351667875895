import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BaseDirective } from '../../../models/base/base-directive';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';
import { exists } from '../../../functions/exists';
import { LabelStack } from '../../../models/menu/section/label-stacks/label-stack';
import { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Directive({
  selector: '[appPrintCardGridManager]'
})
export class PrintCardGridManagerDirective extends BaseDirective implements OnChanges {

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2
  ) {
    super();
  }

  @Input() layoutInstructions: CardsThatLayoutOntoPaper = null;
  @Input() nCardsOnPage: number = 1;

  private _layoutInstructions = new BehaviorSubject<CardsThatLayoutOntoPaper|null>(this.layoutInstructions);
  private layoutInstructions$ = this._layoutInstructions as Observable<CardsThatLayoutOntoPaper|null>;

  private _nCardsOnPage = new BehaviorSubject<number>(this.nCardsOnPage);
  private nCardsOnPage$ = this._nCardsOnPage as Observable<number>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.layoutInstructions) this._layoutInstructions.next(this.layoutInstructions);
    if (changes.nCardsOnPage) this._nCardsOnPage.next(this.nCardsOnPage);
  }

  setupViews() {
  }

  setupBindings() {
    combineLatest([
      this.layoutInstructions$,
      this.nCardsOnPage$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([layoutInstructions, nCardsOnPage]) => this.setLetterPaperGrid(layoutInstructions, nCardsOnPage)
    });
  }

  /**
   * The paper in 2d space is assumed to ALWAYS be in portrait orientation.
   */
  private setLetterPaperGrid(layoutInstructions: CardsThatLayoutOntoPaper|null, nCardsOnPage: number): void {
    const native = this.elementRef.nativeElement;
    const buildGrid = (nRows: number, nColumns: number) => {
      let heightInPx = layoutInstructions?.getCardHeightInPixels() ?? 0;
      let widthInPx = layoutInstructions?.getCardWidthInPixels() ?? 0;
      if (layoutInstructions?.isOnRegularPaper()) {
        const bleed = (layoutInstructions?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH * 2;
        heightInPx += bleed;
        widthInPx += bleed;
      }
      const repeat = (n: number, val: number) => `repeat(${n}, ${val}px)`;
      if (layoutInstructions?.singleCardPerPage && layoutInstructions?.isOnRegularPaper()) {
        this.renderer2.setStyle(native, 'grid-template-rows', repeat(1, heightInPx));
        this.renderer2.setStyle(native, 'grid-template-columns', repeat(1, widthInPx));
      } else {
        this.renderer2.setStyle(native, 'grid-template-rows', repeat(nRows, heightInPx));
        this.renderer2.setStyle(native, 'grid-template-columns', repeat(nColumns, widthInPx));
      }
      if (layoutInstructions?.isOnLaserLabelPaper() && layoutInstructions instanceof LabelStack) {
        const rowGap = CardStack.PIXELS_PER_INCH * layoutInstructions?.getLaserLabelPaperRowGapInInches();
        const columnGap = CardStack.PIXELS_PER_INCH * layoutInstructions?.getLaserLabelPaperColumnGapInInches();
        Number.isFinite(rowGap)
          ? this.renderer2.setStyle(native, 'row-gap', `${rowGap}px`)
          : this.renderer2.removeStyle(native, 'row-gap');
        Number.isFinite(columnGap)
          ? this.renderer2.setStyle(native, 'column-gap', `${columnGap}px`)
          : this.renderer2.removeStyle(native, 'column-gap');
      } else {
        this.renderer2.removeStyle(native, 'row-gap');
        this.renderer2.removeStyle(native, 'column-gap');
      }
    };
    this.renderer2.setStyle(native, 'display', 'grid');
    if (exists(layoutInstructions)) {
      buildGrid(
        layoutInstructions.getNumberOfVisibleRowsOnPage(nCardsOnPage),
        layoutInstructions.getNumberOfVisibleColumnsOnPage(nCardsOnPage)
      );
    }
  }

}
