import { SectionColumnConfigDataValue } from '../models/menu/section/section-column-config';
import { Fraction } from '../models/enum/shared/fraction.enum';

// @dynamic
export class PriceUtils {

  static roundPrice(p: number): number {
    return Math.round(p * 100) / 100;
  }

  static formatPrice(p: number, hidePriceDecimal: boolean = false): string {
    const numberOfDecimals = hidePriceDecimal ? 0 : 2;
    return p ? `$${p.toFixed(numberOfDecimals)}` : '';
  }

  static formatDecimalIntoPercentage(p: number): string {
    const percentage = p * 100;
    return `${(Math.round(percentage * 10) / 10)}%`;
  }

  static formatPriceAndRemoveTrailingZeros(p: number, hidePriceDecimal: boolean = false): string {
    const numberOfDecimals = hidePriceDecimal ? 0 : 2;
    const price = p ? `$${p.toFixed(numberOfDecimals)}` : '';
    return PriceUtils.removeTrailingZerosFromPriceString(price);
  }

  /**
   * removes trailing zeros, ie, 40.00 would become 40
   */
  static removeTrailingZerosFromPriceString(priceString: string): string {
    const zeroReg = /^(\$?\d*)\.00$/.exec(priceString);
    return zeroReg?.length > 1 ? zeroReg[1] : priceString;
  }

  /**
   * @returns a string that is either a fraction, decimal, or empty.
   */
  static getCertainDecimalsAsAsciiFractions(
    decimalString: string,
    supportedFractions: Fraction[],
    dropDecimalCharacter: boolean = false
  ): string {
    const decimal = Number.parseFloat(decimalString);
    if (isNaN(decimal) || decimal <= 0) return '';
    const decimalInThousands = decimal * 1000;
    const getFraction = (): Fraction | null => {
      switch (true) {
        case decimalInThousands >= 110 && decimalInThousands <= 130: return Fraction.Eighth;
        case decimalInThousands >= 240 && decimalInThousands <= 260: return Fraction.Quarter;
        case decimalInThousands >= 330 && decimalInThousands <= 340: return Fraction.Third;
        case decimalInThousands >= 360 && decimalInThousands <= 380: return Fraction.ThreeEighths;
        case decimalInThousands >= 490 && decimalInThousands <= 510: return Fraction.Half;
        case decimalInThousands >= 610 && decimalInThousands <= 630: return Fraction.FiveEighths;
        case decimalInThousands >= 660 && decimalInThousands <= 670: return Fraction.TwoThirds;
        case decimalInThousands >= 740 && decimalInThousands <= 760: return Fraction.ThreeQuarters;
        case decimalInThousands >= 860 && decimalInThousands <= 880: return Fraction.SevenEighths;
        default:                                                     return null;
      }
    };
    const fraction = getFraction();
    const supported = supportedFractions?.includes(fraction) ?? false;
    if (supported) return fraction;
    return dropDecimalCharacter ? decimalString?.replace('.', '') : decimalString;
  }

  /**
   * Rounds a price to the nearest 5 cents
   * ------------ 100th value -----------------------
   * |0|  1  2  3  4  |5|  6  7  8  9  |0|
   *    ←-----||-----→   ←-----||-----→
   *                           || Round 10th value up
   * ------------------------------------------------
   * Examples
   * (5.62 -> 5.6), (5.68 -> 5.7), (3.22 -> 3.2), (9.99 -> 10)
   * (29.98 -> 30), (124.444 -> 124.45)
   */
  static roundToNearest5CentsOrNull(price: number): number | null {
    if (!price) return null;
    return Math.round(price * 20) / 20;
  }

  /**
   * Rounds a price to the nearest 12.5 cents
   *
   * -------------------------------- 100th value -----------------------------------
   *
   * |0| 1 2 3 4 5 6 7 8 9 10 11 12 |12.5| 13 14 15 16 17 18 19 20 21 22 23 24 |25|
   *    <-----------|-------------->  ⅛   <-----------------|-----------------> ¼
   *
   * |25| 26 27 28 29 30 31 32 33 34 35 36 37 |37.5| 38 39 40 41 42 43 44 45 46 47 48 |50|
   *  ¼  <-----------------|----------------->  ⅜   <-----------------|--------------> ½
   *
   * |50| 51 52 53 54 55 56 57 58 59 60 61 62 |62.5| 63 64 65 66 67 68 69 70 71 72 73 74 |75|
   *  ½  <-----------------|----------------->  ⅝   <-----------------|-----------------> ¾
   *
   * |75| 76 77 78 79 80 81 82 83 84 85 86 87 |87.5| 88 89 90 91 92 93 94 95 96 97 98 99 |00|
   *  ¾  <-----------------|----------------->  ⅞   <-----------------|----------------->
   *
   * --------------------------------------------------------------------------------
   * Examples
   * (1.01 -> 1)
   * (1.07 -> 1.125 -> 1⅛)
   * (1.19 -> 1.25 -> 1¼)
   * (1.30 -> 1.25 -> 1¼)
   * (1.35 -> 1.375 -> 1⅜)
   * (1.44 -> 1.5 -> 1½)
   * (1.57 -> 1.625 -> 1⅝)
   * (1.68 -> 1.625 -> 1⅝)
   * (1.83 -> 1.875 -> 1⅞)
   * (1.94 -> 2)
   */
  static roundToNearestEighthCentsOrNull(price: number): number | null {
    if (!price) return null;
    return Math.round(price * 8) / 8;
  }

  static ingramAndSonsEighthPriceRounding(price: number): number | null {
    if (!price) return null;
    const roundedPrice = PriceUtils.roundToNearestEighthCentsOrNull(price);
    const roundedDecimal = PriceUtils.getDecimalsOnly(roundedPrice);
    const diff = Math.abs(price - roundedPrice);
    switch (true) {
      case roundedDecimal === 0.125:
      case roundedDecimal === 0.375:
      case roundedDecimal === 0.625:
      case roundedDecimal === 0.875:
        return diff <= 0.02 ? roundedPrice : price;
      default:
        return diff <= 0.01 ? roundedPrice : price;
    }
  }

  static getDecimalsOnly(price: number): number {
    const decimalString = price?.toString()?.split('.')?.[1] ?? '0';
    return Number.parseFloat(`0.${decimalString}`);
  }

  static roundToNearestHundredthOrNull(price: number): number | null {
    if (!price) return null;
    return Math.round(price * 100) / 100;
  }

  static secondaryPriceRequiresSaleStyling(priceType: SectionColumnConfigDataValue): boolean {
    const isOriginalAndSalePrice = priceType === SectionColumnConfigDataValue.OriginalAndSalePrice;
    const isPricePerUnit = priceType === SectionColumnConfigDataValue.PricePerUOM;
    return isOriginalAndSalePrice || isPricePerUnit;
  }

}
