import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../shared/print-card-content-view-model';
import { BehaviorSubject, combineLatest, defer, Observable, of } from 'rxjs';
import { FikaCardData } from '../../../../../../../models/print-cards/fika-card-data';
import { map } from 'rxjs/operators';
import { PrintCardColumnViewModel } from '../../../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/print-card-column-view-model';
import { StringUtils } from '../../../../../../../utils/string-utils';

@Injectable()
export class FikaIndexCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<FikaCardData>;
  public readonly includeSizeInPrice$ = of(true);

  public override virtualLabelText$ = this._virtualLabelText.pipe(
    map(virtualLabelText => {
      const compare = StringUtils.removeWhiteSpace(virtualLabelText?.toLowerCase());
      if (compare?.includes('bigdeal') || compare?.includes('lastchance')) return virtualLabelText;
      return 'NOW';
    })
  );

  private readonly _nameHeight = new BehaviorSubject<number>(0);
  readonly nameHeight$ = this._nameHeight as Observable<number>;
  connectToNameHeight = (height: number) => this._nameHeight.next(height);

  public readonly descriptionClamp8$ = combineLatest([
    this.hasBadges$,
    this.nameHeight$
  ]).pipe(
    map(([hasBadges, nameHeight]) => hasBadges || (nameHeight > 30))
  );

  public readonly descriptionClamp7$ = combineLatest([
    this.hasBadges$,
    this.nameHeight$
  ]).pipe(
    map(([hasBadges, nameHeight]) => hasBadges && (nameHeight > 30))
  );

  public getFikaGridSize$(columnVM: PrintCardColumnViewModel): Observable<string> {
    return this.cardData$.pipe(
      map(cardData => {
        const variant = columnVM?.variant;
        const size = cardData?.getQuantityAndSizeString(variant);
        const split = size?.split(' ');
        const lastIndex = split?.length - 1;
        if (lastIndex > -1) split[lastIndex] = split[lastIndex]?.toUpperCase();
        if (split?.includes('CAPS') || split?.includes('CAP')) return split?.join(' ');
        return split?.join('');
      })
    );
  }

  public getSalePriceWasText$(columnVM: PrintCardColumnViewModel, nVariantsOnSale: number): Observable<string> {
    return combineLatest([
      this.getFikaGridSize$(columnVM),
      this.isNonCannabinoidVariant$,
      defer(() => this.includeSizeInPrice$)
    ]).pipe(
      map(([gridSize, isNonCannabinoidVariant, includeSizeInPrice]) => {
        if (nVariantsOnSale === 1) {
          return !isNonCannabinoidVariant && includeSizeInPrice
            ? ('<span class="grid-name">' + gridSize + '</span>' + ' WAS ')
            : 'WAS ';
        }
        return null;
      })
    );
  }

}
