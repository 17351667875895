import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ImageAPI } from '../../api/image-api';
import { CacheService } from './cache-service';
import { DomSanitizer } from '@angular/platform-browser';
import { DuplicateAssetService } from './duplicate-asset.service';
import { BaseService } from '../../models/base/base-service';
import { InjectedDeserializer } from '../../models/protocols/deserialize';
import { PrefetchMediaService } from './prefetch-media.service';
import { IsReadyService } from './is-ready.service';
import { IsPrintCardsReadyService } from '../print-cards/services/is-print-cards-ready.service';
import { IsMenuReadyService } from './is-menu-ready.service';

declare global {
  interface Window {
    injector: InjectorService | undefined;
    isMenuReady: boolean | undefined;
  }
}

@Injectable({ providedIn: 'root' })
export class InjectorService extends BaseService {

  /**
   * The constructor uses inject() on purpose. Why?
   * All parameters injected into the constructor as parameters initialize before window.injector = this.
   * We can't have this, because the cache service relies on window.injector being set.
   * Therefore, wait until window.injector is set before initializing services.
   *
   * TL;DR: Don't inject services as params. Use inject() in constructor body instead.
   */
  constructor(
    public sanitizer: DomSanitizer,
    private router: Router
  ) {
    super();
    window.injector = this;
    this.cache = inject(CacheService);
    this.imageApi = inject(ImageAPI);
    this.isMenuReadyService = inject(IsMenuReadyService);
    this.isPrintCardsReadyService = inject(IsPrintCardsReadyService);
    this.duplicateAssetService = inject(DuplicateAssetService);
    this.prefetchMediaService = inject(PrefetchMediaService);
  }

  public cache: CacheService;
  public imageApi: ImageAPI;
  protected isMenuReadyService: IsMenuReadyService;
  protected isPrintCardsReadyService: IsPrintCardsReadyService;
  public duplicateAssetService: DuplicateAssetService;
  public prefetchMediaService: PrefetchMediaService;

  /**
   * This lives in here as to not create circular import dependencies. If you: import {Deserialize} from '...'
   * then there is a high likelihood that you will create a "circular import dependency", because the Deserialize
   * class imports references to most of the classes in this project.
   *
   * TL;DR: Don't import Deserialize into classes. Use the injector instead.
   */
  public Deserialize: typeof InjectedDeserializer = InjectedDeserializer;

  public get isReadyService(): IsReadyService {
    switch (true) {
      case this.router?.url?.includes('print-card'):
      case this.router?.url?.includes('print-label'):
      case this.router?.url?.includes('shelf-talker'):
        this.isMenuReadyService.killService();
        this.isPrintCardsReadyService.startService();
        return this.isPrintCardsReadyService;
      default:
        this.isPrintCardsReadyService.killService();
        this.isMenuReadyService.startService();
        return this.isMenuReadyService;
    }
  }

  destroy() {
    super.destroy();
    window.injector = undefined;
  }

}
