import { ChangeDetectionStrategy, Component, ElementRef, EnvironmentInjector, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { InflatorComponent } from '../../../../../inflator/inflator.component';
import { PrintCardContentInflatorViewModel } from './print-card-content-inflator-view-model';
import { CardData } from '../../../../../../models/print-cards/card-data';
import { ShelfTalkerCardData } from '../../../../../../models/shelf-talkers/shelf-talker-card-data';
import { CardContentComponent } from '../../card-content/shared/card-content.component';

@Component({
  selector: 'app-card-content-inflator',
  templateUrl: './card-content-inflator.component.html',
  styleUrls: ['./card-content-inflator.component.scss'],
  providers: [PrintCardContentInflatorViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardContentInflatorComponent extends InflatorComponent implements OnChanges {

  constructor(
    public viewModel: PrintCardContentInflatorViewModel,
    protected elementRef: ElementRef,
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() preview: boolean;
  @Input() parentHeight: number;
  @Input() parentWidth: number;
  @Input() data: CardData | ShelfTalkerCardData;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.connectToComponentType(this.getBackgroundType());
      this.viewModel.connectToCardData(this.data);
    }
    if (changes.parentHeight) this.viewModel.connectToParentHeight(this.parentHeight);
    if (changes.parentWidth) this.viewModel.connectToParentWidth(this.parentWidth);
    if (changes.preview) this.viewModel.connectToPreview(this.preview);
    super.ngOnChanges(changes);
  }

  protected getBackgroundType(): Type<CardContentComponent> | null {
    return this.data?.getLayoutInstructions()?.getCardContentComponent(this.data as any);
  }

}
