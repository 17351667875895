import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RenderCardPreviewViewModel } from './render-card-preview-view-model';
import { RenderContextComponent } from '../../../../models/base/render-context-component';
import { RenderContextService } from '../../../services/render-context.service';

@Component({
  selector: 'app-render-card-preview',
  templateUrl: './render-card-preview.component.html',
  styleUrls: ['./render-card-preview.component.scss'],
  providers: [RenderCardPreviewViewModel, RenderContextService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderCardPreviewComponent extends RenderContextComponent {

  constructor(
    public viewModel: RenderCardPreviewViewModel
  ) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
  }

}
