import { Pipe, PipeTransform } from '@angular/core';
import type { CardsThatLayoutOntoPaper } from '../../../models/menu/section/cards-that-layout-onto-paper';

@Pipe({
  name: 'verticalCardStackJustification'
})
export class VerticalCardStackJustificationPipe implements PipeTransform {

  transform(cardsThatLayoutOntoPaper: CardsThatLayoutOntoPaper): string | null {
    const topPaperMarginInInches = cardsThatLayoutOntoPaper?.customTopPaperMarginInInches() ?? null;
    if (Number.isFinite(topPaperMarginInInches)) {
      return null;
    }
    return 'center';
  }

}
