<div
  class="internal-grid-cross"
  [style.height]="layoutInstructions | getInternalCrossDimension: crossHeight"
  [style.width]="layoutInstructions | getInternalCrossDimension: crossWidth">
  <div class="vertical">
    <div
      class="dividing-sliver"
      [style.width.px]="(layoutInstructions | withContentBleed: false : 2) || 1"
      [style.border-top-width.px]="0"
      [style.border-right-width.px]="(layoutInstructions | withContentBleed: false) > 0 ? 1 : 0"
      [style.border-bottom-width.px]="0"
      [style.border-left-width.px]="1"></div>
  </div>
  <div class="horizontal">
    <div
      class="dividing-sliver"
      [style.height.px]="(layoutInstructions | withContentBleed: false : 2) || 1"
      [style.border-top-width.px]="1"
      [style.border-right-width.px]="0"
      [style.border-bottom-width.px]="(layoutInstructions | withContentBleed: false) > 0 ? 1 : 0"
      [style.border-left-width.px]="0"></div>
  </div>
  <div *ngIf="(layoutInstructions | withContentBleed: false) > 0" class="center-square">
    <div
      class="white"
      [style.height.px]="layoutInstructions | withContentBleed: false : 2"
      [style.width.px]="layoutInstructions | withContentBleed: false : 2"></div>
  </div>
</div>
