import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardsThatLayoutOntoPaper } from '../../../../../../models/menu/section/cards-that-layout-onto-paper';

@Component({
  selector: 'app-internal-grid-cut-line-cross',
  templateUrl: './internal-grid-cut-line-cross.component.html',
  styleUrls: ['./internal-grid-cut-line-cross.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalGridCutLineCrossComponent {

  @Input() layoutInstructions: CardsThatLayoutOntoPaper;
  @Input() crossWidth: string = '3rem';
  @Input() crossHeight: string = '3rem';

}
