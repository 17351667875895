import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { DisplayAPI } from '../api/display-api';
import { MenuAPI } from '../api/menu-api';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { CacheService } from '../modules/services/cache-service';
import { Display } from '../models/display/dto/display';
import { Menu } from '../models/menu/menu';
import { Theme } from '../models/menu/dto/theme';
import { MenuToDisplay } from '../models/menu/menu-to-display';
import { MenuType } from '../models/enum/dto/menu-type.enum';
import { VariantAssetService } from '../modules/services/variant-asset-service';
import { PrefetchMediaService } from '../modules/services/prefetch-media.service';
import { TemplateAPI } from '../api/template-api';
import { LocationDomainModel } from './location-domain-model';
import { LabelDomainModel } from './label-domain-model';
import { CompanyDomainModel } from './company-domain-model';
import { CachedOverflowService } from '../modules/services/cached-overflow.service';
import { CachedLabelsService } from '../modules/services/cached-labels.service';
import { ToastService } from '../services/toast-service';

@Injectable({ providedIn: 'root' })
export class DisplayDomainModel extends BaseDomainModel {

  constructor(
    private cacheService: CacheService,
    private companyDomainModel: CompanyDomainModel,
    private displayAPI: DisplayAPI,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private menuAPI: MenuAPI,
    private prefetchMediaService: PrefetchMediaService,
    private templateAPI: TemplateAPI,
    private variantAssetService: VariantAssetService,
    private cachedOverflowService: CachedOverflowService,
    private cachedLabelsService: CachedLabelsService,
    private toastService: ToastService
  ) {
    super();
  }

  private _apiResponded = new BehaviorSubject<boolean>(false);
  public readonly apiResponded$ = this._apiResponded as Observable<boolean>;

  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  public readonly companyConfig$ = this.companyDomainModel.companyConfig$;

  private _themes = new BehaviorSubject<Theme[]>(null);
  public readonly themes$ = this._themes as Observable<Theme[]>;

  public readonly variantMetadataLoaded$ = this.variantAssetService.variantMetadataLoaded$;
  public readonly marketingMenuAssets$ = this.prefetchMediaService.marketingMenuAssets$;

  // The current menu being shown on screen
  public menuToDisplay = new BehaviorSubject<MenuToDisplay>(null);
  public classificationOfMenu = new BehaviorSubject<MenuType>(MenuType.DisplayMenu);

  public setMenuToDisplay(m: Menu) {
    if (m) {
      this.menuToDisplay.next(new MenuToDisplay(m, m.rotationInterval));
    }
  }

  public getTemplateCollection(locationId: number, collectionId: string): Observable<Display> {
    return this.templateAPI.GetTemplateCollection(locationId, collectionId).pipe(
      tap(display => {
        display?.configurationIds?.forEach(id => this.cachedOverflowService.clearOverflowedSectionsFor(id));
        this.cachedLabelsService.clearCache();
        this.cacheService.cacheObject(display?.cacheKey(), display, true);
        this.prefetchMediaService.connectToAllMenus(display?.configurations);
        this.prefetchMediaService.connectToDisplayOptions(display?.options);
        this.variantAssetService.fetchVariantAssetsForMenusAssignedToDisplay(display?.configurations);
      }),
      concatMap(display => this.labelDomainModel.getLabels(locationId, display?.companyId).pipe(map(_ => display))),
      tap(_ => this._apiResponded.next(true)),
      catchError(err => {
        console.error('getTemplateCollection api error');
        return throwError(err);
      })
    );
  }

  /**
   * Fetch the locations labels whenever a display is fetched.
   */
  public getDisplay(displayId: string, ignoreLastSession: boolean): Observable<Display> {
    const lastModified: number = this.cacheService.getCachedGeneric(`last-modified-${displayId}`) || null;
    // allows api retry mechanism to display toast messages when the api fails
    const publishToast = (message: string, title: string) => this.toastService.publishInfoMessage(message, title);
    return this.displayAPI.GetDisplay(displayId, true, ignoreLastSession, lastModified, publishToast).pipe(
      tap(display => {
        display?.configurationIds?.forEach(id => this.cachedOverflowService.clearOverflowedSectionsFor(id));
        this.cachedLabelsService.clearCache();
        this.prefetchMediaService.connectToAllMenus(display?.configurations);
        this.prefetchMediaService.connectToDisplayOptions(display?.options);
        this.variantAssetService.fetchVariantAssetsForMenusAssignedToDisplay(display?.configurations);
      }),
      concatMap(display => {
        return this.labelDomainModel.getLabels(display?.locationId, display?.companyId).pipe(map(_ => display));
      }),
      tap(_ => this._apiResponded.next(true)),
      catchError(err => {
        console.error('getDisplay api error');
        return throwError(err);
      })
    );
  }

  public getMenuTemplate(locationId: number, menuTemplateId: string): Observable<Menu> {
    return this.templateAPI.GetMenuTemplate(locationId, menuTemplateId).pipe(
      tap(menuTemplate => {
        this.cachedOverflowService.clearOverflowedSectionsFor(menuTemplate?.id);
        this.cachedLabelsService.clearCache();
        this.cacheService.cacheObject(menuTemplate?.cacheKey(), menuTemplate, true);
        this.prefetchMediaService.connectToAllMenus([menuTemplate]);
        this.prefetchMediaService.connectToDisplayOptions(null);
        this.variantAssetService.fetchVariantAssetsForMenusAssignedToDisplay([menuTemplate]);
      }),
      concatMap(menu => this.labelDomainModel.getLabels(locationId, menu?.companyId).pipe(map(_ => menu))),
      tap(_ => this._apiResponded.next(true)),
      catchError(err => {
        console.error('getMenuTemplate api error');
        return throwError(err);
      })
    );
  }

  /**
   * Fetch the locations labels whenever a menu is fetched.
   */
  public getMenu(locationId: number, menuId: string) {
    return this.menuAPI.GetMenu(locationId, menuId).pipe(
      tap(menu => {
        this.cachedOverflowService.clearOverflowedSectionsFor(menu?.id);
        this.cachedLabelsService.clearCache();
        this.cacheService.cacheObject(menu?.cacheKey(), menu, true);
        this.prefetchMediaService.connectToAllMenus([menu]);
        this.prefetchMediaService.connectToDisplayOptions(null);
        this.variantAssetService.fetchVariantAssetsForMenusAssignedToDisplay([menu]);
      }),
      concatMap(menu => this.labelDomainModel.getLabels(locationId, menu?.companyId).pipe(map(_ => menu))),
      tap(_ => this._apiResponded.next(true)),
      catchError(err => {
        console.error('getMenu api error');
        return throwError(err);
      })
    );
  }

  public connectToThemes = (themes: Theme[]) => this._themes.next(themes);

}
