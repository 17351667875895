import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../cards/card-content/shared/print-card-content-view-model';
import { map, shareReplay } from 'rxjs/operators';
import { LabelData } from '../../../../../models/print-labels/label-data';
import { PrintLabelMenu } from '../../../../../models/menu/print-label/print-label-menu';
import { Observable } from 'rxjs';
import { LabelStack } from '../../../../../models/menu/section/label-stacks/label-stack';

@Injectable()
export abstract class PrintLabelContentViewModel extends PrintCardContentViewModel {

  public menu$: Observable<PrintLabelMenu>;
  public section$: Observable<LabelStack>;
  public labelStackMenu$ = this.menu$;
  public labelStack$ = this.section$;

  public labelData$ = this.rowViewModel$.pipe(
    map(labelData => labelData as LabelData ?? null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly barCode$ = this.labelData$.pipe(
    map(labelData => labelData?.getBarCode() ?? null)
  );

  public readonly sku$ = this.labelData$.pipe(
    map(labelData => labelData?.getSKU() ?? null)
  );

}
