/* src/app/modules/print-cards/components/cards/backgrounds/business-cards-2x3/jane-doe-business-card-stack-background/jane-doe-business-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
:host .outside-border {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:host .outside-border .main-content {
  background-color: #ffffff;
}
/*# sourceMappingURL=jane-doe-business-card-stack-background.component.css.map */
