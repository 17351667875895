<app-label-inflator
  #labelCalculator
  hidden
  class="label-calculator label"
  [columnLabel]="false"
  [labelComponentInterface]="data"
  [reset]="false">
</app-label-inflator>

<div class="card-left-edge">
  <div
    *ngIf="
      !(viewModel.isNonCannabinoidVariant$ | async) && data
        | sectionRowViewModelBasicMethod: 'getReadableStrainType' as strainType
    "
    class="strain-container">
    {{ strainType }}
  </div>
</div>
<div class="main-content">
  <div class="card-body-left-column">
    <img
      *ngIf="viewModel.showCompanyLogo$ | async"
      appImage
      class="company-logo"
      [asset]="viewModel.companyLogo$ | async"
      alt="company logo"
      src="" />

    <div>
      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
        class="product-card-name"
        appScaleDownText
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
        [style.color]="viewModel.headerTextColor$ | async">
        {{ title | trimSafely }}
      </div>
      <div class="product-size-and-type-container" [style.color]="viewModel.bodyTextColor$ | async">
        <ng-container *ngIf="viewModel.quantitySizeAndVariantType$ | async as quantitySizeAndVariantType">
          {{ quantitySizeAndVariantType }}
        </ng-container>
      </div>
    </div>

    <div *ngIf="viewModel.badgeOrLabelVisible$ | async" class="badge-and-label-container">
      <img
        *ngFor="let variantBadge of data | printCardBadges"
        appImage
        class="badge"
        [asset]="variantBadge?.image"
        alt="badge"
        src="" />

      <app-label-inflator
        class="print-card-label"
        [class.default-label]="!(viewModel.labelIsFlagStyle$ | async)"
        [class.flag-label]="viewModel.labelIsFlagStyle$ | async"
        [columnLabel]="false"
        [labelComponentInterface]="data"
        [reset]="false">
      </app-label-inflator>
    </div>

    <div
      *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)"
      class="cannabinoids-container"
      [style.background-color]="viewModel.cardAccentColor$ | async"
      [style.color]="viewModel.bodyTextColor$ | async">
      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getThc' as thc"
        class="property-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        <div class="title">THC</div>
        <div class="value" appScaleDownText [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
          {{ thc }}
        </div>
      </div>

      <div
        *ngIf="data | sectionRowViewModelBasicMethod: 'getCbd' as cbd"
        class="property-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        <div class="title">CBD</div>
        <div class="value" appScaleDownText [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
          {{ cbd }}
        </div>
      </div>
    </div>
  </div>

  <div class="card-body-right-column">
    <img
      *ngIf="data | getVariantAssetForCard | async as variantAsset"
      appImage
      class="brand-logo"
      [asset]="variantAsset"
      src=""
      alt="" />

    <div class="pricing-container" [style.color]="viewModel.bodyTextColor$ | async">
      <app-column-primary-price
        [menu]="viewModel.cardStackMenu$ | async"
        [section]="viewModel.cardStack$ | async"
        [colVm]="viewModel.spoofedColViewModel$ | async"
        [rowVm]="viewModel.cardData$ | async"
        [locConfig]="viewModel.locationConfig$ | async"
        [locationId]="viewModel.locationId$ | async"
        [productStylingVm]="viewModel">
      </app-column-primary-price>
    </div>
  </div>
</div>
