import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CardData } from '../../../../../models/print-cards/card-data';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { CardBackground } from '../../../../../models/protocols/card-background';
import { ShelfTalkerCardData } from '../../../../../models/shelf-talkers/shelf-talker-card-data';

@Injectable()
export abstract class CardBackgroundViewModel extends BaseViewModel {

  private readonly _cardBackground = new BehaviorSubject<CardBackground|null>(null);
  public readonly cardBackground$ = this._cardBackground as Observable<CardBackground|null>;
  connectToCardBackground = (cardBackground: CardBackground) => this._cardBackground.next(cardBackground);

  private readonly _preview = new BehaviorSubject<boolean>(false);
  public readonly preview$ = this._preview as Observable<boolean>;
  connectToPreview = (preview: boolean) => this._preview.next(preview);

  public readonly cardData$ = this.cardBackground$.pipe(
    filter((cardBG: CardBackground): cardBG is CardData => cardBG instanceof CardData),
    startWith(null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly shelfTalkerCardData$ = this.cardBackground$.pipe(
    filter((cardBG: CardBackground): cardBG is ShelfTalkerCardData => cardBG instanceof ShelfTalkerCardData),
    startWith(null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly headerTextColor$ = this.cardBackground$.pipe(map(cardData => cardData?.getHeaderTextColor() ?? null));
  public readonly bodyTextColor$ = this.cardBackground$.pipe(map(cardData => cardData?.getBodyTextColor() ?? null));
  public readonly cardAccentColor$ = this.cardBackground$.pipe(map(cardData => cardData?.getAccentColor() ?? null));
  public readonly cardColor$ = this.cardBackground$.pipe(map(cardData => cardData?.getCardColor() ?? null));

  public readonly cardStack$ = this.cardData$.pipe(map(cardData => cardData?.section));

}
