<div class="card-content">
  <app-label-inflator
    #labelCalculator
    hidden
    class="label-calculator label"
    [columnLabel]="false"
    [labelComponentInterface]="data"
    [reset]="false">
  </app-label-inflator>
  <div class="header-section" [style.background-color]="viewModel.cardAccentColor$ | async">
    <div class="header-text">
      {{
        viewModel.isLineItemMode$
          | cardHeaderUnitSize: viewModel.cardData$ : viewModel.spoofedGridColViewModels$ : false
          | async
          | trimSafely
      }}
      <ng-container *ngIf="viewModel.showVariantType$ | async">
        {{ data | sectionRowViewModelBasicMethod: 'rowVariantType' }}
      </ng-container>
    </div>
  </div>
  <div class="main-section" [style.color]="viewModel.bodyTextColor$ | async">
    <div class="background-content" [style.background-color]="viewModel.cardColor$ | async">
      <app-print-card-background-image-with-color-overlay-and-opacity [cardData]="data">
      </app-print-card-background-image-with-color-overlay-and-opacity>
    </div>
    <div
      class="left-side"
      [class.two-col]="(viewModel.spoofedGridColViewModels$ | async)?.length === 2"
      [class.full-width]="viewModel.hideRightSideOfCard$ | async">
      <div class="title-brand-container">
        <div
          *ngIf="data | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
          appScaleDownText
          class="title-container"
          [style.color]="viewModel.headerTextColor$ | async">
          {{ title | trim }}
        </div>
        <div *ngIf="data | sectionRowViewModelBasicMethod: 'getBrand' as brand" class="product-card-brand">
          {{ brand | trim }}
        </div>
      </div>
      <div *ngIf="viewModel.isLineItemMode$ | async; else gridModeBadgesAndLabel" class="product-card-price">
        <app-column-primary-price
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="viewModel.spoofedColViewModel$ | async"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel">
        </app-column-primary-price>
      </div>
    </div>
    <div
      *ngIf="!(viewModel.hideRightSideOfCard$ | async)"
      class="right-side"
      [class.two-col]="(viewModel.spoofedGridColViewModels$ | async)?.length === 2"
      [class.grid-mode]="!(viewModel.isLineItemMode$ | async)">
      <div
        *ngIf="(viewModel.badgeOrLabelVisible$ | async) && (viewModel.isLineItemMode$ | async)"
        class="badge-and-label-container">
        <div *ngIf="viewModel.hasBadges$ | async" class="product-card-badge-container">
          <img
            *ngFor="let variantBadge of data | printCardBadges"
            appImage
            class="product-card-badge"
            [asset]="variantBadge?.image"
            alt="badge"
            src="" />
        </div>
        <div *ngIf="viewModel.labelText$ | async" class="product-card-label-container">
          <app-label-inflator
            class="print-card-label"
            [columnLabel]="false"
            [labelComponentInterface]="data"
            [reset]="false">
          </app-label-inflator>
        </div>
      </div>
      <ng-container *ngIf="viewModel.isLineItemMode$ | async; else gridModeProperties">
        <ng-container *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)">
          <div *ngIf="viewModel.strainText$ | async as strainType" class="strain-type-container">
            <div class="strain-type">{{ strainType }}</div>
          </div>
          <div class="property-row">
            <div class="title" [class.grid-mode]="!(viewModel.isLineItemMode$ | async)">THC</div>
            <div class="value" [class.grid-mode]="!(viewModel.isLineItemMode$ | async)">
              <div
                class="value-text"
                appScaleDownText
                [scaleDownLinkedKey]="viewModel.variantId$ | async"
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                {{ data | sectionRowViewModelBasicMethod: 'getThc' }}
              </div>
            </div>
          </div>
          <div class="property-row">
            <div class="title" [class.grid-mode]="!(viewModel.isLineItemMode$ | async)">CBD</div>
            <div class="value" [class.grid-mode]="!(viewModel.isLineItemMode$ | async)">
              <div
                class="value-text"
                appScaleDownText
                [scaleDownLinkedKey]="viewModel.variantId$ | async"
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                {{ data | sectionRowViewModelBasicMethod: 'getCbd' }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #gridModeBadgesAndLabel>
  <div *ngIf="viewModel.badgeOrLabelVisible$ | async" class="badge-and-label-container grid-mode">
    <div *ngIf="viewModel.hasBadges$ | async" class="product-card-badge-container grid-mode">
      <img
        *ngFor="let variantBadge of data | printCardBadges"
        appImage
        class="product-card-badge"
        [asset]="variantBadge?.image"
        alt="badge"
        src="" />
    </div>
    <div *ngIf="viewModel.labelText$ | async" class="product-card-label-container grid-mode">
      <app-label-inflator
        class="print-card-label"
        [columnLabel]="false"
        [labelComponentInterface]="data"
        [reset]="false">
      </app-label-inflator>
    </div>
  </div>
</ng-template>

<ng-template #gridModeProperties>
  <ng-container *ngIf="viewModel.spoofedGridColViewModels$ | async as spoofedGridColVMs">
    <div
      *ngIf="!(viewModel.isNonCannabinoidVariant$ | async) && (viewModel.strainText$ | async) as strainType"
      class="strain-type-container">
      <div class="strain-type" appScaleDownText [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
        {{ strainType }}
      </div>
    </div>
    <div class="property-row">
      <div class="title grid-mode"></div>
      <div
        *ngFor="let spoofedVM of spoofedGridColVMs"
        class="value grid-mode column-header"
        [class.two-col]="spoofedGridColVMs.length === 2">
        <div class="value-text" appScaleDownText [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
          {{ viewModel.getGridSize$(spoofedVM) | async }}
        </div>
      </div>
    </div>
    <div *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)" class="property-row">
      <div class="title grid-mode">THC</div>
      <div
        *ngFor="let spoofedVM of spoofedGridColVMs"
        class="value grid-mode"
        [class.two-col]="spoofedGridColVMs.length === 2">
        <div
          class="value-text"
          appScaleDownText
          [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
          [scaleDownLinkedKey]="viewModel.variantId$ | async">
          {{ spoofedVM | getGridVariantThc: viewModel.cardStackMenu$ : viewModel.cardData$ | async }}
        </div>
      </div>
    </div>
    <div *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)" class="property-row">
      <div class="title grid-mode">CBD</div>
      <div
        *ngFor="let spoofedVM of spoofedGridColVMs"
        class="value grid-mode"
        [class.two-col]="spoofedGridColVMs.length === 2">
        <div
          class="value-text"
          appScaleDownText
          [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
          [scaleDownLinkedKey]="viewModel.variantId$ | async">
          {{ spoofedVM | getGridVariantCbd: viewModel.cardStackMenu$ : viewModel.cardData$ | async }}
        </div>
      </div>
    </div>
    <div class="property-row">
      <div class="title grid-mode">Price</div>
      <div
        *ngFor="let spoofedVM of spoofedGridColVMs"
        class="value grid-mode grid-regular-price"
        [class.two-col]="spoofedGridColVMs.length === 2">
        <app-column-primary-price
          #priceColumn
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="spoofedVM"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel">
        </app-column-primary-price>
      </div>
    </div>
    <div *ngIf="viewModel.atLeastOneGridVariantOnSale$ | async" class="property-row">
      <div class="title grid-mode">Sale</div>
      <div
        *ngFor="let spoofedVM of spoofedGridColVMs"
        class="value grid-mode grid-sale-price"
        appScaleDownText
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
        <app-column-primary-price
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="spoofedVM"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel"
          [showNgContentContainerIfNoDiscount]="true">
          --
        </app-column-primary-price>
      </div>
    </div>
  </ng-container>
</ng-template>
